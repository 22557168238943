import ProfileStore from "../../../stores/ProfileStore";
import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export function getContractInsightsColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-dropdown', txt: '', align: 'center'},
        {cls: 'col-contract-title', txt: 'Filename', align: 'center'},
        {cls: 'col-contract-type', txt: 'Type'},
        {cls: 'col-parent-supplier', txt: 'Parent supplier'},
        {cls: 'col-involved_supplier', txt: 'Parties involved'},
        {cls: 'col-effective_date', txt: 'Effective date'},
        {cls: 'col-expiration_date', txt: 'Expiration date'},
        {cls: 'col-button', txt: '', align: 'center'}
    ];

    return arr.filter(c => c) as ColSpec[];
}

export function getContractInsightsSubColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-info', txt: '',},
    ];

    return arr.filter(c => c) as ColSpec[];
}