import {RequestManager} from "../../../stores/managers/RequestManager";
import {AbsTreeNodeFilter} from "../../../services/classes/MatReviewClasses";
import {AxiosResponse} from "axios";
import {MatCategoryConcentrationStatistics} from "../../../services/classes/MaterializedClasses";
import {from} from "rxjs";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {BagStore} from "../../../stores/BagStore";
import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable, reaction} from "mobx";
import {catsToDict} from "../../../services/ApiHelpers";
import {MatCommonKpiSerializer} from "../../../services/ApiTypes";
import {PpvPageController} from "./PpvPageController";
import {PpvGroupRowState} from "./classes/PpvGroupRowState";
import {TaxonomyCategoryProvider} from "../../../stores/TaxonomyCategoryProvider";
import {PpvGroupFilter} from "./classes/PpvApiClasses";
import {OpportunityStatusEnum} from "../../../services/classes/OpportunityClasses";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {PpvPartRowState} from "./classes/PpvPartRowState";
import {analyticsOpportunityEvent} from "../../../services/analytics/AnalyticEvents";
import {getOpportunityStatusName} from "../../../pages/opportunity/utils/OpportunityStatusVisual";
import AuthStore from "../../../stores/AuthStore";

type PpvOpportunityTotalSavingsFilter = {
    databag: number
}

type PpvOpportunityTotalSavings = {
    total_expected_savings: number
}

export class PpvControllerStore {
    databag_id: number | undefined;
    selectedCategory: string[] = [];
    maxTaxonomySize = 2; // Do not allow to go deeper than L2 initially
    readonly categories = new TaxonomyCategoryProvider(this.profile, this.api);
    readonly ppvPageController = new PpvPageController(this, this.api, this.profile)

    isEditNoteDialogOpen: boolean = false
    isDeleteGroupModalOpen: boolean = false
    ppvRowToEdit: PpvGroupRowState | undefined = undefined
    ppvGroupToDelete: PpvGroupRowState | undefined = undefined
    ppvItemToDelete: PpvPartRowState | undefined = undefined

    /**
     * Special request manager to manage the top root result. To show the total
     */
    readonly _rootStatRequestManager = new RequestManager<{ databag: number }, AxiosResponse<MatCommonKpiSerializer>>(
        ({databag}) => from(this.api.getCommonKpi(databag))
    );

    //FIXME: I think this calls the wrong endpoint
    // Only used for showing the KeyValue statistics
    readonly _spendConcentration = new RequestManager<AbsTreeNodeFilter, AxiosResponse<MatCategoryConcentrationStatistics[]>>(
        filter => from(this.api.listSpendConcentrationCategory(filter.databag, filter.level))
    );

    readonly _ppvTotalExpectedSavings = new RequestManager<PpvOpportunityTotalSavingsFilter, AxiosResponse<PpvOpportunityTotalSavings>>(
        databag => from(this.api.getPpvTotalExpectedSavings(Number(databag)))
    );

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private profile: ProfileStore,
        private authStore: AuthStore,
    ) {
        makeAutoObservable(this)


        reaction(() => [
            this.bagId,
        ] as const, () => {
            this.ppvPageController.loadData()
        })

    }

    init(databagId: number, taxonomySize: number) {
        this.databag_id = databagId;
        this.maxTaxonomySize = taxonomySize;
        this.selectedCategory = [];
        if (this.activeFilter) {
            this._spendConcentration.request(this.activeFilter)
            this._rootStatRequestManager.request({databag: databagId})
            this._ppvTotalExpectedSavings.request({databag: databagId})
        } else {
            this._spendConcentration.cleanup()
            this._rootStatRequestManager.cleanup()
            this._ppvTotalExpectedSavings.cleanup()
        }
    }

    get activeFilter(): AbsTreeNodeFilter | undefined {
        if (this.databag_id === undefined) return undefined;
        const level = this.selectedCategory.length
        const selectedCats = catsToDict(this.selectedCategory, level);
        return {
            type: 'children',
            databag: this.databag_id,
            level: level,
            ...selectedCats,
        }
    }


    get totalSuppliers(): number | undefined {
        return this._rootStatRequestManager.result?.data.n_suppliers;
    }

    get totalSpend(): number | undefined {
        return this._rootStatRequestManager.result?.data.total_spend;
    }

    get unclassifiedSpend(): number | undefined {
        return this._rootStatRequestManager.result?.data.unclassified_spend;
    }

    get totalExpectedSavings(): number | undefined {
        return this._ppvTotalExpectedSavings.result?.data.total_expected_savings;
    }

    get bagId(): number {
        return this.bagStore.bagId;
    }

    setEditNoteDialogOpen(open: boolean) {
        this.isEditNoteDialogOpen = open;
    }

    setDeleteGroupModalOpen(open: boolean) {
        this.isDeleteGroupModalOpen = open;
    }

    setPpvGroupToDelete(group: PpvGroupRowState, part: PpvPartRowState) {
        this.ppvGroupToDelete = group;
        this.ppvItemToDelete = part;
    }


    saveNote(note: string, onUpdateNote: (note: string) => void) {
        if (!this.ppvRowToEdit) return;

        const opportunityId = this.ppvRowToEdit.data.opportunity?.id;
        opportunityId
            ? this.api.updatePpvOpportunity(opportunityId, {note}).then(() => onUpdateNote(note))
            : this.createNewOpportunity(this.ppvRowToEdit, this.ppvRowToEdit.data.opportunity?.status || OpportunityStatusEnum.PENDING_REVIEW).then(() => onUpdateNote(note));
    }

    changeStatus(groupedRow: PpvGroupRowState, enumValue: OpportunityStatusEnum) {
        analyticsOpportunityEvent({
            type: 'ppv',
            key: groupedRow.key,
            status: getOpportunityStatusName(enumValue),
            expected_savings: groupedRow.data.ppv_opportunity,
            user: this.authStore.email,
        })
        this.setPpvRowToEdit(groupedRow);

        if (groupedRow?.data?.opportunity) {
            groupedRow.data.opportunity.status = enumValue;
        }

        if (!this.ppvRowToEdit) return;

        const opportunityId = this.ppvRowToEdit.data.opportunity?.id;
        const updateOrCreateOpportunity = opportunityId
            ? this.api.updatePpvOpportunity(opportunityId, {status: enumValue})
            : this.createNewOpportunity(groupedRow, enumValue);

        updateOrCreateOpportunity.finally(() => {
            this._ppvTotalExpectedSavings.request({databag: this.bagId});
            this.updateGroupedRowStatus(groupedRow);
        });
    }

    private createNewOpportunity(groupedRow: PpvGroupRowState, status: OpportunityStatusEnum) {
        if (!groupedRow.data.opportunity) {
            groupedRow.data.opportunity = {
                id: -1,
                ppv_group_key: groupedRow.data.p_description,
                databag: this.bagId,
                status: status,
                scope: this.ppvPageController.ppvPageFilterController.selectedFilter,
                note: '',
                initial_expected_savings: groupedRow.data.ppv_opportunity,
                current_expected_savings: groupedRow.data.ppv_opportunity,
                modified: new Date().toISOString()
            };
        }

        return this.api.createPpvOpportunity({
            ppv_group_key: groupedRow.data.p_description,
            databag: this.bagId,
            note: groupedRow.data.opportunity.note || '',
            status: status,
            scope: this.ppvPageController.ppvPageFilterController.selectedFilter,
            expected_savings: groupedRow.data.ppv_opportunity
        });
    }

    private updateGroupedRowStatus(groupedRow: PpvGroupRowState) {
        this.ppvPageController.ppvPageDataController.fetchGroups(this.ppvPageController.ppvPageFilterController.selectedFilter).then(supplierPages => {
            if (!supplierPages) return;

            const updatedGroup = supplierPages.find(response => response.data.p_description === groupedRow.data.p_description);
            if (updatedGroup?.data.opportunity && groupedRow.data.opportunity) {
                groupedRow.data.opportunity.status = updatedGroup.data.opportunity.status;  // TODO: CAT-1968: changing (observed) observable values without using an action is not allowed
            }
        });
    }


    setPpvRowToEdit(ppvRow: PpvGroupRowState) {
        this.ppvRowToEdit = ppvRow
    }

    goToPpvScope(history: any, filter: PpvGroupFilter) {
        this.ppvPageController.ppvPageFilterController.setActiveGroupFilter(filter)
        history.push(generatePath(routes.job_koi_ppv, {id: this.bagId}))
        this.ppvPageController.ppvPageFilterController.setActiveGroupFilter(filter)
    }

}
