import {AxoisRequestManager} from "../managers/RequestManager";
import {from} from "rxjs";
import {PageResponse} from "../../services/ApiTypes";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable, runInAction} from "mobx";
import {environment} from "../../env";
import {
    MatSupplierReviewStatistics,
    ParentSupplierChoice,
    ParentSupplierReviewRow,
    SupplierNormalizationState,
    SupplierReviewRow,
    UpdateParentSupplierReviewRowData,
    UpdateSupplierData
} from "../../pages/supplier-normalization/pages/SupplierNormalization.classes";
import {initialListFilters, StringArrayFilter} from "../../services/ApiHelpers";
import {SupplierParentIdentificationJobDelegate} from "./SupplierParentIdentificationJobDelegate";
import ProfileStore from "../ProfileStore";


export class SupplierNormalizationStore {
    state: SupplierNormalizationState = SupplierNormalizationState.START;
    isMaster = false;
    databagId: number = 0;
    jobDelegate = new SupplierParentIdentificationJobDelegate(this.api, this);
    selectedRowId: number = 0;
    openRowId: number = 0;
    statistics?: MatSupplierReviewStatistics;

    readonly parentSupplierRM = new AxoisRequestManager<{
        filters: string[][]
    }, PageResponse<ParentSupplierReviewRow>>(
        ({filters}) => from(this.api.listParentSupplierReviewRows(filters)));
    parentSupplierFilters: StringArrayFilter = new StringArrayFilter(this.parentSupplierRM, initialListFilters(10, [['sp_n_suppliers__gt', '1']]));
    readonly parentSupplierSearchRM = new AxoisRequestManager<{
        filters: string[][]
    }, PageResponse<ParentSupplierChoice>>(
        ({filters}) => from(this.api.listSuggestedParentSupplierReviewRows(filters)));
    parentSupplierSearchFilters: StringArrayFilter = new StringArrayFilter(this.parentSupplierSearchRM, initialListFilters(100));
    readonly supplierRM = new AxoisRequestManager<{ filters: string[][] }, PageResponse<SupplierReviewRow>>(
        ({filters}) => from(this.api.listSupplierReviewRows(filters)));
    supplierFilters: StringArrayFilter = new StringArrayFilter(this.supplierRM);

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this);
    }

    init(databagId: number, onlyStatistics = true) {
        if (this.databagId !== databagId) {
            this.databagId = databagId;
            this.statistics = undefined;
            this.api.getMatSupplierReviewStatistics(databagId).then(r => this.statistics = r.data);
            if (!onlyStatistics) {
                this.parentSupplierFilters.setFilters([['databag_id', this.databagId.toString()]]);
                this.supplierFilters.setFilters([['databag_id', this.databagId.toString()]], false);
                this.jobDelegate.init(databagId);
            }
        }
    }

    get stepNumber(): number {
        switch (this.state) {
            default:
            case 'start':
            case 'running':
                return 0;
            case 'review':
                return 1;
            case 'applying':
            case 'done':
                return 2;
        }
    }

    goToState(state: SupplierNormalizationState) {
        // Demo flow is implemented within this block. Note that in demo state,
        // RUNNING job should be replaced with a timeout and no actual job is created.
        if (this.profileStore.p.skipAiSupplierNormalizationModel) {
            this.state = state;
            switch (this.state) {
                case SupplierNormalizationState.RUNNING:
                    setTimeout(() => {
                        this.goToState(SupplierNormalizationState.REVIEW);
                    }, environment.aiResultFakeTimeout);
                    break;
                case SupplierNormalizationState.APPLYING:
                    setTimeout(() => {
                        this.goToState(SupplierNormalizationState.DONE);
                    }, environment.aiResultFakeTimeout);
                    break;
            }
            return;
        } else {
            // When a job is running, user can not proceed to review, but has to wait for job to finish
            if (this.jobDelegate.jobRunning &&
                this.state === SupplierNormalizationState.START && state === SupplierNormalizationState.REVIEW) {
                this.state = SupplierNormalizationState.RUNNING;
                return;
            }
            // When no job is running (notice exclusion of undefined) proceeding to review is allowed, but ru
            if (this.jobDelegate.jobRunning === false &&
                this.state === SupplierNormalizationState.START && state === SupplierNormalizationState.RUNNING) {
                    this.jobDelegate.createJob();
                    return;
            }
            // When user goes from REVIEW to APPLYING, statistics should be updated with API call
            if (this.state === SupplierNormalizationState.REVIEW && state === SupplierNormalizationState.APPLYING) {
                this.state = SupplierNormalizationState.APPLYING;
                this.updateStatistics().then(() => {
                    if (this.state === SupplierNormalizationState.APPLYING)
                        this.goToState(SupplierNormalizationState.DONE)
                });
                return;
            }
            // Otherwise, state should be accepted
            this.state = state;
        }
    }

    updateStatistics(): Promise<unknown> {
        this.statistics = undefined;
        return this.api.updateMatSupplierReviewStatistics(this.databagId).then(r => {
            runInAction(() => this.statistics = r.data);
        });
    }

    setSelectedRowId(id: number = 0) {
        this.selectedRowId = id;
        this.supplierFilters.setFilters([['page', '1']], false);
    }

    refreshTableData() {
        this.parentSupplierRM.request({filters: this.parentSupplierFilters.filters});
        if ((this.supplierFilters.filters.find(i => i[0] === 'parent_supplier_review_row')?.at(1) ?? '0') !== '0')
            this.supplierRM.request({filters: this.supplierFilters.filters});
    }

    updateParentSupplier(data: UpdateParentSupplierReviewRowData) {
        this.api.updateParentSupplierReviewRow(data).then(() => this.refreshTableData());
    }

    deleteParentSupplier(id: number) {
        this.api.deleteParentSupplierReviewRow(id).then(() => this.refreshTableData());
    }

    updateSupplier(data: UpdateSupplierData) {
        this.api.updateSupplierReviewRow(data).then(() => this.refreshTableData());
    }

    deleteSupplier(id: number) {
        this.api.deleteSupplierReviewRow(id).then(() => this.refreshTableData());
    }
}
