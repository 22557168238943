import '../dashboard/DashBoard.scss'
import './AdvancedDashboard.scss'
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Typography
} from "@mui/material";
import {JobRouteMatch, routes} from "../../routing/routes";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {useRouteMatch} from "react-router-dom";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS} from "../../components/dashboard/Dashboard.utils";
import {TreeMap} from "../../components/visualization/tree-map/TreeMap";
import {BubbleMap} from "../../components/visualization/bubble-map/BubbleMap";
import {LineChart} from "../../components/visualization/line-chart/LineChart";
import {AdvancedTable, HeaderSpecification} from '../../components/data-table/AdvancedTable';
import {environment} from '../../env';
import {AdvancedDashboardHeaderFilters} from "./AdvancedDashboardHeaderFilters";
import {AdvancedDashboardCategoryFilters} from "./AdvancedDashboardCategoryFilters";
import {DonutChart} from "../../components/visualization/donut-chart/DonutChart";
import {AdvancedBarChart} from "../../components/visualization/bar-chart/AdvancedBarChart";
import {generateHomePath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {generatePath, useHistory} from "react-router";
import {runInAction} from "mobx";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

/**
 * These two graphs need to be styled better
 */
const DISABLE_TWO_GRAPHS: boolean = true;

export const AdvancedDashboard: React.FC = observer(() => {
    const {rootAppStore, p, bagStore, loadingBarStore, businessCaseGeneratorStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const isMergeXMergePage = routeMatch.path === routes.merge_x_merge_advanced_dashboard;
    const mergeXmergeDashboardId = p.p.mergeXmergeOpportunityDashboardBagId;
    const databagId = isMergeXMergePage ? (mergeXmergeDashboardId || bagStore.bagId) : bagStore.bagId;
    const showL4 = Boolean(p.p.showL4InOpportunityDashboard) && !isMergeXMergePage;
    const hideParent = Boolean(p.p.hideParentSupplierOpportunityDashboard);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    // Check for a requested databag id change
    useEffect(() => {
        bagStore.advanced.setDatabagId(databagId);
    }, [databagId, bagStore.advanced])

    const isReady = Boolean(bagStore.advanced.kpi) && !bagStore.advanced.isLoading;
    useEffect(() => {
        if (!isReady) {
            loadingBarStore.start(true);
        } else {
            loadingBarStore.setComplete()
        }
    }, [isReady, loadingBarStore])

    useEffect(() => {
        runInAction(() => {
            businessCaseGeneratorStore.hasLoaded = false;
            businessCaseGeneratorStore.risksHaveLoaded = false;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    class HeaderSpecificationWithOrdering {
        onOrder?: () => void;

        constructor(public label: string, public key: string,
                    public type: 'string' | 'number' | 'currency' | '2_decimal' | 'category' | 'selection',
                    ordering_prefix: string) {
            this.onOrder = () => {
                if (bagStore.advanced.getOrderingWithPrefix(ordering_prefix) === key)
                    bagStore.advanced.setOrderingWithPrefix('-' + key, ordering_prefix);
                else
                    bagStore.advanced.setOrderingWithPrefix(key, ordering_prefix);
                return;
            }
        }
    }

    const debug = (val) => JSON.stringify(val, null, 2);

    const OPPORTUNITY_HEADERS: HeaderSpecificationWithOrdering[] = ([
        new HeaderSpecificationWithOrdering('L1', 'active_l1', 'category', 'opportunity_'),
        new HeaderSpecificationWithOrdering('L2', 'active_l2', 'category', 'opportunity_'),
        new HeaderSpecificationWithOrdering('L3', 'active_l3', 'category', 'opportunity_'),
        showL4 ? new HeaderSpecificationWithOrdering('L4', 'active_l4', 'category', 'opportunity_') : undefined,
        new HeaderSpecificationWithOrdering('Opportunity rank', 'opportunity_rank', '2_decimal', 'opportunity_'),
        new HeaderSpecificationWithOrdering('Spend', 'spend', 'currency', 'opportunity_'),
    ] as (undefined | HeaderSpecification)[]).filter(v => v !== undefined) as HeaderSpecification[];
    const SUP_BREAKDOWN_HEADERS = ([
        hideParent ? undefined : new HeaderSpecificationWithOrdering('Parent Supplier', 'sp_name', 'string', 'sup_breakdown_'),
        new HeaderSpecificationWithOrdering('Supplier name', 's_name', 'string', 'sup_breakdown_'),
        p.p.showSupplierIdInAdvDashboard ? new HeaderSpecificationWithOrdering('Supplier id', 's_id', 'string', 'sup_breakdown_') : undefined,
        new HeaderSpecificationWithOrdering('Supplier country', 's_country_code', 'string', 'sup_breakdown_'),
        new HeaderSpecificationWithOrdering('L1', 'active_l1', 'category', 'sup_breakdown_'),
        new HeaderSpecificationWithOrdering('L2', 'active_l2', 'category', 'sup_breakdown_'),
        new HeaderSpecificationWithOrdering('L3', 'active_l3', 'category', 'sup_breakdown_'),
        showL4 ? new HeaderSpecificationWithOrdering('L4', 'active_l4', 'category', 'sup_breakdown_') : undefined,
        p.p.synergyDashboardShowDatabagName ? new HeaderSpecificationWithOrdering('Dataset name', 'databag_name', 'string', 'sup_breakdown_') : undefined,
        new HeaderSpecificationWithOrdering('Total spend', 'total_spend', 'currency', 'sup_breakdown_'),
    ] as (undefined | HeaderSpecification)[]).filter(v => v !== undefined) as HeaderSpecification[];

    const pageTitle = isMergeXMergePage ? 'Synergy Dashboard' : 'Opportunity Dashboard';
    return <KoiFacade
        title={pageTitle}
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, routeMatch.params, rootAppStore.app)}
        className="advanced-dashboard-page">
        <Grid container
              className="advanced dashboard dashboard-v2-wrapper"
              style={{minHeight: 'unset', marginBottom: '2em'}}
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.advanced.kpi ? String(bagStore.advanced.kpi.n_categories) : DASHBOARD_CONSTANTS.VALUE_LOADING}>
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.advanced.kpi ?
                    <CurrencyComponent v={bagStore.advanced.kpi.total_spend}
                                       nDigits={3}/> : DASHBOARD_CONSTANTS.VALUE_LOADING}>
            </DashboardRow>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.advanced.kpi ? String(bagStore.advanced.kpi.n_suppliers) : DASHBOARD_CONSTANTS.VALUE_LOADING}>
            </DashboardRow>
            <DashboardRow
                title="Transactions"
                className="transactions-row"
                subtitle={bagStore.advanced.kpi ?
                    (bagStore.advanced.kpi.total_transactions ? String(bagStore.advanced.kpi.total_transactions) : '0') : DASHBOARD_CONSTANTS.VALUE_LOADING}
            >
            </DashboardRow>
        </Grid>

        {environment.isTest && <>
            {/* Debug info */}
            <pre
                style={{maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto', height: '10em', maxHeight: '10em'}}>
                {debug(bagStore.advanced.filters)}
            </pre>
        </>}

        <AdvancedDashboardHeaderFilters/>

        {p.p.demoFakeBusinessCaseGenerator && bagStore.advanced.filters.category && (
            <Alert
                severity="info"
                action={
                    <>
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                            <FormControl variant='standard' style={{width: '20em', marginRight: '1em'}}>
                                <InputLabel id="business-case-dropdown-label">Select Strategy</InputLabel>
                                <Select
                                    labelId="business-case-dropdown-label"
                                    id="business-case-dropdown"
                                    label="Select Strategy"
                                    onChange={(event) => {
                                        runInAction(() => {
                                            businessCaseGeneratorStore.selectedStrategy = event.target.value;
                                        });
                                    }}
                                >
                                    <MenuItem value={1}>Demand / supplier consolidation</MenuItem>
                                    <MenuItem value={2}>Time to market ( TTM ) optimization</MenuItem>
                                    <MenuItem value={3}>Tail spend reduction</MenuItem>
                                    <MenuItem value={4}>Optimize cash flow</MenuItem>
                                    <MenuItem value={5}>Price variation optimization</MenuItem>
                                    <MenuItem value={6}>Multi - source strategy</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                style={{width: '15em'}}
                                disabled={!businessCaseGeneratorStore.selectedStrategy || isLoading}
                                onClick={() => {
                                    setIsLoading(true);
                                    // Simulate a delay for 1.8 seconds and perform your actions
                                    setTimeout(() => {
                                        runInAction(() => {
                                            businessCaseGeneratorStore.hasLoaded = false;
                                            businessCaseGeneratorStore.pageController.navigateToPage('review-opportunities');
                                        });
                                        history.push(generatePath(routes.job_business_case_report, {id: bagStore.bagId}));
                                        setIsLoading(false);
                                    }, 1800);
                                }}
                            >
                                {isLoading ? 'Generating...' : 'Generate Business Case'}
                            </Button>
                        </div>
                    </>
                }
            >
                {/*<AlertTitle>You can use Gen AI to build your Business case</AlertTitle>*/}
                {/*Based on the data in this dashboard, you can generate a business case.*/}
                <AlertTitle>Select scope and defined category strategy to build your business case</AlertTitle>
                Based on this selection, you can use Mithra Ai assistant to kick start a business case
            </Alert>
        )}

        <Grid container>

            <Grid container item>
                <Grid item xs={6} style={{padding: '0.5em'}}>
                    <BubbleMap
                        data={bagStore.advanced.geoData}
                        countryFilterChanged={bagStore.advanced.countryFilterChanged}
                        selectedCountries={bagStore.advanced.filters.country}
                    />
                </Grid>
                <Grid item xs={6} style={{padding: '0.5em'}}>
                    <TreeMap
                        data={bagStore.advanced.treeData}
                        categoryFilterChanged={bagStore.advanced.categoryFilterChanged}
                    />
                </Grid>
            </Grid>

            <Grid container item>
                <Grid item xs={6} style={{padding: '0.5em'}}>
                    <Card style={{height: '100%'}}>
                        <CardContent>
                            <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                                Spend breakdown
                            </Typography>
                            <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                        className={'advanced-dashboard-subtitle'}>
                                Spend distribution by top suppliers
                            </Typography>
                            <Divider style={{marginTop: '0.5em', marginBottom: '0.5em'}}/>
                            <DonutChart
                                data={bagStore.advanced.spendDonutData}
                                loading={bagStore.advanced.spendDonutDataLoading}
                                onClickedSupplier={bagStore.advanced.supplierFilterChanged}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6} style={{padding: '0.5em'}}>
                    <Card style={{height: '100%'}}>
                        <CardContent>
                            <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                                Supplier spend
                            </Typography>
                            <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                        className={'advanced-dashboard-subtitle'}>
                                Total spend distribution per supplier
                            </Typography>
                            <Divider style={{marginTop: '0.5em', marginBottom: '0.5em'}}/>
                            <AdvancedBarChart
                                dataWrapper={bagStore.advanced.spendConcentrationData}
                                selectedSuppliers={bagStore.advanced.filters.s_ids}
                                supplierFilterChanged={bagStore.advanced.supplierFilterChanged}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Grid>

        <Typography variant={"h4"} className={'advanced-dashboard-title'}
                    style={{marginTop: '1.5em', marginBottom: '1.5em'}}>
            Category specific opportunities
        </Typography>

        <AdvancedDashboardCategoryFilters showL4={showL4}/>

        <section className="advanced-looker-tables">
            <AdvancedTable
                className="advanced-opportunity-table"
                headers={OPPORTUNITY_HEADERS}
                dataWrapper={bagStore.advanced.opportunityData}
                breakdownFilterChanged={bagStore.advanced.breakdownFilterChanged}
                ordering={bagStore.advanced.opportunity_ordering}
            />

            {!DISABLE_TWO_GRAPHS &&
                <section className="advanced-line-graph-container">
                    <Card>
                        <CardContent>
                            <Typography variant="h6">
                                Spend per supplier
                            </Typography>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom
                                        className={'advanced-dashboard-subtitle'}>
                                Analyzing trends in various categories
                            </Typography>
                            <Divider style={{marginTop: '1em', marginBottom: '1em'}}/>
                            <Box mt={2}>
                                <LineChart dataWrapper={bagStore.advanced.spendPerSupplierData}/>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">
                                Spend per level one category
                            </Typography>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom
                                        className={'advanced-dashboard-subtitle'}>
                                Analyzing trends in specific levels
                            </Typography>
                            <Divider style={{marginTop: '1em', marginBottom: '1em'}}/>
                            <Box mt={2}>
                                <LineChart dataWrapper={bagStore.advanced.spendPerActiveL1Data}/>
                            </Box>
                        </CardContent>
                    </Card>
                </section>
            }

            <div className="advanced-supplier-breakdown-table">
                <Typography variant={"h4"} className={'advanced-dashboard-title'}
                            style={{marginTop: '1.5em', marginBottom: '1.5em'}}>
                    Supplier and spend category overview
                </Typography>
                <AdvancedTable
                    className="advanced-supplier-breakdown-table"
                    headers={SUP_BREAKDOWN_HEADERS}
                    dataWrapper={bagStore.advanced.supplierBreakdownData}
                    compactNumbers={true}
                    breakdownFilterChanged={bagStore.advanced.breakdownFilterChanged}
                    ordering={bagStore.advanced.sup_breakdown_ordering}
                />
            </div>
        </section>
    </KoiFacade>
});
