import {IObservableArray, makeAutoObservable} from "mobx";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {taxonomy_editor} from "../components/visualization/taxonomy-editor/TaxonomyEditorTypes";
import {TaxonomyEditorController} from "../components/visualization/taxonomy-editor/TaxonomyEditorController";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";
import {ViewMode} from "../components/visualization/taxonomy-editor/TaxonomyEditorOptions";
import ProfileStore from "./ProfileStore";

const _DEBUG_START_IN_EDIT = false

const ALL_TAXONOMY_VALUE_MODES = [
    {
        key: 'n_categories' as const,
        calc: 'leaf=1' as const,
        initCalcFromLeaf: true as const,
        label: 'Number of categories',
    },
    {
        key: 'p__spend__sum' as const,
        calc: 'avg_dist' as const,
        initCalcFromLeaf: true as const,
        label: 'Total spend',
    },
    {
        key: 'equal' as const,
        calc: 'equal' as const,
        initCalcFromLeaf: false as const,
        label: 'Equal',
    },
]
/**
 * Value mode in which all edit functionalities are supported
 */
const EDIT_VALUE_MODE = 'n_categories' as const;

export type Category = { node_id: number, label: string, values: string[] };
export type TaxonomyNodeValueMode = typeof ALL_TAXONOMY_VALUE_MODES[number]

type Node = taxonomy_editor.GraphNode;
type OLDNODE = any;

export const DEFAULT_VIEW_MODE: ViewMode = 'fit'

/**
 * For showing and interacting with the taxonomy controller
 */
export default class TaxonomyEditorStore {

    allTaxonomyValueModes: TaxonomyNodeValueMode[];
    valueMode: TaxonomyNodeValueMode;

    /**
     * The focus element or undefined when nothing is focussed
     */
    focus: Node | undefined

    readonly selection: IObservableArray<Node>

    isEditMode = _DEBUG_START_IN_EDIT

    isUpdateCategoryMode = false;
    updateCategoryName = '';
    updateCategoryNameError = '';
    updateCategoryDescription = '';
    updateCategoryDescriptionError = '';

    isCreateCategoryMode = false;
    newCategoryName = '';
    newCategoryNameError = '';
    newCategoryDescription = '';
    newCategoryDescriptionError = '';

    isMergeCategoryMode = false;
    mergeCategoryName = '';
    mergeCategoryNameError = '';
    mergeCategoryDescription = '';
    mergeCategoryDescriptionError = '';

    isMoveCategoryMode = false;
    moveCategoryDestination?: Category;

    private controller?: TaxonomyEditorController;

    healthButtonText = "Health"
    disableHealth = false

    /**
     * If the taxonomy is full sized, or is fitted to the height of the screen
     * Only affects the height of the taxonomy
     */
    viewMode: ViewMode = DEFAULT_VIEW_MODE;

    setHealthButtonText(healthButtonText: string) {
        this.healthButtonText = healthButtonText
    }

    setDisableHealth(disableHealth: boolean) {
        this.disableHealth = disableHealth
    }

    constructor(
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
    ) {
        this.selection = [] as any;
        if (this.profileStore.p.taxonomy_builder_only_n_cats) {
            this.allTaxonomyValueModes = ALL_TAXONOMY_VALUE_MODES.slice(0, 1);
        } else {
            this.allTaxonomyValueModes = ALL_TAXONOMY_VALUE_MODES
        }
        this.valueMode = this.allTaxonomyValueModes[0];
        this.taxonomyManagerStore.taxonomyEditorStore = this;
        makeAutoObservable(this)
    }


    get selectionSiblings() {
        if (this.selection.length === 1) {
            const selected = this.selection[0];
            const siblings = selected.parent?.children;
            if (siblings) {
                return siblings.filter(c => c !== selected)
            }
        }
        return []
    }

    getFocusParent(): taxonomy_editor.GraphNode | undefined {
        return this.focus?.parent || undefined
    }

    toggleSelection(node: Node) {
        const selectedI = this.selection.findIndex(s => s.data.id === node.data.id)
        if (selectedI === -1) {
            this.selection.push(node)
            return true
        } else {
            this.selection.splice(selectedI, 1)
            return false
        }
    }

    // setFocus(node: OLDNODE | undefined) {
    //     const newFocus = TaxonomyEditorStore.changeFocus(node, this.focus)
    //     // console.log('setFocus from', this.focus?.id, 'to', newFocus ? newFocus.id : newFocus)
    //     if (newFocus !== false)
    //         this.focus = newFocus
    // }

    get focusLevel(): number {
        return (this.focus?.depth || 0) + 1
    }

    get goBackButtonTxt(): string {
        // if (!this.focus || this.focus.depth <= 1) {
        //     return 'Go back to All'
        // }
        const backLevel = this.focus?.depth || 1;
        return `Go back to L${backLevel}`
    }

    setEditMode(editMode: boolean) {
        this.isEditMode = editMode
    }

    get lastSelected() {
        if (this.selection.length === 0) {
            return undefined
        }
        return this.selection[this.selection.length - 1];
    }

    get sameLevelSelection(): OLDNODE[] {
        const depthLevel = Math.min(...this.selection.map(n => n.depth))
        return this.selection.filter(n => n.depth === depthLevel)
    }

    setCreateCategoryMode(b: boolean) {
        this.isCreateCategoryMode = b
        this.setNewCategoryName(this.newCategoryName)
    }

    setNewCategoryName(name: string) {
        this.newCategoryName = name

        const i = this.newNameExists;
        if (i >= 0) {
            this.newCategoryNameError = 'Category already exists'
        } else if (name.trim() === '') {
            this.updateCategoryNameError = 'Category name cannot be empty'
        } else {
            this.newCategoryNameError = ''
        }
    }

    setNewCategoryDescription(name: string) {
        this.newCategoryDescription = name
        this.newCategoryDescriptionError = ''
    }

    setMergeCategoryName(name: string) {
        this.mergeCategoryName = name

        const i = this.mergeNameExists;
        if (i >= 0) {
            this.mergeCategoryNameError = 'Category already exists'
        } else if (name.trim() === '') {
            this.updateCategoryNameError = 'Category name cannot be empty'
        } else {
            this.mergeCategoryNameError = ''
        }
    }

    setMergeCategoryDescription(description: string) {
        this.mergeCategoryDescription = description
        this.mergeCategoryDescriptionError = ''
    }

    setMoveCategoryDestination(category: Category) {
        this.moveCategoryDestination = category
    }

    get canSaveNewCategory() {
        return Boolean(this.newCategoryName)
            && !Boolean(this.newCategoryNameError)
            && !Boolean(this.newCategoryDescriptionError)
    }

    get canMergeCategories() {
        return Boolean(this.mergeCategoryName && !this.mergeCategoryNameError)
    }

    checkCanMergeCategories(): false | Node[] {
        // Executed when a merge attempt is started

        let selection = this.sameLevelSelection;
        if (selection.length !== this.selection.length) {
            // Normalize selections over different levels
            this.setSelection(selection)
            this.mergeCategoryNameError = 'Multiple levels selected'
            return false
        }
        if (selection.length <= 1) {
            console.log(`Not enough nodes are selected, selection=${selection.length}`)
            this.mergeCategoryNameError = `Select at least two categories`
            return false
        }

        // Determine the parent
        const parents = selection.map(n => n.parent)
        const uniqueParents = new Set(parents.map(n => n?.id))
        if (uniqueParents.size !== 1) {
            console.warn('Not allowed to merge nodes that do not have a common parent',
                'selection=' + JSON.stringify(this.selection.map(n => n.data.id))
            )

            // Change the selection to the last selected category
            const targetParentId = selection[selection.length - 1].parent?.id
            const newSelection = selection.filter(n => n.parent?.id === targetParentId);
            this.setSelection(newSelection)
            this.mergeCategoryNameError = 'Can only merge if the are in the same parent category'
            return false
        }
        const selectionParent = selection[0].parent;
        const parentChildren = selectionParent?.children;
        if (!selectionParent || !parentChildren) {
            console.warn('Merging of roots is not allowed',
                'selection=' + JSON.stringify(this.selection.map(n => n.data.id))
            )
            return false
        }
        return selection
    }

    get canMoveCategories() {
        return Boolean(this.moveCategoryDestination)
    }

    get taxonomyParentOptions(): Category[] {
        // return this.root.leaves().map(n => ({
        //     id: `${n.id}`,
        //     values: n.ancestors().reverse().map(a => a.data.label)
        // }))
        if (!this.controller?.root) return []
        return this.controller.root.descendants().map((n, i) => {
            if (i === 0) {
                return ({
                    node_id: n.data.id,
                    label: 'Taxonomy root',
                    values: ['Taxonomy root'],
                })
            }
            const labels = TaxonomyEditorController.getLabels(n);
            return ({
                node_id: n.data.id,
                label: labels.join(' > '),
                values: labels,
            });
        })
    }

    get newNameExists(): number {
        let pool: Node[] | undefined;
        if (!this.focus) {
            return -1
        }
        pool = this.focus.children;
        return TaxonomyEditorStore.nameExists(pool, this.newCategoryName)
    }

    get mergeNameExists(): number {
        let pool: OLDNODE[] | undefined;

        const selection = this.sameLevelSelection;
        if (selection.length > 0) {
            const parent = selection[0].parent
            pool = parent?.children
            if (!pool) {
                console.warn('Expected parent to have children', parent)
                return -3
            }
            pool = pool.filter(n => !selection.some(s => s.id === n.id))
        }

        return TaxonomyEditorStore.nameExists(pool, this.mergeCategoryName)
    }

    private static nameExists(pool: Node[] | undefined, name: string) {
        if (!pool) return -2
        name = name.toLowerCase();
        return pool.findIndex(n =>
            n.data.showLabel.toLowerCase() === name
        )
    }

    closeModeDialog() {
        this.isCreateCategoryMode = false
        this.isMergeCategoryMode = false
    }

    saveNewCategory() {
        if (!this.controller || !this.taxonomyManagerStore.taxonomy) return;
        if (!this.canSaveNewCategory) {
            console.warn('Cannot save category',
                this.newCategoryNameError,
                this.newCategoryDescriptionError,
            )
            return;
        }
        this.isCreateCategoryMode = false

        this.controller.addNode({
            newId: this.taxonomyManagerStore.taxonomy.next_node_id,
            label: this.newCategoryName.trim(),
            description: this.newCategoryDescription.trim(),
            value: 1,
        })
        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Add,
            this.controller.exportTree(),
        )

        this.newCategoryName = ''
        this.newCategoryNameError = ''
        this.newCategoryDescription = ''
        this.newCategoryDescriptionError = ''
    }

    setMergeCategoryMode(b: boolean) {
        this.isMergeCategoryMode = b
        // // Optionally: Prefill the name with the first element from the selection
        // if (b) {
        //     if (this.selection.length > 0) {
        //         this.setMergeCategoryName(this.selection[0].data.label)
        //     }
        // }
    }

    saveMergeCategory() {
        if (!this.controller || !this.taxonomyManagerStore.taxonomy) return;

        // Check if we can merge
        const source = this.checkCanMergeCategories()
        if (!source) {
            return;
        }
        if (this.mergeCategoryNameError) {
            console.warn('Cannot merge category', this.mergeCategoryNameError)
            return;
        }

        /////////////////////////
        // Do the actual merge //
        /////////////////////////
        this.isMergeCategoryMode = false
        this.controller.mergeNodes({
            sources: source,
            destination: {
                newId: this.taxonomyManagerStore.taxonomy.next_node_id,
                label: this.mergeCategoryName.trim(),
                description: this.mergeCategoryDescription.trim(),
                value: 1, // TODO
            }
        })
        this.setSelection([])

        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Merge,
            this.controller.exportTree(),
        )

        this.mergeCategoryName = ''
        this.mergeCategoryNameError = ''
        this.mergeCategoryDescription = ''
        this.mergeCategoryDescriptionError = ''
    }

    toggleEditMode() {
        const editMode = !this.isEditMode;
        this.isEditMode = editMode;
        if (editMode) {
            // Not all edit functionalities are supported in edit mode
            // We did not intend to build these
            this.setValueMode(EDIT_VALUE_MODE);
            this.viewMode = 'full';
        } else {
            this.viewMode = 'fit';
        }
    }

    setMoveCategoryMode(b: boolean) {
        this.isMoveCategoryMode = b
    }

    saveMoveCategory() {
        if (!this.controller) return;
        if (!this.canMoveCategories) {
            return
        }
        this.isMoveCategoryMode = false
        if (this.moveCategoryDestination) {
            const newParent = this.controller.moveNodes(this.selection, this.moveCategoryDestination)
            if (newParent) {
                this.setSelection([newParent])

                this.taxonomyManagerStore.onChangeTaxonomy(
                    m_taxonomy.Operation.Move,
                    this.controller.exportTree(),
                )
            }
        }
    }

    deleteSelection() {
        if (!this.controller) return;
        this.controller.deleteNodes(this.selection)

        this.setSelection([])
        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Delete,
            this.controller.exportTree(),
        )
    }

    goUpLevel() {
        this.controller?.moveFocusUp()
    }

    get canGoUp() {
        const focus = this.focus;
        return focus && focus.depth > 0
    }

    // static preProcessInputData(
    //     inputData: taxonomy_editor.InputData,
    //     options?: PreprocessOptions,
    //     depth = 0,
    // ): taxonomy_editor.Tree {
    //     const d = treeClone(inputData)
    //     return TaxonomyEditorStore._preProcessInputData(d, options || {}, depth)
    // }
    //
    // private static _preProcessInputData(
    //     d: taxonomy_editor.InputData,
    //     options: PreprocessOptions,
    //     depth: number,
    // ): taxonomy_editor.Tree {
    //
    //     if (!options.keepUncategorized) {
    //         // Remove all uncategorized
    //         d.children = d.children.filter(c => c.label !== UNCATEGORIZED_VALUE)
    //     }
    //
    //     // // Set values to 1
    //     // d.value = 1
    //
    //     if (options.limitData) {
    //         // Only focus on small part for testing
    //         if (depth >= 0) {
    //             d.children = d.children.filter((_, i) => i <= 2)
    //             d.children.forEach((c: any, i) => { // TODO
    //                 c.value = i * 10
    //             })
    //         }
    //     }
    //
    //     d.children = d.children.map(c => TaxonomyEditorStore._preProcessInputData(c, options, depth + 1))
    //     return d as taxonomy_editor.Tree
    // }

    // handleClickNode(n: OLDNODE) {
    //     if (this.isEditMode) {
    //         this.toggleSelection(n) // triggers: drawSelection()
    //     } else {
    //         this.setSelected([]) // triggers: drawSelection()
    //         this.setFocus(n) // potentially triggers a change of focus
    //     }
    //     this.error = ''
    // }

    clearError() {
        this.newCategoryNameError = ''
        this.mergeCategoryNameError = ''
    }

    setFocusAndClearSelection(node: Node) {
        this.focus = node
        this.selection.clear()
    }

    setSelection(selection: taxonomy_editor.GraphNode[]) {
        this.selection.replace(selection)
    }

    registerController(controller: TaxonomyEditorController) {
        this.controller = controller
    }

    setFocus(focus: Node) {
        this.focus = focus
    }

    setUpdateCategoryName(name: string) {
        this.updateCategoryName = name

        const i = TaxonomyEditorStore.nameExists(this.selectionSiblings, name);
        if (i >= 0) {
            this.updateCategoryNameError = 'Category already exists'
        } else if (name.trim() === '') {
            this.updateCategoryNameError = 'Category name cannot be empty'
        } else {
            this.updateCategoryNameError = ''
        }
    }

    setUpdateCategoryDescription(description: string) {
        this.updateCategoryDescription = description
        this.updateCategoryDescriptionError = '' // Not used
    }


    setUpdateCategoryMode(b: boolean) {
        this.loadNodeDataToEdit()
        this.isUpdateCategoryMode = b
    }

    get canUpdateCategory() {
        return this.selection.length === 1
            && !Boolean(this.updateCategoryNameError)
            && !Boolean(this.updateCategoryDescriptionError)
    }

    saveUpdateCategory() {
        if (!this.controller) return;
        if (!this.canUpdateCategory) {
            return
        }
        this.isUpdateCategoryMode = false
        this.controller.updateNodeFields(this.selection[0], this.updateCategoryName.trim(), this.updateCategoryDescription.trim())

        this.taxonomyManagerStore.onChangeTaxonomy(
            m_taxonomy.Operation.Update,
            this.controller.exportTree(),
        )

        this.updateCategoryName = ''
        this.updateCategoryNameError = ''
        this.updateCategoryDescription = ''
        this.updateCategoryDescriptionError = ''
    }

    setValueMode(valueModeKey: TaxonomyNodeValueMode['key']) {
        if (!this.controller) return;
        const newValueMode = this.allTaxonomyValueModes.find(m => m.key === valueModeKey)
        if (!newValueMode) return
        if (this.valueMode === newValueMode) return
        this.valueMode = newValueMode
        this.controller.updateValueMode()
    }

    getFocusedAncestors(inFocus?: Node) {
        let f: string[] = []
        if (inFocus === undefined) return
        if (!inFocus.parent) return
        do {
            f.push(inFocus.data.dataLabel)
            inFocus = inFocus?.parent
        } while (inFocus && inFocus.parent) //inFocus?.parent !== null || inFocus?.parent !== undefined
        return f.reverse()
    }

    loadNodeDataToEdit() {
        if (this.selection[0]) {
            this.updateCategoryName = this.selection[0].data.dataLabel
            this.updateCategoryDescription = this.selection[0].data.values.description
        }
    }

    toggleViewMode() {
        this.viewMode = this.viewMode === 'full' ? 'fit' : 'full'
    }

}
