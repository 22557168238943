import {ApiService} from "../services/Http";
import {AxiosInstance, AxiosResponse} from "axios";
import {Category, Company, Currency, Initiative, Lever, LeverGroup, Target} from "./ApiTypes";
import {PageResponse} from "../services/ApiTypes";

export default class SitApi extends ApiService {

    constructor(public http: AxiosInstance) {
        super(http);
    }

    // Company configuration
    listLeverGroups(): Promise<AxiosResponse<LeverGroup[]>> {
        return this.http.get(`/lever-groups/`, {params: {no_page: ''}});
    }

    listLevers(): Promise<AxiosResponse<Lever[]>> {
        return this.http.get(`/levers/`, {params: {no_page: ''}});
    }

    listCategories(): Promise<AxiosResponse<Category[]>> {
        return this.http.get(`/categories/`, {params: {no_page: ''}});
    }

    listCompanies(): Promise<AxiosResponse<Company[]>> {
        return this.http.get(`/companies/`, {params: {no_page: ''}});
    }

    listCurrencies(): Promise<AxiosResponse<Currency[]>> {
        return this.http.get(`/currencies/`, {params: {no_page: ''}});
    }

    // Target
    listTargets(): Promise<AxiosResponse<PageResponse<Target>>> {
        return this.http.get(`/targets/`);
    }

    // Initiative
    listInitiatives(): Promise<AxiosResponse<PageResponse<Initiative>>> {
        return this.http.get(`/initiatives/`);
    }

    getInitiative(id: number): Promise<AxiosResponse<Initiative>> {
        return this.http.get(`/initiatives/` + id.toString() + `/`);
    }
}