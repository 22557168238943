import {Divider, FormControl, Grid, InputLabel, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";
import React from "react";
import {useStores} from "../../../stores";
import {Analytics, Dashboard, Gavel, Info, Inventory, LocalShipping, RequestPage} from "@mui/icons-material";
import {ModalCardBlockType, ModalCardProps, ModalEditCardProps} from "../classes";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

type Props = {
    isOpen: boolean
}

export const ModalContractEdit: React.FC<Props> = observer(({isOpen}) => {
    const {contractInsightsStore} = useStores();

    const modalContractsDetailsData = contractInsightsStore.modalContractsDetailsData;
    const contractFinancialTermsCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Pricing',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.pricing ? [modalContractsDetailsData.pricing] : ["Product pricing consists of 'Added Value' and 'HDPE' components."]
        },
        {
            blockTitle: 'Payment Terms',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.payment_terms ? [modalContractsDetailsData.payment_terms] : []
        }
    ];
    const contractProductDetailsCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Price Table',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.price_table ? [modalContractsDetailsData.price_table] : [
                "Product thickness and width deviations allowed within\n" +
                "certain limits.\n" +
                "Over or under delivery acceptable within specified percentages based on net weight."]
        },
        {
            blockTitle: 'Quantity Threshold',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.quantity_threshold ? [modalContractsDetailsData.quantity_threshold] : ['Bright Motion Ams commits to purchasing an indicative volume annually, with potential increase during the contract period.']
        }
    ];
    const contractDeliveryNStockCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Delivery Conditions',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.delivery_conditions ? [modalContractsDetailsData.delivery_conditions] : ['DDP (Incoterms 2020) to LWM\'s locations in the\n' +
            'Netherlands.']
        },
        {
            blockTitle: 'Quantity Thresholds',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.quantity_thresholds ? [modalContractsDetailsData.quantity_thresholds] : ['BM Holding maintains a minimum stock level with maximum stock in the supply chain not exceeding 13 weeks.']
        }
    ];
    const contractTerminationNLegaCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Renewal Conditions',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.renewal_conditions ? [modalContractsDetailsData.renewal_conditions] : ['Not specified']
        },
        {
            blockTitle: 'Termination Provisions',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.termination_conditions ? [modalContractsDetailsData.termination_conditions] : ['Parties may agree to terminate before expiration.\n' +
            'Extraordinary termination provisions specified.']
        },
        {
            blockTitle: 'Liability & Legal',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.liability ? [modalContractsDetailsData.liability] : ['Various provisions regarding liability, confidentiality, disputes resolution, and governing law.']
        }
    ];
    const contractPerformanceNComplianceCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Performance Obligations',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.performance_obligations ? [modalContractsDetailsData.performance_obligations] : ['BM Holding must ensure skilled, dependable staff and comply with safety instructions.']
        }
    ];
    const contractGroupOfProductsCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Products / Services',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.products_services ? [modalContractsDetailsData.products_services] : ['(Un-) Printed Packaging Foils']
        },
        {
            blockTitle: 'Annual Cost & Volume',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.annual_cost_volume ? [modalContractsDetailsData.annual_cost_volume] : ['Not specified']
        }
    ];

    return <GenericModalVertical
        titleId={""}
        className="contract-details-modal"
        isOpen={isOpen}
        onSave={() => {
            contractInsightsStore.setEditModalVisible(false)
        }}
        saveTitle={"Done"}
        onCancel={() => {
            contractInsightsStore.setEditModalVisible(false)
        }}
        title={"Contract details"}
        maxWidth="md"
    >
        <Grid container>
            <Grid container item xs={10} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                <ModalEditCard
                    title={'Contract Info'}
                    contractTitle={'Supply Agreement for (Un-) Printed Packaging Foils'}
                    icon={<Info/>}
                    parentSupplier={'sosdkskdkso'}
                    endDate={'31-12-2025'}
                    startDate={'01-01-2022'}
                    sisterCompanies={['a', 'b']}
                    scope={'sadidojsajdioasjdioajdijaosj'}>
                </ModalEditCard>
                <ModalCard title="Contract Financial Terms" icon={<RequestPage/>}
                           blocks={contractFinancialTermsCard}/>
                <ModalCard title="Contract Product Details" icon={<Inventory/>}
                           blocks={contractProductDetailsCard}/>
                <ModalCard title="Contract Delivery & Stock" icon={<LocalShipping/>}
                           blocks={contractDeliveryNStockCard}/>
                <ModalCard title="Contract Termination & Legal" icon={<Gavel/>}
                           blocks={contractTerminationNLegaCard}/>
                <ModalCard title="Contract Performance & Compliance" icon={<Analytics/>}
                           blocks={contractPerformanceNComplianceCard}/>
                <ModalCard title="Contract Group of Products" icon={<Dashboard/>}
                           blocks={contractGroupOfProductsCard}/>
            </Grid>
        </Grid>
    </GenericModalVertical>
})

const ModalCard: React.FC<ModalCardProps> = ({title, icon, blocks}) => (
    <Grid container className={`dashboard-row`} style={{
        margin: '1em 0',
        padding: '1em',
        background: '#F6F6F6',
        borderRadius: '5px',
        border: '1px groove #D1D6DD'
    }}>
        <Grid item xs={1} className="row-icon">
            {icon}
        </Grid>
        <Grid item xs={11} className="row-title">
            <Typography variant="h5" component="h5">
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} className="row-content">
            {blocks.map(block => (
                block.bulletPoints.length > 0 && (
                    <>
                        <Grid item className="block" style={{marginTop: '1.5em', marginBottom: '0.5em'}}>
                            <Typography variant="h6" component="h6">
                                {block.blockTitle}
                            </Typography>
                            <ul>
                                {block.bulletPoints.map(bulletPoint => (
                                    <li>{bulletPoint}</li>
                                ))}
                            </ul>
                        </Grid>
                        <Divider/>
                    </>
                )
            ))}
        </Grid>
    </Grid>
);
const ModalEditCard: React.FC<ModalEditCardProps> = ({
                                                         title,
                                                         contractTitle,
                                                         icon,
                                                         parentSupplier,
                                                         sisterCompanies,
                                                         startDate,
                                                         endDate,
                                                         scope
                                                     }) => (
    <Grid container className={`modal-row`}
          style={{
              margin: '1em 0',
              padding: '1em',
              background: '#F6F6F6',
              borderRadius: '5px',
              border: '1px groove #D1D6DD'
          }}>
        <Grid item xs={1} className="row-icon">
            {icon}
        </Grid>
        <Grid item xs={11} className="row-title">
            <Typography variant="h5" component="h5">
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} className="row-content">
            <Grid item className="block" style={{margin: '1em '}}>
                <Typography variant="h6" component="h6">
                    Contract Title
                </Typography>
                <ul>
                    {contractTitle}
                </ul>
            </Grid>
            <Divider/>
            <Grid item className="block" style={{margin: '1em 0'}}>
                <FormControl fullWidth>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                        labelId="type-label"
                        id="type-select"
                        value={'Supplier Contract'}
                        defaultValue={'Supplier Contract'}
                        label="Type"
                        // onChange={handleTypeChange}
                    >
                        <MenuItem value="Supplier Contract">Supplier Contract</MenuItem>
                        <MenuItem value="Other Type">Other Type</MenuItem>
                        {/* Add more types as needed */}
                    </Select>
                </FormControl>
            </Grid>


            <Grid item className="block" style={{margin: '1em 0'}}>
                <FormControl fullWidth>
                    <InputLabel id="parent-supplier-label">Parent Supplier</InputLabel>
                    <Select
                        labelId="parent-supplier-label"
                        id="parent-supplier-select"
                        value={'BM Holding'}
                        defaultValue={'BM Holding'}
                        label="Parent Supplier"
                        // onChange={handleParentSupplierChange}
                    >
                        <MenuItem value="BM Holding">BM Holding</MenuItem>
                        {/* Add more parent suppliers as needed */}
                    </Select>
                </FormControl>
            </Grid>


            <Grid item className="block" style={{margin: '1em 0 '}}>
                <FormControl fullWidth>
                    <InputLabel id="sister-companies-label">Sister Companies</InputLabel>
                    <Select
                        labelId="sister-companies-label"
                        id="sister-companies-select"
                        defaultValue={'Bright motion Ams BV and 1 more'}
                        value={'Bright motion Ams BV and 1 more'}
                        label="Sister Companies"
                        // onChange={handleSisterCompaniesChange}
                    >
                        <MenuItem value="Bright motion Ams BV and 1 more">Bright motion Ams BV and 1 more</MenuItem>
                        {/* Add more sister companies as needed */}
                    </Select>
                </FormControl>
            </Grid>


            <Grid item className={'block'} style={{margin: '1em 0'}}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Effective Date"
                            value={new Date('01-01-2022')}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>

            <Grid item className={'block'}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Expiration Date"
                            value={new Date('12-31-2025')}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item className="block" style={{margin: '1em 0'}}>
                <Typography variant="h6" component="h6">
                    Scope
                </Typography>
                <ul>
                    Procurement of Printed and Unprinted Packaging Foils
                </ul>
            </Grid>
        </Grid>
    </Grid>
);