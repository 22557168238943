import React from 'react';
import './App.scss';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ThemeProvider} from '@mui/material/styles';
import {environment} from "./env";
import {DebugMenuBar} from "./components/debug-components/DebugMenuBar";
import {BrowserRouter, Switch} from 'react-router-dom';
import {_theme} from "./style/theme";
import git from "./utils/git.json";
import {C} from "./configurations/active-configuration";
import {activeRoutes} from "./routing/routing";
import {Auth0Provider} from '@auth0/auth0-react';
import {FirebaseProvider} from "./services/analytics/FirebaseProvider";

// USED:
// https://react-bootstrap.github.io/layout/grid/#col-props
// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/function_components
// https://react-dnd.github.io/react-dnd/examples/other/native-files
// https://material-ui.com/guides/typescript/

// Future work?
// https://www.typescriptlang.org/docs/handbook/react.html
// https://reactjs.org/docs/hooks-intro.html
// Redux react
// https://www.bergqvist.it/blog/2019/5/17/typing_react_with_typescript

export const App: React.FC = () => {
    // const locale = environment.locale || (window.navigator.language || 'en-GB');
    // moment.locale(locale);

    console.log('Version: ' + git.gitCommitHash)
    console.log('auth0ClientId: ' + environment.auth0ClientId)
    console.log('AppPackage: ' + String(C.package))

    return <FirebaseProvider>
        <Auth0Provider
            domain={environment.auth0Domain}
            clientId={environment.auth0ClientId}
            authorizationParams={{
                organization: environment.auth0Organization
            }}>
            <BrowserRouter>
                <ThemeProvider theme={_theme}>
                    <DndProvider backend={HTML5Backend}>
                        {environment.devMenu && <div className="position-absolute dev-menu" style={{zIndex: 1250}}>
                            <DebugMenuBar/>
                        </div>}
                        <Switch>
                            {activeRoutes}
                        </Switch>
                    </DndProvider>
                </ThemeProvider>
            </BrowserRouter>
        </Auth0Provider>
    </FirebaseProvider>
}
