/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import dayjs from 'dayjs';
import {useStores} from "../../../stores";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";

// noinspection ES6UnusedImports
import relativeTime from 'dayjs/plugin/relativeTime';
import {AdminPanelSettings, Bathtub, DeleteForever, PlayArrow, Publish, ThumbDown} from "@mui/icons-material";
import {UNCATEGORIZED_LABEL} from "../../../constants";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

export const RevisionSelectorDropdown = observer(() => {
    const {categorizationReviewStore, authStore} = useStores();
    const aiJobDelegate = categorizationReviewStore.aiJobDelegate;
    const revisionController = categorizationReviewStore.reviewPageController.reviewPageRevisionController;
    const statusDelegate = categorizationReviewStore.statusDelegate;
    const revisionId = typeof revisionController.revision === 'number' ? revisionController.revision : undefined;

    const [isPublishConfirmationOpen, setIsPublishConfirmationOpen] = React.useState(false);
    const [isRejectConfirmationOpen, setIsRejectConfirmationOpen] = React.useState(false);
    const [isDestroyConfirmationOpen, setIsDestroyConfirmationOpen] = React.useState(false);
    const [isApplyCutoffConfirmationOpen, setIsApplyCutoffConfirmationOpen] = React.useState(false);

    const [cutoffValue, setCutoffValue] = React.useState<string>(String(.5));
    const cutoffValueFloat = parseFloat(cutoffValue.replace(',', '.'));
    const cutoffValueValid = cutoffValueFloat >= 0 && cutoffValueFloat <= 1;

    const job = statusDelegate.status?.categorization_job.latest;
    const categorizationJobId = categorizationReviewStore.statusDelegate.categorizationJobId;


    useEffect(() => {
        if (authStore.isMithraStaffGroup) {
            revisionController._revisionRequestManager.request()
        }
    }, [authStore.isMithraStaffGroup, revisionController, categorizationReviewStore.statusDelegate.categorizationJobStatus])

    if (!authStore.isMithraStaffGroup) return <></>;
    return <Grid container alignItems="center" spacing={2}>
        <Dialog open={isPublishConfirmationOpen} onClose={() => setIsPublishConfirmationOpen(false)}>
            <DialogTitle>
                Are you sure you want to publish this revision?
            </DialogTitle>
            {/*<DialogContent>*/}
            {/*</DialogContent>*/}
            <DialogActions>
                <Button onClick={() => setIsPublishConfirmationOpen(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (categorizationJobId) aiJobDelegate.devopsGoLiveRequestManager.request(categorizationJobId);
                        setIsPublishConfirmationOpen(false);
                    }}>
                    Publish
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={isRejectConfirmationOpen} onClose={() => setIsRejectConfirmationOpen(false)}>
            <DialogTitle>
                Are you sure you want to reject this revision?
            </DialogTitle>
            {/*<DialogContent>*/}
            {/*</DialogContent>*/}
            <DialogActions>
                <Button onClick={() => setIsPublishConfirmationOpen(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (categorizationJobId) aiJobDelegate.devopsRejectRevisionManager.request(categorizationJobId);
                        setIsPublishConfirmationOpen(false);
                    }}>
                    Reject
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={isDestroyConfirmationOpen} onClose={() => setIsDestroyConfirmationOpen(false)}>
            <DialogTitle>
                Are you sure you want to destroy this job?
            </DialogTitle>
            {/*<DialogContent>*/}
            {/*</DialogContent>*/}
            <DialogActions>
                <Button onClick={() => setIsDestroyConfirmationOpen(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (categorizationJobId) aiJobDelegate.devopsDestroyJobManager.request(categorizationJobId);
                        setIsDestroyConfirmationOpen(false);
                    }}>
                    Destroy
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={isApplyCutoffConfirmationOpen} onClose={() => setIsApplyCutoffConfirmationOpen(false)}>
            <DialogTitle>
                Apply a cutoff value
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{marginBottom: '1em'}}>
                    Everything below this score will be set to {UNCATEGORIZED_LABEL}
                </Typography>

                <TextField
                    style={{marginBottom: '3em'}}
                    error={!cutoffValueValid}
                    label="Cutoff value"
                    helperText={!cutoffValueValid ? 'Value must be between 0 and 1' : ''}
                    value={cutoffValue}
                    onChange={(e) => setCutoffValue(e.target.value)}
                    onBlur={() => cutoffValueValid && setCutoffValue(String(cutoffValueFloat))}
                /><br/>

                <Button
                    variant="outlined"
                    className="btn-with-circular-progress"
                    startIcon={aiJobDelegate.devopsSimulateCutoffManager.busy
                        ? <CircularProgress size={14}/>
                        : <PlayArrow/>}
                    disabled={!cutoffValueValid || !revisionId || !isApplyCutoffConfirmationOpen}
                    onClick={() => {
                        if (cutoffValueValid && revisionId && isApplyCutoffConfirmationOpen) {
                            aiJobDelegate.devopsSimulateCutoffManager.request({
                                cutoff_value: cutoffValueFloat,
                                revision: revisionId
                            });
                        }
                    }}>
                    Simulate {UNCATEGORIZED_LABEL}
                </Button>
                {aiJobDelegate.devopsSimulateCutoffManager.error && <Alert color="error">
                    {'' + aiJobDelegate.devopsSimulateCutoffManager.error}
                </Alert>}

                <List style={{opacity: aiJobDelegate.devopsSimulateCutoffManager.busy ? '.25' : '1'}}>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={UNCATEGORIZED_LABEL + ` spend`}
                            secondary={aiJobDelegate.devopsSimulateCutoffManager.result?.spend &&
                                <CurrencyComponent v={aiJobDelegate.devopsSimulateCutoffManager.result?.spend}/>}
                        />
                        <ListItemText
                            primary={UNCATEGORIZED_LABEL + ` parts`}
                            secondary={aiJobDelegate.devopsSimulateCutoffManager.result?.parts}
                        />
                        <ListItemText
                            primary={`Suppliers with ${UNCATEGORIZED_LABEL} parts`}
                            secondary={aiJobDelegate.devopsSimulateCutoffManager.result?.suppliers}
                        />
                    </ListItem>
                </List>
                {!revisionId && <Alert color="error">
                    Please select a revision first
                </Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsApplyCutoffConfirmationOpen(false)}>
                    Cancel
                </Button>
                <Button
                    disabled={!categorizationJobId || !revisionId || !cutoffValueValid}
                    onClick={() => {
                        if (categorizationJobId && revisionId && cutoffValueValid) {
                            const data = {cutoff_value: cutoffValueFloat, revision: revisionId};
                            aiJobDelegate.devopsApplyCutoffManager.request([categorizationJobId, data]);
                        }
                        setIsApplyCutoffConfirmationOpen(false);
                    }}>
                    Apply Cutoff
                </Button>
            </DialogActions>
        </Dialog>

        <Grid item>
            <Button
                variant="text"
                startIcon={<>
                    {statusDelegate.statusRequestManager.busy ? <CircularProgress size={14}/> : null}
                    <AdminPanelSettings/>
                </>}
                onClick={() => {
                    statusDelegate.checkStatus();
                }}>
                Job Status: {job ? `${job?.current_status.status} (${job?.current_status.timestamp})` : 'No job'}
                {statusDelegate.statusRequestManager.error ? ' (Error)' : ''}
            </Button>

            <Button
                // color=""
                variant="outlined"
                className="btn-with-circular-progress"
                startIcon={aiJobDelegate.devopsDestroyJobManager.busy
                    ? <CircularProgress size={14}/>
                    : <DeleteForever/>}
                disabled={aiJobDelegate.devopsDestroyJobManager.busy || !categorizationJobId}
                onClick={() => setIsDestroyConfirmationOpen(true)}>
                Destroy
                {aiJobDelegate.devopsDestroyJobManager.error && ' (Error)'}
            </Button>
        </Grid>

        <Grid item>
            <FormControl style={{minWidth: '10em'}}>
                <InputLabel id="revision-selector-dropdown-label">Revision</InputLabel>
                <Select
                    label="Revision"
                    labelId="revision-selector-dropdown-label"
                    color={revisionController.revision === 'customer' ? 'warning' : undefined}
                    value={revisionController.revision || ''}
                    onChange={(e) => revisionController.setRevision(String(e.target.value))}>
                    <MenuItem value="">
                        Disable
                    </MenuItem>
                    <MenuItem value="customer" color="warning">
                        Customer version
                    </MenuItem>
                    {revisionController.revisions?.filter(r => r.databag === categorizationReviewStore.bagId).map(r =>
                        <MenuItem key={r.id} value={r.id}>
                            Revision {r.id} (created {dayjs(r.created).fromNow()})
                            {r.rejected && ' (rejected)'}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        <Grid item>
            {revisionController.revision === 'customer' &&
                <Alert color="warning">
                    You are modifying the customer's view
                </Alert>
            }
            {Number.isInteger(revisionController.revision) &&
                <Alert
                    color={categorizationReviewStore.statusDelegate.latestRevisionIsRejected ? 'error' : 'info'}
                    action={categorizationReviewStore.statusDelegate.latestRevisionIsRejected
                        ? null
                        : <>
                            {revisionId && <Button
                                variant="outlined"
                                startIcon={aiJobDelegate.devopsApplyCutoffManager.busy
                                    ? <CircularProgress size={14}/>
                                    : <Bathtub/>}
                                disabled={aiJobDelegate.devopsApplyCutoffManager.busy}
                                onClick={() => setIsApplyCutoffConfirmationOpen(true)}>
                                Cutoff
                                {aiJobDelegate.devopsApplyCutoffManager.error && ' (Error)'}
                            </Button>}
                            {categorizationReviewStore.statusDelegate.latestRevisionIsNotRejected && <Button
                                // color=""
                                variant="outlined"
                                className="btn-with-circular-progress"
                                // style={{marginLeft: '2em'}}
                                startIcon={aiJobDelegate.devopsRejectRevisionManager.busy
                                    ? <CircularProgress size={14}/>
                                    : <ThumbDown/>}
                                disabled={aiJobDelegate.devopsRejectRevisionManager.busy}
                                onClick={() => setIsRejectConfirmationOpen(true)}>
                                Reject
                                {aiJobDelegate.devopsRejectRevisionManager.error && ' (Error)'}
                            </Button>}
                            {revisionController.revision === categorizationReviewStore.statusDelegate.latestRevisionId &&
                                <Button
                                    // color=""
                                    variant="outlined"
                                    className="btn-with-circular-progress"
                                    // style={{marginLeft: '2em'}}
                                    startIcon={aiJobDelegate.devopsGoLiveRequestManager.busy
                                        ? <CircularProgress size={14}/>
                                        : <Publish/>}
                                    disabled={aiJobDelegate.devopsGoLiveRequestManager.busy}
                                    onClick={() => setIsPublishConfirmationOpen(true)}>
                                    Publish
                                    {aiJobDelegate.devopsGoLiveRequestManager.error && ' (Error)'}
                                </Button>
                            }
                        </>
                    }>
                    {categorizationReviewStore.statusDelegate.latestRevisionIsRejected
                        ? 'This revision is already rejected'
                        : 'You are modifying a revision'}
                </Alert>
            }
        </Grid>
    </Grid>
});