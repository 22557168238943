import Margin from "../../../utils/margin";
import {C} from "../../../configurations/active-configuration";

export type ViewMode = 'full' | 'fit';

export type TaxonomyEditorOptions = {
    hideRoot: boolean
    showNumberLevels: number
    width: number
    fitHeight: number
    margin: Margin
    nodeWidth: number
    nodeDistance: number
    selectMargin: number
    algPadding: number
    /**
     * The post padding does not affect the positioning of the nodes in the visualization
     */
    postPadding: number
    transitionDuration: number
}

export const DEFAULT_TAXONOMY_EDITOR_OPTIONS: TaxonomyEditorOptions = {
    hideRoot: true,
    showNumberLevels: 1,
    width: 800,
    fitHeight: C.profile.taxonomyBuilderHeight || 650,
    margin: {
        left: 10,
        right: 10,
        top: 50,
        bottom: 50,
    },
    nodeWidth: 80,
    nodeDistance: 295,
    selectMargin: 40,
    algPadding: 0,
    postPadding: 0,
    transitionDuration: 450,
}
