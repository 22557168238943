import {observer} from "mobx-react-lite";
import {CircularProgress, IconButton, TableRow} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {RemoveCircle} from "@mui/icons-material";
import {useStores} from "../../../stores";
import {PpvPartRowState} from "./classes/PpvPartRowState";
import {getPpvSubTableColumns} from "./PpvColumns";
import {PpvGroupRowState} from "./classes/PpvGroupRowState";
import React, {useState} from "react";


type Props = {
    part: PpvPartRowState
    group: PpvGroupRowState;
};
export const PpvTableSubRow: React.FC<Props> = observer(({part, group}) => {
    const {p, ppvControllerStore} = useStores();
    const COLUMNS = getPpvSubTableColumns(p);
    const isBaseline = part.data.variance_cost === 0;

    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
        setIsLoading(true);
        if (group.partStates?.length === 2) {
            ppvControllerStore.setPpvGroupToDelete(group, part)
            ppvControllerStore.setDeleteGroupModalOpen(true)
        } else {
            ppvControllerStore.ppvPageController.ppvPageDataController.deletePpvItem(group, part)
                .finally(() => {
                    setIsLoading(false)
                })
        }
    };

    let i = 0;
    return <TableRow className={isBaseline ? 'ppv-table-subrow-is-baseline' : ''}>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.p_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.p_description}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.n_parts}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.s_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.s_country}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.cc_description}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.data.combined_quantity}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.data.combined_spend} nDigits={3}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.data.combined_unit_cost} nDigits={3}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.data.variance_cost} nDigits={3}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {(part.data.ppv_delta_perc * 100).toFixed(0) + '%'}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.data.ppv_opportunity} nDigits={3}/>
        </MithraTableCell>

        {!p.p.isPpvReadOnly && <MithraTableCell c={COLUMNS[i++]}>
            <IconButton onClick={handleDelete}>
                {isLoading ? <CircularProgress size={20}/> : <RemoveCircle style={{color: '#FF3435'}}/>}
            </IconButton>
        </MithraTableCell>}
    </TableRow>
})
