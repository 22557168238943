import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import React from "react";
import {Button, CircularProgress, Grid, Typography,} from "@mui/material";
import {UploadFile} from "@mui/icons-material";
import {ReactComponent as NoData} from '../NoData.svg';
import {contractInsightsData} from "../data/ContractInsightsData";
import {environment} from "../../../env";

export const ContractUploadComponent: React.FC = observer(() => {
    const {contractInsightsStore, p} = useStores();
    const isDemo = environment.isDemo;

    let className = 'contract-upload-page'
    if (p.p.disableBuFilter) {
        className = `${className} button-only`
    }

    return <Grid container className={className} alignItems="center" justifyContent="center">
        <Grid item>
            <NoData/>
        </Grid>

        <Grid xs={12}></Grid>

        <Grid item style={{marginTop: '3em', marginBottom: '3em'}}>
            <Typography variant="h5" className="mb-2">
                It’s all empty here...
            </Typography>
            <Typography variant="body1" className="mb-2">
                To start, try to upload or drag and drop a contract file here
            </Typography>
        </Grid>

        <Grid xs={12}></Grid>

        <Grid item>
            <Button
                disabled={!isDemo || contractInsightsStore.datasetUploadIsLoading}
                startIcon={contractInsightsStore.datasetUploadIsLoading ? <CircularProgress size={20}/> : <UploadFile/>}
                variant="outlined"
                component="label"
                className="upload-file-button"
            >
                Upload contract file
                <input
                    hidden
                    accept="application/pdf"
                    type="file"
                    onChange={(e) => {
                        //fake call
                        contractInsightsStore.setDatasetUploadLoading(true);
                        setTimeout(() => {
                            contractInsightsStore.setDatasetUploadLoading(false);
                            contractInsightsStore.setContracts(contractInsightsData);
                            console.log("Fake API call completed.");
                        }, 4000);
                    }}
                />
            </Button>
        </Grid>

        <Grid xs={12}></Grid>

        <Grid item>
            <Typography variant="caption" className="mt-2">
                *.PDF file up to 50 mb
            </Typography>
        </Grid>
    </Grid>
});
