import "./TaxonomyMapping.scss"
import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid} from "@mui/material";
import {useHistory} from "react-router";
import {useStores} from "../../../stores";
import {routes} from "../../../routing/routes";


export const TaxonomyFinish: React.FC = observer(() => {
    const {taxonomyIngestionStore} = useStores();
    const history = useHistory();


    return <div className="finish-component">
        <Grid>
            <Grid xs={12} container justifyContent="center" alignItems="center">
                <h2>Finished creating a new dataset!</h2>
            </Grid>
            <Grid xs={12} container justifyContent="center" alignItems="center">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        taxonomyIngestionStore.resetIngestionStore();
                        history.push(routes.home);
                    }}>
                    To the dashboard
                </Button>
            </Grid>
        </Grid>
    </div>
})