import * as d3 from "d3";
import {EnterElement, PartitionLayout, ScaleOrdinal} from "d3";
import {D3GSelection, D3S, D3SvgSelection} from "../../../utils/global";
import {HierarchyNode} from "d3-hierarchy";
import {MAIN_COLOR} from "../../../style/colors";
import {wrapText2} from "../../../utils/d3-utils";
import {TaxonomyEditorOptions} from "./TaxonomyEditorOptions";
import {taxonomy_editor} from "./TaxonomyEditorTypes";
import {TaxonomyEditorController} from "./TaxonomyEditorController";
import {environment} from "../../../env";

type Node = taxonomy_editor.GraphNode;

export const MIN_LABEL_HEIGHT = environment.package === 'sales_demo' ? 16 : 14 // Wide: 14, Very narrow: 9

const MIN_RECT_HEIGHT = 2
const MIN_TEXT_MARGIN_RIGHT = 10
const MIN_TEXT_MARGIN_LEFT = 25

const CIRCLE_SIZE = MIN_LABEL_HEIGHT - 1
const CIRCLE_INNER_SIZE = CIRCLE_SIZE * .6

type Selection = d3.Selection<SVGGElement, Node, SVGGElement, any>;
type EnterSelection = d3.Selection<EnterElement, Node, SVGGElement, any>;

type Transition = d3.Transition<SVGGElement, Node, SVGGElement, any>;

const SELECTION_ARROW_MARGIN_RIGHT = 5
const SELECTION_ARROW_MARGIN_LEFT = 5
const SELECTION_ARROW_WIDTH = 4
const SELECTION_ARROW_HEIGHT = SELECTION_ARROW_WIDTH * 2
const SELECTION_ARROW_WRAPPER_WIDTH = SELECTION_ARROW_WIDTH + SELECTION_ARROW_MARGIN_LEFT + SELECTION_ARROW_MARGIN_RIGHT

function CHEVRON_POINTS(d: 'l' | 'r' | 'c') {
    // <polyline points={CHEVRON_POINTS} className="my-chevron" />
    const w = SELECTION_ARROW_WIDTH
    const h = SELECTION_ARROW_HEIGHT
    if (d === 'r') return `0 ${-h / 2} ${w} 0 0 ${h / 2}`
    if (d === 'l') return `${w} ${-h / 2} 0 0 ${w} ${h / 2}`
    else return `${w / 2} ${-h / 2} ${w / 2} 0 ${w / 2} ${h / 2}`
}

const CHEVRON_POINTS_R = CHEVRON_POINTS('r')

/**
 * For drawing and rendering the visualization only
 */
export class TaxonomyEditorBuilder {
    private readonly bgRect: D3S<SVGRectElement, any>
    private readonly overlayRect: D3S<SVGRectElement, any>
    private readonly drawRoot: D3GSelection<any>
    private readonly nodesRoot: D3GSelection<any>
    // private readonly levelLabelsRoot: D3GSelection<any>

    private readonly color: ScaleOrdinal<string, string>
    private readonly format: (n: number | undefined) => string

    private readonly graphWidth: number
    private graphHeight: number

    private partitionLayout: PartitionLayout<taxonomy_editor.NodeData>;

    constructor(
        svg: D3SvgSelection,
        private options: TaxonomyEditorOptions,
        private controller: TaxonomyEditorController,
    ) {
        console.log('Creating new TaxonomyEditorBuilder...')

        this.graphWidth = options.width - options.margin.left - options.margin.right
        const height = options.fitHeight;
        this.graphHeight = height - options.margin.top - options.margin.bottom

        // background
        this.bgRect = svg.append('rect')
            .classed('bg', true)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', options.width)
            .attr('height', height)
            .on('click', () => {
                console.log('Background clicked')
                this.controller.backgroundClicked()
            })

        // Create a drawing root node
        // const xOffset = 0;
        const xOffset = this.graphWidth / 2 - (this.options.nodeWidth + this.options.nodeDistance) / 2;
        this.drawRoot = svg.append('g')
            .attr('transform', `translate(${options.margin.left + xOffset}, ${options.margin.top})`)

        this.nodesRoot = this.drawRoot.append('g')
            .classed('nodes-group', true)


        // // Option1: Use a color pallet
        // let pallet: (t: number) => string;
        // let colors: string[];
        //
        // // pallet = d3.interpolateRainbow
        // // // pallet = categoryColorGradient
        // // colors = d3.quantize(pallet, (this.taxonomyEditorStore.root?.children?.length || 0) + 1);
        //
        // pallet = d3.interpolateGreys
        // colors = d3.quantize(pallet, (this.taxonomyEditorStore.root?.children?.length || 0) + 3).reverse();
        // colors.splice(0, 1)
        // colors.splice(colors.length - 2)
        //
        // this.color = d3.scaleOrdinal(colors)

        // Option2: USe a single color
        this.color = d3.scaleOrdinal([MAIN_COLOR])

        this.format = d3.format(',d') as any

        this.partitionLayout = d3.partition<taxonomy_editor.NodeData>()
            .round(false)
            .padding(this.options.algPadding)
            .size([
                this.graphHeight,
                // (this.options.padding + 2.5) * (root.height + 1.5),
                100,
            ])

        // overlay
        this.overlayRect = svg.append('rect')
            .classed('overlay', true)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', options.width)
            .attr('height', height)
    }

    public init(rootHierarchy: HierarchyNode<taxonomy_editor.NodeData>): Node {
        console.log('>>> init')
        return this.draw(rootHierarchy)
    }

    /**
     * Set the height of the graph
     * @param newGraphHeight
     * @returns The new requested height of the SVG element
     */
    public setGraphHeight(newGraphHeight: number): number {
        const newHeight = newGraphHeight + this.options.margin.top + this.options.margin.bottom;
        this.graphHeight = newGraphHeight
        this.bgRect.attr('height', newHeight)
        this.overlayRect.attr('height', newHeight)
        this.partitionLayout.size([this.graphHeight, 100])
        return this.graphHeight + this.options.margin.top + this.options.margin.bottom;
    }

    public draw(rootHierarchy: HierarchyNode<taxonomy_editor.NodeData>): Node {
        const root = this.applyLayout(rootHierarchy)
        this.drawView(root)
        return root
    }

    public drawNewHierarchy(
        newHierarchy: HierarchyNode<taxonomy_editor.NodeData>,
        focusId: number | undefined,
    ): { root: Node, focus: Node } {
        console.log('drawNewHierarchy')
        // Move all previously existing nodes to the new location, and draw the new nodes

        // Calculate the new (unfocused) locations
        const newRoot = this.applyLayout(newHierarchy)
        let newFocus: Node | undefined = newRoot.find(node => node.data.id === focusId)
        if (focusId !== undefined) {
            if (!newFocus) {
                // The new focus does not exist anymore
                // TODO-PARKED: When undo/redo the page and the current focus does not exist we focus on the root
                //  Maybe we should just go up one level
                //  >>> 1h
                // Skip to just draw root
            } else {
                this.updateTarget(newRoot, newFocus)
                this.animateTarget(newFocus)
                    .end()
                    .then(() => {
                        console.log('Animation END')
                        this.drawView(newRoot)
                    })
            }
        }

        this.drawSelection()

        if (!newFocus) {
            this.drawView(newRoot)
        }
        return {
            root: newRoot,
            focus: newFocus || newRoot,
        }
    }

    public reApplyLayout(rootHierarchy: HierarchyNode<taxonomy_editor.NodeData>): Node {
        rootHierarchy.each((n: any) => {
            delete n.x0
            delete n.x1
            delete n.y0
            delete n.y1
        })
        return this.applyLayout(rootHierarchy)
    }

    public applyLayout(rootHierarchy: HierarchyNode<taxonomy_editor.NodeData>): Node {
        const root: Node = this.partitionLayout(rootHierarchy)
        root.each(d => {
            // Optional: Add more fields after layout is applied

            // Invert XY
            [d.x0, d.x1, d.y0, d.y1] = [d.y0, d.y1, d.x0, d.x1]

            d.data.viz.nodeHeight = this.getNodeHeight(d)
            d.data.viz.labelOpacity = +this.labelVisible(d)
            d.data.viz.nodeOpacity = +this.nodeVisible(d)
            d.data.viz.labelVerticalPosition = this.labelPosition(d)
            d.data.viz.chevronVerticalPosition = this.labelPosition(d)

            d.x0 = (d.depth - 1) * (this.options.nodeWidth + this.options.nodeDistance)
            d.x1 = d.x0 + this.options.nodeWidth

            // d.x0 += (d.depth - 1) * (this.options.nodeWidth + this.options.nodeDistance)
            // d.x1 += (d.depth - 1) * (this.options.nodeWidth + this.options.nodeDistance)
        })
        return root
    }

    private get nodeSelection(): Selection {
        // Used to be: this.drawGroups
        return this.nodesRoot.selectAll<SVGGElement, Node>('g.node-group')
    }

    private drawView(root: Node) {
        console.log('>>> drawView on ' + root.data.id)
        let data: Node[] = root.descendants()

        // // Optionally add level data
        // const levelData = [...Array(root.height)].map((value, index) => index)

        if (this.options.hideRoot) {
            // Hide the first node (as it's the root)
            data = data.filter(d => d.depth !== 0)
        }

        this.nodeSelection
            .data(data, d => {
                return d.data.id;
            })
            .join(
                this._enter.bind(this),
                elem => elem, // No update
                exit => {
                    console.log('exit', exit.size())
                    exit.remove();
                },
            )
            .call(this._join.bind(this))
        console.log('<<< /drawView')
    }

    //this is called to create the graph
    private _enter(enter: EnterSelection): Selection {
        console.log('enter: ', enter.size())

        const drawGroups = enter.append('g')
            .attr('opacity', d => (d.data.target?.viz || d.data.viz).nodeOpacity)
            .classed('node-group', true)
            .classed('is-navigate', d => this.controller.isNavigateable(d))
            .on('click', (_, n) => this.controller.onClick(n))

        drawGroups.append('rect')
            .classed('node-selection-bg bg', true)
            .attr('x', -this.options.nodeDistance)
            .attr('y', 0)
            .attr('width', this.options.nodeDistance + this.options.nodeWidth)
        drawGroups.append('rect')
            .classed('bar', true)

        const labelGroup = drawGroups.append('g')
            .classed('label-group', true)

        // text
        const textX = -this.textMarginRight;
        let text = labelGroup.append('text')
            .classed('name', true)

            //If we add an 'fill-opacity' there it will hide all the labels in the lower levels and not update when it's time to show them
            //.attr('fill-opacity', d => (d.data.target?.viz || d.data.viz).labelOpacity)

            .attr('pointer-events', 'none')
            .attr('dominant-baseline', 'middle')
            .style('text-anchor', 'end')
            .attr('dy', '.1em') // Fine-tuning to center the text better vertically
            .attr('x', textX)
        text.append('tspan')
            .attr('font-size', MIN_LABEL_HEIGHT)

        const navGroup = drawGroups.append('g')
            .classed('nav-group', true)
            .attr('fill-opacity', d => (d.data.target?.viz || d.data.viz).labelOpacity)
            .attr('transform', `translate(${this.options.nodeWidth}, 0)`)
            .on('click', (_, n) => {
                this.controller.onClickNav(n);
            })
        navGroup.append('rect')
            .classed('nav-bg bg', true)
            .attr('width', SELECTION_ARROW_WRAPPER_WIDTH)
        const navCenter = navGroup.append('g')
            .classed('nav-center', true)
        navCenter
            .append('polyline')
            .classed('chevron', true)
            .attr('transform', `translate(${SELECTION_ARROW_MARGIN_LEFT}, 0)`)
            // TODO CAT-675: Taxonomy label behvior needs refactoring
            //.attr('fill-opacity', d => (d.data.target?.viz || d.data.viz).labelOpacity)
            .attr('points', CHEVRON_POINTS_R)
            // TODO CAT-675: Taxonomy label behvior needs refactoring
            .attr('opacity', d => {
                let _d: Node | null = d;
                if (_d.children) {
                    return (d.data.target?.viz || d.data.viz).labelOpacity
                }
                return 0
            })


        // Select button
        const isEditMode = this.controller.isEditMode
        labelGroup.append('circle')
            .classed('select outer', true)
            .attr('opacity', d => !isEditMode ? 0 : (d.data.target?.viz || d.data.viz).labelOpacity)
            .attr('cx', textX / 2)
            .attr('r', CIRCLE_SIZE / 2)
        labelGroup.append('circle')
            .classed('select inner', true)
            .attr('opacity', d => !isEditMode ? 0 : (d.data.target?.viz || d.data.viz).labelOpacity)
            .attr('cx', textX / 2)
            .attr('r', CIRCLE_INNER_SIZE / 2)

        if (!environment.production) {
            drawGroups.append('title')
                .text(d => {
                    return d.ancestors().map(d => d.data.showLabel).reverse().join('/')
                        + `\nid = ${d.data.id}`
                        + `\nvalue = ${this.format(d.value)}`
                })
        }

        return drawGroups
    }

    //this is called after enter and animate
    private _join(nodeSelection: Selection) {

        console.log('join: ', nodeSelection.size())

        // The drawGroup is located at the top left of the bar
        nodeSelection
            .attr('transform', d => this.getTransform(d))
            .classed('label-visible', d => this.labelVisible(d))
            .classed('chevron-visible', d => this.chevronVisible(d))

        nodeSelection.select('rect.bar')
            .attr('width', d => d.x1 - d.x0)
            .attr('y', this.options.postPadding)
            .attr('height', d => Math.max(MIN_RECT_HEIGHT, (d.data.target?.viz || d.data.viz).nodeHeight - this.options.postPadding))//adding bigger number here will increase the padding between the bars
            .attr('fill', d => {
                let _d: Node | null = d;
                if (_d.depth) {
                    while (_d && _d.depth > 1) {
                        _d = _d.parent;
                    }
                    if (_d)
                        return this.color(_d.data.showLabel);
                }
                return "#ccc";
            })
        nodeSelection.select('rect.node-selection-bg')
            .attr('height', d => (d.data.target?.viz || d.data.viz).nodeHeight)

        nodeSelection.select('g.label-group')
            .attr('transform', d => `translate(0,${(d.data.target?.viz || d.data.viz).labelVerticalPosition})`)
            .attr('opacity', d => {
                const n = d.data.target || d
                const bigEnough = Math.abs(n.y1 - n.y0) > MIN_LABEL_HEIGHT;
                let result = n.x1 <= this.graphWidth && n.x0 >= 0 && bigEnough
                return result ? 1 : 0
            })

        nodeSelection.select('.chevron')
            .attr('transform', d => `translate(${SELECTION_ARROW_MARGIN_LEFT},${(d.data.target?.viz || d.data.viz).chevronVerticalPosition})`)
            // .attr('opacity', d => (d.data.target?.viz || d.data.viz).labelOpacity)
            // .classed('chevron-visible', d => this.chevronVisible(d))
            .style('fill', 'none')
            .style('stroke-width', 3)
            .style('stroke', '#CCC')

        nodeSelection.select('tspan')
            .each(this.trimTextFn())

        // nodeSelection.select('circle.outer')
        //     .attr('fill-opacity', d => d.data.target?.labelOpacity || 1)
        // nodeSelection.select('circle.inner')
        //     .attr('fill-opacity', d => d.data.target?.labelOpacity || 1)
        // nodeSelection.select('text')
        //     .attr('fill-opacity', d => d.data.target?.labelOpacity || 1)

        // if (BRUTE_REDRAW) {
        //     // Ensure the newly added nodes are properly rendered
        //     this.updateTarget()
        //
        //     nodeSelection
        //         .attr('transform', d =>
        //             `translate(${(d.data.target?.y0 || 0) + this.options.nodeDistance / 2},${d.data.target?.x0 || 0})`
        //         )
        //         .attr('opacity', d => d.data.target ? d.data.target.nodeOpacity : 1)
        //
        //     nodeSelection.selectAll('rect')
        //         .call(s =>
        //             s.attr('height', (d: any) => Math.max(MIN_RECT_HEIGHT, d.data.target.nodeHeight - this.options.postPadding * 2))
        //         )
        //
        //     nodeSelection.select('g.label-group')
        //         .attr('transform', d => `translate(0,${d.data.target?.labelVerticalPosition || 0})`)
        //     const text = nodeSelection.select('text')
        //         .attr('fill-opacity', d => d.data.target?.labelOpacity || 0)
        //
        // }
    }

    public drawSelection() {
        // console.log('drawSelection with selection.id=', this.taxonomyEditorStore.selection.map(s => s.data.id))
        this.nodeSelection
            .classed('selected', d => this.controller.isSelected(d))
    }

    private getTransform(d: Node) {
        const target = d.data.target;
        let x, y;
        // console.log(`getTransform with target=${Boolean(target)}`)
        if (target) {
            x = target.x0
            y = target.y0
        } else {
            x = d.x0
            y = d.y0
        }
        x += this.options.nodeDistance / 2
        return `translate(${x},${y})`;
    }

    private labelVisible(node: Node): boolean {
        const n = node.data.target || node
        const bigEnough = Math.abs(n.y1 - n.y0) > MIN_LABEL_HEIGHT;
        return n.x1 <= this.graphWidth && n.x0 >= 0 && bigEnough
    }

    private chevronVisible(node: Node): boolean {
        const n = node.data.target || node
        const bigEnough = Math.abs(n.y1 - n.y0) > MIN_LABEL_HEIGHT;
        return n.x1 <= this.graphWidth && n.x0 >= 0 && bigEnough
    }

    private nodeVisible(node: Node) {
        const focusLevel = this.controller.focusLevel
        const dd = node.depth - focusLevel
        return dd <= this.options.showNumberLevels
    }

    private getNodeHeight(node: Node | taxonomy_editor.AnimationTarget) {
        return Math.max(MIN_RECT_HEIGHT, node.y1 - node.y0);
        // return Math.max(MIN_RECT_HEIGHT, node.y1 - node.y0 - Math.min(0, (node.y1 - node.y0) / 2));
    }

    private labelPosition(node: Node) {
        // console.log('Changing node', this.taxonomyEditorStore.focusLevel, node.depth, Boolean(node.data.target))
        const n = node.data.target || node
        if (this.controller.focusIsNested(node)) {
            // It's one of the parents (to the left)
            // console.log('Parent', node.data.target?.y0, node.data.target?.y1, 'w=' + this.graphHeight)

            if (node.data.target) {
                // Check if it falls in range of the focus
                const viewportStart = -this.options.margin.top
                const viewportEnd = this.graphHeight + this.options.margin.bottom
                const start = node.data.target.y0
                const end = node.data.target.y1

                // // Option1: Look at coordinates
                const outOfView = start >= viewportEnd || end <= viewportStart;
                if (outOfView) {
                    node.data.target.viz.overlap = ''
                    node.data.target.viz.labelOpacity = 0
                } else {
                    const startInView = start >= viewportStart;
                    const endInView = end <= viewportEnd;
                    // - totally view <=> startInView && endInView
                    // - completely overlapping the view <=> !startInView && !endInView

                    const apparentStart = Math.max(start, viewportStart)
                    const apparentEnd = Math.min(end, viewportEnd)
                    node.data.target.viz.labelOpacity = 1
                    let overlap, p;
                    // p = (apparentEnd - apparentStart) / 2
                    if (!startInView) {
                        const startOffset = apparentStart - start;
                        if (!endInView) {
                            // complete overlap
                            overlap = 'both'
                            p = startOffset + (apparentEnd - apparentStart) / 2
                        } else {
                            overlap = 'bottom'
                            p = startOffset + (apparentEnd - apparentStart) / 2;
                        }
                    } else {
                        // Start is in the view
                        if (!endInView) {
                            overlap = 'top'
                            p = (apparentEnd - apparentStart) / 2
                        } else {
                            // Completely in view
                            overlap = ''
                            p = (apparentEnd - apparentStart) / 2
                        }
                    }
                    node.data.target.viz.overlap = overlap

                    // console.log(node.data.id, node.data.dataLabel, startInView, endInView, p)
                    return p
                }
            }
        }
        return (n.y1 - n.y0) / 2
    }

    private get textMarginRight() {
        return this.controller.isEditMode ? this.options.selectMargin : MIN_TEXT_MARGIN_RIGHT
    }

    private trimTextFn() {
        const textSize = this.options.nodeDistance - this.textMarginRight - MIN_TEXT_MARGIN_LEFT;
        return wrapText2<Node>(textSize, n => {
            let label = String(n.data.showLabel)
            let postfix = ''
            // if (n.height > 0) {
            //     postfix = ` (${n.leaves().length})`
            // }
            return [label, postfix];
        }, ' ...')

        // return wrapText(textSize,
        //     // n => n.data.label,
        //     // n => n.id,
        //     n => `${n.value}: ${n.data.label}`,
        // )
    }

    setEditMode(isEditMode: boolean) {
        console.log('setEditMode: ', isEditMode)

        const onEnd = () => {
            console.log('Edit mode', isEditMode)
            this.drawRoot.classed('edit-mode', isEditMode)
            this.drawRoot.classed('view-mode', !isEditMode)
        }

        const t: any = this.drawRoot
            .transition()
            .duration(this.options.transitionDuration)

        const labelGroups = this.nodeSelection
            .select('g.label-group');
        const text = labelGroups
            .select('text')

        const textX = -this.textMarginRight;
        text.transition(t)
            // .attr('opacity', d => d.data.target?.labelOpacity || d.labelOpacity)
            .attr('x', textX)

        labelGroups.select('circle.outer')
            // .classed('has-target', d => Boolean(d.data.target))
            // .classed('has-labelOpacity', d => Boolean(d.data.target?.labelOpacity))
            // .classed('has-targetLabelOpacity', d => Boolean(d.labelOpacity))
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : (d.data.target?.viz || d.data.viz).labelOpacity)
            .attr('cx', textX / 2)
        labelGroups.select('circle.inner')
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : (d.data.target?.viz || d.data.viz).labelOpacity)
            .attr('cx', textX / 2)

        const trimTextFn = this.trimTextFn();
        if (isEditMode) {
            // Edit is turning on, so the values might shrink
            // Adjust text before animation
            text.select('tspan')
                .each(trimTextFn)
            t.on('end', () => {
                onEnd()
            })
        } else {
            // Adjust text after animation
            t.on('end', () => {
                onEnd()
                text.select('tspan')
                    .each(trimTextFn)
            })
        }
    }


    moveFocus(root: Node, newFocus: Node, prev?: Node) {
        console.log(`TaxonomyEditor: moving Focus from ${prev?.data.id} -> ${newFocus.data.id}`)

        const nodeSelection = this.nodeSelection
        nodeSelection
            .classed('is-focus', d => d === newFocus)

        this.updateTarget(root, newFocus)
        this.animateTarget(newFocus, prev)
    }

    private updateTarget(root: Node, focus: Node) {
        root.each(d => {
            let target: taxonomy_editor.AnimationTarget;
            if (focus) {
                const w = this.options.nodeDistance + this.options.nodeWidth
                // const w = 0
                target = {
                    x0: d.x0 - focus.x0 - w,
                    x1: d.x1 - focus.x0 - w,
                    y0: (d.y0 - focus.y0) / (focus.y1 - focus.y0) * this.graphHeight,
                    y1: (d.y1 - focus.y0) / (focus.y1 - focus.y0) * this.graphHeight,
                    viz: {...taxonomy_editor.NO_VIZ_DATA},
                }
            } else {
                target = {
                    x0: d.x0,
                    x1: d.x1,
                    y0: d.y0,
                    y1: d.y1,
                    viz: {...taxonomy_editor.NO_VIZ_DATA},
                }
            }
            // console.log('target', target)
            d.data.target = target;

            // Ensure d.data.target is set, before calculating the viz data!
            target.viz.labelOpacity = +this.labelVisible(d)
            // TODO-PARKED: Hide nodes in the deeper levels
            target.viz.nodeOpacity = +this.nodeVisible(d)
            target.viz.nodeHeight = this.getNodeHeight(target)
        }).each(d => {
            if (d.data.target) {
                d.data.target.viz.labelVerticalPosition = this.labelPosition(d)
                d.data.target.viz.chevronVerticalPosition = this.labelPosition(d)
            }
        });
    }

    private animateTarget(newFocus: Node, prev?: Node): Transition {
        const dLevel = Math.abs((prev?.depth || 0) - (newFocus?.depth || 0))
        const animationFactor = Math.max(1, 1 + Math.pow(.75, dLevel))
        // console.log('dLevel', dLevel, 'animationFactor', animationFactor)

        const nodeSelection = this.nodeSelection
        const t: any = nodeSelection
            // TODO: Nodes which are not part of the current focus are not hidden
            //  We want to make buttons to navigate to siblings and hide the current nodes
            //  >>> OK? 2h
            .classed('is-overlap-top', d => (d.data.target?.viz || d.data.viz).overlap === 'top')
            .classed('is-overlap-bottom', d => (d.data.target?.viz || d.data.viz).overlap === 'bottom')
            .classed('is-overlap-both', d => (d.data.target?.viz || d.data.viz).overlap === 'both')
            .classed('is-navigate', d => this.controller.isNavigateable(d))
            .transition()
            .duration(this.options.transitionDuration * animationFactor)
            .attr('transform', d => this.getTransform(d))
            .attr('opacity', d => (d.data.target?.viz || d.data.viz).nodeOpacity)

        nodeSelection.selectAll<SVGRectElement, Node>('rect')
            .call(s =>
                    s.transition(t)
                        .attr('height', d => Math.max(MIN_RECT_HEIGHT, (d.data.target?.viz || d.data.viz).nodeHeight - this.options.postPadding))
                // .attr('height', d => {
                //     if (d.data.target) {
                //         return Math.max(MIN_RECT_HEIGHT, d.data.target.viz.nodeHeight - this.options.postPadding * 2);
                //     }
                //     return 0
                // })
            )

        const labelGroups = nodeSelection
            .select('g.label-group');

        labelGroups
            .transition(t)
            .attr('transform', d => `translate(0,${(d.data.target?.viz || d.data.viz).labelVerticalPosition})`)
            .attr('opacity', d => (d.data.target?.viz || d.data.viz).labelOpacity || this.labelVisible(d) ? 1 : 0)


        nodeSelection.select('.chevron')
            .transition(t)
            .attr('transform', d => `translate(${SELECTION_ARROW_MARGIN_LEFT},${(d.data.target?.viz || d.data.viz).chevronVerticalPosition})`)

        const isEditMode = this.controller.isEditMode;
        labelGroups.select('circle.outer')
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : (d.data.target?.viz || d.data.viz).labelOpacity)
        // .attr('cx', textX / 2) FIXME: Cleanup code: (Is this line needed?)
        labelGroups.select('circle.inner')
            .transition(t)
            .attr('opacity', d => !isEditMode ? 0 : (d.data.target?.viz || d.data.viz).labelOpacity)

        nodeSelection.select('.chevron')
            .transition(t)
            //.attr('opacity', d => (d.data.target?.viz || d.data.viz).labelOpacity)
            .attr('opacity', d => {
                let _d: Node | null = d;
                if (_d.children) {
                    return (d.data.target?.viz || d.data.viz).labelOpacity
                }
                return 0
            })

        nodeSelection.select('.chevron')
            .attr('transform', d => `translate(${SELECTION_ARROW_MARGIN_LEFT},${(d.data.target?.viz || d.data.viz).chevronVerticalPosition})`)
            // .classed('chevron-visible', d => this.chevronVisible(d))
            .style('fill', 'none')
            .style('stroke-width', 3)
            .style('stroke', '#CCC')

        nodeSelection.select('text')
            .transition(t)
            .attr('opacity', d => (d.data.target?.viz || d.data.viz).labelOpacity)

        return t
    }

    reDraw(rootHierarchy: HierarchyNode<taxonomy_editor.NodeData>, focus: Node | undefined): Node {
        const root = this.reApplyLayout(rootHierarchy)
        if (focus) {
            this.updateTarget(root, focus)
        }
        this.drawView(root)
        return root
    }
}
