import React from "react";
import {observer} from "mobx-react-lite";
import {
    Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow,
} from "@mui/material";
import {useSitStores} from "../../../stores";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {TARGET_COLUMNS} from "./columns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {MithraTableCell} from "../../../components/table/MithraTableCell";

export const TargetTable: React.FC = observer(() => {
    const {targetStore} = useSitStores();

    return <TableContainer className={'mithra-table x-design target-table' +
        (targetStore.targetListRM.busy ? ' loading' : '')}>
        <Table>
            <MithraTableHeadColumns columns={TARGET_COLUMNS} enableLoading
                                    isLoading={targetStore.targetListRM.busy}/>
            {targetStore.targetListRM.result &&
                <TableBody>
                    {targetStore.targetListRM.result?.results.map((row, index) => {
                        return (
                            <TableRow>
                                <MithraTableCell c={TARGET_COLUMNS[0]}>{row.title}</MithraTableCell>
                                <MithraTableCell c={TARGET_COLUMNS[1]}>{row.target_year}</MithraTableCell>
                                <MithraTableCell c={TARGET_COLUMNS[2]}>{row.fiscal_year}</MithraTableCell>
                                <MithraTableCell c={TARGET_COLUMNS[3]}>{row.baseline_spend}</MithraTableCell>
                                <MithraTableCell c={TARGET_COLUMNS[4]}>{row.saving_amount}</MithraTableCell>
                                <MithraTableCell c={TARGET_COLUMNS[5]}>{row.avoidance_amount}</MithraTableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            }
            <TableFooter>
                <TableRow>
                    <TablePagination
                        colSpan={TARGET_COLUMNS.length}
                        count={targetStore.targetListRM.result?.count ?? 0}
                        rowsPerPage={parseInt(targetStore.targetListFilter.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                        page={parseInt(targetStore.targetListFilter.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                        onPageChange={(event, page: number) => {
                            targetStore.targetListFilter.setFilters([['page', (page + 1).toString()]]);
                        }}
                        onRowsPerPageChange={(event) => {
                            targetStore.targetListFilter.setFilters([['page_size', (event.target.value).toString()]], false);
                            targetStore.targetListFilter.setFilters([['page', '1']]);
                        }}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
});