import React from "react";
import {observer} from "mobx-react-lite";
import {Alert, Button, Grid, LinearProgress, Typography} from "@mui/material";
import {UPLOAD_MIME_TYPES, uploadController} from "../DataUploadController";
import {Cancel, UploadFile} from "@mui/icons-material";
import {useStores} from "../../../../stores";
import {Trash} from "lucide-react";
import {canUploadFile} from "../../../../services/MithraDataIngestionApi";


/**
 * Taken from:
 * - https://cloud.google.com/storage/docs/performing-resumable-uploads#json-api_2
 * - https://medium.com/@theyograjthakur/simplifying-large-file-uploads-with-react-and-node-js-a-step-by-step-guide-bd72967f57fe
 * - https://dev.to/akirakashihara/how-to-upload-files-to-google-cloud-storage-using-javascript-on-only-the-browser-11ei
 * - https://cloud.google.com/storage/docs/json_api/v1/objects/insert#http-request
 */
export const GcpBucketUploadComponent: React.FC = observer(() => {
    const {dataIngestionStore} = useStores();
    const handleFileChange = (event) => {
        if (!event.target.files || event.target.files.length !== 1) {
            dataIngestionStore.setErrorMsg('')
            uploadController.cancel();
            return;
        }
        const file: File = event.target.files[0];
        const err = canUploadFile(file.name, file.size);
        if (err) {
            dataIngestionStore.setErrorMsg(err)
        } else {
            dataIngestionStore.setErrorMsg('')
            if (dataIngestionStore.datasetName === '' // There was no name set yet
                || dataIngestionStore.datasetName === uploadController.fileName // It was the same as the old name
            ) {
                // Update the datasetName to the current file name
                dataIngestionStore.setDatasetName(file.name)
            }
            uploadController.handleFileUpload(file);
        }
    };

    return <Grid container alignItems="center" className="gcp-bucket-upload-component">
        <Grid item>
            <Button
                disabled={uploadController.isCancelling}
                startIcon={
                    uploadController.inProgress ? <Cancel/> : uploadController.isUploaded ? <Trash/> : <UploadFile/>
                }
                variant="outlined"
                component="label"
                className="upload-file-button"
                onClick={() => {
                    if (uploadController.errorMsg || uploadController.inProgress || uploadController.isUploaded) {
                        uploadController.cancel();
                    }
                }}
                title={uploadController.isUploaded ? 'Delete file and upload another file' : ''}
            >
                {uploadController.inProgress
                    ? <>Cancel</>
                    : uploadController.isUploaded
                        ? <>Remove</>
                        : <>
                            Upload
                            <input
                                hidden
                                accept={UPLOAD_MIME_TYPES}
                                type="file"
                                onChange={handleFileChange}
                            />
                        </>}
            </Button>
        </Grid>
        <Grid item style={{flex: 1, marginLeft: '1em'}}>
            {uploadController.errorMsg
                ? <Alert severity="error">{uploadController.errorMsg}</Alert>
                : <Typography variant="caption">
                    {uploadController.isCancelling ? 'Cancelling ...'
                        : uploadController.isRemoving ? 'Removing ...'
                            : uploadController.inProgress ?
                                <>Uploading <span className="filename">
                                    {uploadController.fileName}
                                </span> ...</>
                                : uploadController.isUploaded ?
                                    <>Upload of <span className="filename">
                                        {uploadController.fileName}
                                    </span> completed</>
                                    // else
                                    : 'Select a file to upload'
                    }
                </Typography>
            }
        </Grid>
        <Grid item xs={12} style={{marginTop: '1em'}}>
            <LinearProgress value={uploadController.progress} variant="determinate"/>
        </Grid>
    </Grid>
});
