import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {Target} from "../ApiTypes";
import {PageResponse} from "../../services/ApiTypes";
import {initialListFilters, StringArrayFilter} from "../../services/ApiHelpers";
import {makeAutoObservable} from "mobx";

export class TargetStore {
    readonly targetListRM = new AxoisRequestManager<void, PageResponse<Target>>(
        () => from(this.api.listTargets()));
    targetListFilter: StringArrayFilter = new StringArrayFilter(this.targetListRM, initialListFilters(10));

    constructor(private api: SitApi) {
        makeAutoObservable(this, {});
        if(this.targetListRM.result === undefined)
            this.targetListFilter.setFilters([[]]);
    }
}