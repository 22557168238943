import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import './SupplierNormalization.scss';
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {Button, Chip, Grid, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {SupplierNormalizationWelcomeStep} from "./pages/SupplierNormalizationWelcomeStep";
import {environment} from "../../env";
import {SupplierNormalizationApplyStep} from "./pages/SupplierNormalizationApplyStep";
import {SupplierNormalizationModelRunningStep} from "./pages/SupplierNormalizationModelRunningStep";
import {SupplierNormalizationApplyingResultsStep} from "./pages/SupplierNormalizationApplyingResultsStep";
import {ParentSuppliersStep} from "./pages/parent-suppliers/ParentSuppliersStep";
import {generateHomePath} from "../../routing/routing.utils";
import {JobRouteMatch} from '../../routing/routes';
import {useHistory} from "react-router";
import {SupplierNormalizationState} from "./pages/SupplierNormalization.classes";
import {ChevronRight} from "@mui/icons-material";

export const SupplierNormalizationPage: React.FC = observer(() => {
    const {
        p, rootAppStore, bagStore,
        supplierNormalizationStore,
        masterSupplierNormalizationStore,
    } = useStores();
    const bagId = bagStore.bagId;
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const activeState = supplierNormalizationStore.state;

    useEffect(() => {
        // Check route
        if (supplierNormalizationStore.isMaster) {
            if (!p.p.hardcodeMasterBagId) throw new Error('Hardcode master bag id is not set (ERROR FROM PAGE!!)');
            masterSupplierNormalizationStore.init(p.p.hardcodeMasterBagId)
        } else {
            supplierNormalizationStore.init(bagId, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let goBack = () => history.push(generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app));
    let backLabel = 'Home';
    if (activeState === SupplierNormalizationState.START || activeState === SupplierNormalizationState.RUNNING) {
        // Go Home
    } else if (activeState === SupplierNormalizationState.REVIEW) {
        goBack = () => supplierNormalizationStore.goToState(SupplierNormalizationState.START);
        backLabel = 'Back';
    } else if (activeState === SupplierNormalizationState.APPLYING || activeState === SupplierNormalizationState.DONE) {
        goBack = () => supplierNormalizationStore.goToState(SupplierNormalizationState.REVIEW);
        backLabel = 'Back';
    }
    let nextNode = <></>
    if (activeState === SupplierNormalizationState.REVIEW) {
        nextNode = <Button variant="outlined"
                           onClick={() => supplierNormalizationStore.goToState(SupplierNormalizationState.APPLYING)}
                           endIcon={<ChevronRight/>}>
            See results
        </Button>
    }

    return <KoiFacade
        title={(!p.p.masterCompanyName && !p.p.compareCompanyName) ? 'Supplier Normalization'
            : (supplierNormalizationStore.isMaster
                ? <Typography variant="h2" component="h1">
                    Supplier Normalization for {p.p.masterCompanyName + ' '}
                    {p.p.showResultPills && <Chip label="SAP results" size="medium"/>}
                </Typography>
                : <Typography variant="h2" component="h1">
                    Supplier Normalization for {p.p.compareCompanyName + ' '}
                    {p.p.showResultPills && <Chip color="primary" label="Mithra results" size="medium"/>}
                </Typography>)}
        onBack={goBack}
        backLabel={backLabel}
        backClass={""}
        next={{type: 'component', node: nextNode}}
        nextClass={"text-right"}
        className="sup-norm-page">
        <Grid container justifyContent="space-evenly" className="supplier-normalization-stepper">
            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={supplierNormalizationStore.stepNumber}>
                    <Step
                        onClick={() => environment.isTest && supplierNormalizationStore.goToState(SupplierNormalizationState.START)}>
                        <StepLabel>
                            {activeState === 'running' ? 'Running ...' : 'Start model'}
                        </StepLabel>
                    </Step>
                    <Step
                        onClick={() => environment.isTest && supplierNormalizationStore.goToState(SupplierNormalizationState.REVIEW)}>
                        <StepLabel>
                            Review
                        </StepLabel>
                    </Step>
                    <Step
                        onClick={() => environment.isTest && supplierNormalizationStore.goToState(SupplierNormalizationState.APPLYING)}>
                        <StepLabel>
                            Results
                        </StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>
        {activeState === SupplierNormalizationState.START && <SupplierNormalizationWelcomeStep/>}
        {activeState === SupplierNormalizationState.RUNNING && <SupplierNormalizationModelRunningStep/>}
        {activeState === SupplierNormalizationState.REVIEW && <ParentSuppliersStep/>}
        {activeState === SupplierNormalizationState.APPLYING && <SupplierNormalizationApplyingResultsStep/>}
        {activeState === SupplierNormalizationState.DONE && <SupplierNormalizationApplyStep/>}
    </KoiFacade>
});
