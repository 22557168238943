import './ApprovalPage.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {useStores} from "../../stores";
import {MithraTable, MithraTableHeader} from "../../components/data-table/MithraTable";
import {
    Button, Chip, Grid, IconButton, Input, InputAdornment, Tab, TableCell, TablePagination, TableRow, Tabs, Typography
} from "@mui/material";
import moment from "moment";
import {Clear, Refresh, Search} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {ApprovalRouteMatch, routes} from "../../routing/routes";
import {ApprovalStore} from "../../stores/ApprovalStore";
import {ApprovalStatusEnum} from "../../services/classes/AiClasses";

const HEADERS: MithraTableHeader[] = [
    {headerText: 'Username'},
    {headerText: 'Type of change', className: 'width-15'},
    {headerText: 'Time & Date of change', className: 'width-15'},
    {headerText: 'Status', className: 'width-10'},
    {headerText: '', className: 'width-10'},
]

export const ApprovalListPage: React.FC = observer(() => {
    const {approvalStore} = useStores();
    const history = useHistory();

    useEffect(() => {
        if (approvalStore.statusCount.result === undefined)
            approvalStore.statusCount.request();
        approvalStore.filteredList?.request({filters: approvalStore.listFilters.filters});
    }, [approvalStore.statusCount, approvalStore.filteredList, approvalStore.listFilters])

    return <KoiFacade
        title="Approval requests list"
        simpleAction={<IconButton
            size="small"
            aria-label="refresh"
            onClick={() => {
                approvalStore.statusCount.request();
                approvalStore.filteredList.request({filters: approvalStore.listFilters.filters});
            }}
            disabled={approvalStore.filteredList.busy}
            className="ml-2">
            <Refresh/>
        </IconButton>}
        back={routes.home}
        backClass={""}
        className="approval-page">
        <div className="approval-subtitle">
            <Tabs value={approvalStore.listFilters.filters.find(i => i[0] === 'status_q')?.at(1) ?? 'all'}
                  className="approval-tabs">
                <Tab value="all" label={'ALL (' + (approvalStore.getCountForStatus('all') ?? 0).toString() + ')'}
                     onClick={() => {
                         approvalStore.setListFilters([['status_q', '']]);
                     }}/>
                {[ApprovalStatusEnum.PENDING, ApprovalStatusEnum.BUSY,
                    ApprovalStatusEnum.APPROVED, ApprovalStatusEnum.REJECTED].map((status, i) =>
                    <Tab key={i} value={status}
                         label={ApprovalStore.getApprovalStatusName(status).toUpperCase() + ' (' +
                             (approvalStore.getCountForStatus(status) ?? 0).toString() + ')'}
                         onClick={() => {
                             approvalStore.setListFilters([['status_q', status.toString()]]);
                         }}/>
                )}
            </Tabs>
            <Input
                id="approval-list-search-field"
                type="text" placeholder="Search for change or user..."
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="clear search"
                            onClick={() => {
                                approvalStore.setListFilters([['search', ''],['page', '1']]);
                            }}>
                            <Clear/>
                        </IconButton>
                        <IconButton
                            aria-label="search"
                            onClick={() => {
                                approvalStore.filteredList.request({filters: approvalStore.listFilters.filters});
                            }}>
                            <Search/>
                        </IconButton>
                    </InputAdornment>
                }
                value={approvalStore.listFilters.filters.find(i => i[0] === 'search')?.at(1) ?? ''}
                onChange={e => approvalStore.setListFilters(
                        [['search', ApprovalStore.stripRequestTypeForSearch(e.target.value)],['page', '1']], false)}
                onKeyDown={e => e.key === 'Enter' ? approvalStore.filteredList.request(
                        {filters: approvalStore.listFilters.filters}) : undefined}
            />
        </div>
        <Grid container>
            <Grid item xs={12}>
                <MithraTable
                    headers={HEADERS} loading={approvalStore.filteredList.busy} className="mithra-table"
                    children={approvalStore.filteredList.result?.results.map(a => {
                        const created = moment(a.created_timestamp);
                        const linkParams: ApprovalRouteMatch = {approvalId: String(a.id)};
                        const link = generatePath(ApprovalStore.getBaseRoute(a.request_type), linkParams);
                        return <TableRow key={a.id}>
                            <TableCell>
                                <Typography>{a.created_by.first_name + ' ' + a.created_by.last_name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{ApprovalStore.showRequestType(a.request_type)}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{created.format('L LT')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Chip size="medium"
                                      label={ApprovalStore.getApprovalStatusName(a.current_status.status)}
                                      className={a.current_status.status_name.toLowerCase()}/>
                            </TableCell>
                            <TableCell>
                                <Button className="detail-button"
                                        href={link}
                                        onClick={e => {
                                            e.preventDefault();
                                            history.push(link);
                                        }}
                                        variant='outlined'>
                                    Details
                                </Button>
                            </TableCell>
                        </TableRow>;
                    })}
                    footer={
                        <TableRow>
                            <TablePagination
                                count={approvalStore.filteredList.result?.count ?? 0}
                                rowsPerPage={parseInt(approvalStore.listFilters.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                                page={parseInt(approvalStore.listFilters.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                                onPageChange={(event, page: number) => {
                                    approvalStore.setListFilters([['page', (page + 1).toString()]]);
                                }}
                                onRowsPerPageChange={(event) => {
                                    approvalStore.setListFilters([['page_size', (event.target.value).toString()]]);
                                    approvalStore.setListFilters([['page', '1']]);
                                }}
                            />
                        </TableRow>}/>
            </Grid>
        </Grid>
    </KoiFacade>
})
