import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {PageResponse} from "../../services/ApiTypes";
import {initialListFilters, StringArrayFilter} from "../../services/ApiHelpers";
import {makeAutoObservable} from "mobx";
import {Initiative} from "../ApiTypes";

export class InitiativeStore {
    instance: Initiative = new Initiative();
    readonly initiativeListRM = new AxoisRequestManager<void, PageResponse<Initiative>>(
        () => from(this.api.listInitiatives()));
    initiativeListFilter: StringArrayFilter = new StringArrayFilter(this.initiativeListRM, initialListFilters(10));
    readonly initiativeRM = new AxoisRequestManager<number, Initiative>(
        (id) => from(this.api.getInitiative(id)));

    constructor(private api: SitApi) {
        makeAutoObservable(this, {});
        if(this.initiativeListRM.result === undefined)
            this.initiativeListFilter.setFilters([[]]);
    }

    getInstance(id: number){
        if(id === 0) {
            this.instance = new Initiative();
            return;
        }
        this.api.getInitiative(id).then((resp) => {
            this.instance = resp.data
        });
    }
}