import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import {ScaleOrdinal} from "d3";
import './LineChart.scss';
import {AdvancedSpendPerGroup} from "../../../services/ApiTypes";
import {HashedLoadingDataWrapper} from "../../../utils/LoadingDataWrapper";
import Typography from "@mui/material/Typography";
import {observer} from "mobx-react-lite";

export const LineChart: React.FC<{
    title?: string,
    dataWrapper: HashedLoadingDataWrapper<any>,
    graphWidth?: number
    graphHeight?: number
}> = observer(({title, dataWrapper, graphWidth, graphHeight}) => {
    const margin = {top: 10, right: 10, bottom: 10, left: 10}
    const total_width: number = graphWidth || 600;
    const total_height: number = graphHeight || 400;
    const width: number = total_width - margin.left - margin.right;
    const height: number = total_height - margin.top - margin.bottom;
    let data = dataWrapper.elements;
    const datahash = dataWrapper.hash;

    const svgRef = useRef<SVGSVGElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!svgRef.current) {
            return;
        }
        if (!datahash) {
            return;
        }
        if (svgRef.current.dataset['datahash'] === datahash) {
            // Don't re-render if the input data is already rendered
            return;
        }
        svgRef.current.dataset['datahash'] = datahash;

        const svg = d3.select(svgRef.current);
        const tooltip = d3.select(tooltipRef.current);

        svg.html("");
        svg
            .attr('width', total_width)
            .attr('height', total_height)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const currency = d3.formatLocale({
            "decimal": ".",
            "thousands": ",",
            "grouping": [3],
            "currency": ["€", ""],
        })
        const format = currency.format("$,d");

        data = data.filter(item => item.spend != null && item.date != null && item.group != null);

        const dateExtentResult: [Date, Date] | [undefined, undefined] = d3.extent(data, (d) => d.date as Date);
        const dateExtremes: [Date, Date] = dateExtentResult.some((x) => x === undefined)
            ? [new Date(), new Date()] : dateExtentResult as [Date, Date];

        function nextMonth(date: Date): Date {
            if (date.getMonth() == 11) {
                date.setFullYear(date.getFullYear() + 1, 0, 1);
            } else {
                date.setMonth(date.getMonth() + 1, 1);
            }

            return date;
        }

        // Group
        const groupedData = d3.group(data, (d) => d.group);
        groupedData.forEach((group, key) => {
            for (let date = new Date(dateExtremes[0]); date <= dateExtremes[1]; date = nextMonth(date)) {
                if (group.find(item => item.date.toString() === date.toString()) === undefined) {
                    group.push({date: new Date(date), spend: 0, group: key} as AdvancedSpendPerGroup);
                }
            }
            group.sort((a, b) => d3.ascending(a.date, b.date));
        });

        // Add X axis
        const x = d3.scaleTime()
            .domain(dateExtremes)
            .range([0, width]);

        // Draw X axis
        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));

        const maxValueResult: number | undefined = d3.max(data, (d) => +d.spend) ?? 0;

        // Add Y axis
        const y = d3.scaleLinear()
            .domain([0, maxValueResult])
            .range([height, 0]);

        // Draw Y axis
        svg.append("g")
            .attr("class", "y-axis")
            .call(d3.axisLeft(y).ticks(5).tickFormat(v => d3.format("~s")(v)));

        // Create colour palette
        const colour: ScaleOrdinal<string, any> = d3.scaleOrdinal()
            .domain(groupedData.keys())
            .range(['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999'])

        tooltip
            .style("opacity", 0);

        function mouseover(this, event, d: AdvancedSpendPerGroup[]) {
            tooltip.style("opacity", 1);
            d3.select(this).attr('fill-opacity', 0.8);
        }

        function mousemove(this, event, d: AdvancedSpendPerGroup[]) {
            const sum = d.reduce((a: number, b: AdvancedSpendPerGroup) => a + b.spend, 0);
            const average_spend = (sum / d.length) || 0;

            tooltip.text(d[0].group + ": " + format(average_spend))
                .style("left", event.pageX + "px")
                .style("top", (event.pageY + 20) + "px");
        }

        function mouseleave(this, event, d: AdvancedSpendPerGroup[]) {
            tooltip.style("opacity", 0);
        }

        // Draw lines
        svg.selectAll(".line")
            .data(groupedData.values())
            .enter()
            .append("path")
            .attr("fill", "none")
            .attr("stroke", (d: AdvancedSpendPerGroup[]) => colour(d[0].group))
            .attr("stroke-width", 1.5)
            .attr("d", (d) => {
                return d3.line<AdvancedSpendPerGroup>()
                    .x((d) => x(new Date(d.date)))
                    .y((d) => y(isNaN(+d.spend) ? 0 : +d.spend))
                    (d)
            })
            .attr("style", "cursor: pointer;")
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
    }, [data, datahash]);

    // ORIGINAL
    return <div className="line-chart-container" style={{height: height + 'px'}}>
        {title && <Typography variant="subtitle1" className="line-chart-title">{title}</Typography>}
        <svg
            className="line-chart"
            ref={svgRef}
            viewBox={`0 0 ${total_width} ${total_height}`}/>
        <div className='tooltip' ref={tooltipRef} style={{height: '17px'}}/>
    </div>;

    // return (
    //     <div className="line-chart-container" style={{ height: height + 'px' }}>
    //         {loading ? (
    //             <Grid container justifyContent="center" alignItems="center" style={{ width: total_width + 'px', height: total_height + 'px' }}>
    //                 <CircularProgress color="inherit" />
    //             </Grid>
    //         ) : (
    //             <>
    //                 {title && <Typography variant="subtitle1" className="line-chart-title">{title}</Typography>}
    //                 <svg
    //                     className="line-chart"
    //                     ref={svgRef}
    //                     viewBox={`0 0 ${total_width} ${total_height}`}
    //                 />
    //                 <div className='tooltip' ref={tooltipRef} style={{ height: '17px' }} />
    //             </>
    //         )}
    //     </div>
    // );
});
