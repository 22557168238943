import React, {useEffect} from "react";
import {useStores} from "../../../stores";
import {Button, Grid, Typography} from "@mui/material";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import './KoiPurchasePriceVariance.scss';
import {PurchasePriceVarianceHelp} from "./PurchasePriceVarianceHelp";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from "../../../routing/routes";
import {observer} from "mobx-react-lite";
import {generateHomePath} from "../../../routing/routing.utils";
import {SingleBarChart} from "../../../components/visualization/single-barchart/SingleBarChart";
import {UNCATEGORIZED_VALUE} from "../../../constants";
import {NO_MARGIN} from "../../../utils/margin";
import {PpvExpandableTable} from "./PpvExpandableTable";
import {PpvFilterModal} from "./PpvFilterModal";
import {PpvBreadcrumbs} from "./PpvBreadcrumbs";
import {ShareModal} from "../../../pages/business-case-generator/components/modals/ShareModal";
import {SearchComponent} from "../../../components/SearchComponet";
import {FilterAlt} from "@mui/icons-material";
import {ShadcnQuarterDatePicker} from "../../../shadcn/components/ui/ShadcnQuarterDatePicker";
import {PpvEditNoteModal} from "./PpvEditNoteModal";
import {generatePath, useHistory} from "react-router";
import {runInAction} from "mobx";
import {PpvDeleteGroupModal} from "./PpvDeleteGroupModal";

export const KOIPurchasePriceVariance: React.FC = observer(() => {
    const {rootAppStore, bagStore, ppvControllerStore} = useStores();
    const bagId = bagStore.bagId;
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const [isShareOpen, setIsShareOpen] = React.useState(false);

    const taxonomySize = bagStore.taxonomy_size;
    useEffect(() => {
        ppvControllerStore.init(bagId, taxonomySize)
    }, [ppvControllerStore, bagId, taxonomySize])

    useEffect(() => {
        ppvControllerStore.ppvPageController.loadData(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isExtended, setIsExtended] = React.useState<boolean>(false);
    const dataMax = ppvControllerStore.ppvPageController.selectionCharts?.max || 1;

    // TODO: PR #98 Model View Controller mismatch + Underloaded MOBX Action
    const handleNoteUpdate = (updatedNote: string) => {
        runInAction(() => {
            if (ppvControllerStore.ppvRowToEdit && ppvControllerStore.ppvRowToEdit.data.opportunity) {
                ppvControllerStore.ppvRowToEdit.data.opportunity.note = updatedNote
            }
        })
    };

    return <KoiFacade
        title="Purchase Price Variance"
        helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
        //TODO: The share button is temporarily disable since we do not have a design for the BE
        // next={{
        //     type: 'component',
        //     node: <Button
        //         color="primary"
        //         variant="outlined"
        //         startIcon={<Share/>}
        //         onClick={() => setIsShareOpen(true)}>
        //         Share
        //     </Button>
        // }}
        next={{
            type: 'component',
            node: <Button
                color="primary"
                variant="outlined"
                onClick={() => history.push(generatePath(routes.koi_opportunities, {id: bagStore.bagId}))}>
                Go to opportunities
            </Button>
        }}
        className="koi-ppv-page"
    >
        <ShareModal isOpen={isShareOpen} onClose={() => setIsShareOpen(false)}/>

        <PpvFilterModal/>

        {/*FIXME: @Dennis, I do not like how I implemented it but it will work. I think you will catch this in the PR*/}
        <PpvEditNoteModal onNoteUpdate={handleNoteUpdate}/>

        <PpvDeleteGroupModal/>

        {/*{ppvControllerStore.ppvDataDoesNotExist &&*/}
        {/*    <Grid container justifyContent="space-evenly">*/}
        {/*        <Grid item style={{margin: '4em'}}>*/}
        {/*            <Alert severity="warning">*/}
        {/*                This dataset is missing data to calculate the Purchase Price Variance.*/}
        {/*            </Alert>*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*}*/}

        <Grid container justifyContent="center" style={{margin: '3em 0 3em 0'}}>
            <Grid container justifyContent="center"
                  className="key-value-container narrow-margin text-center font-bigger">
                <Grid container item xs={10} spacing={3} justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={3} alignItems="center">
                        <Typography variant="caption">Total Suppliers</Typography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                        <Typography variant="caption">Purchase Price Variance</Typography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                        <Typography variant="caption">Total spend</Typography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                        <Typography variant="caption">In Review
                            Opportunities</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={10} spacing={3} justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {ppvControllerStore.totalSuppliers ? `${ppvControllerStore.totalSuppliers}` : <>&nbsp;</>}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {ppvControllerStore.ppvPageController.ppvPageStatisticsController.spendTotalPPV ? (
                                <CurrencyComponent
                                    v={ppvControllerStore.ppvPageController.ppvPageStatisticsController.spendTotalPPV}
                                    nDigits={3}/>
                            ) : (
                                <>&nbsp;</>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {ppvControllerStore.totalSpend ?
                                <CurrencyComponent v={ppvControllerStore.totalSpend} nDigits={3}/> : <>&nbsp;</>}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {ppvControllerStore.totalExpectedSavings ?
                                <CurrencyComponent v={ppvControllerStore.totalExpectedSavings}
                                                   nDigits={3}/> : <>&nbsp;</>}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


        <div className="page-item">
            <Grid container justifyContent="space-evenly">
                <Grid item xs={4}/>
            </Grid>

            <Grid container justifyContent="space-evenly">
                {
                    <Grid container justifyContent="center">
                        <Grid item xs={4}/>
                        <Grid item xs={8}>
                            <PpvBreadcrumbs/>
                        </Grid>
                        <Grid item xs={3}/>
                        <Grid item xs={9} className={'overview-bar-charts'}>
                            {/* The bars of the bar chart */}
                            {ppvControllerStore.ppvPageController.selectionCharts && ppvControllerStore.ppvPageController.selectionCharts.data.slice(0, isExtended ? undefined : 3).map(changeChartData => (
                                <div key={changeChartData.category}
                                     className={`clickable bar-chart-container l${ppvControllerStore.ppvPageController.ppvPageFilterController.selectedLevel + 1}`}
                                     onClick={() => ppvControllerStore.ppvPageController.ppvPageFilterController.selectNextCategoryDown(changeChartData.category)}>
                                    <SingleBarChart
                                        key={changeChartData.category}
                                        data={{
                                            mainLabel: changeChartData.label,
                                            values: changeChartData.values,
                                        }}
                                        className={changeChartData.category === UNCATEGORIZED_VALUE ? 'uncat' : ''}
                                        options={{
                                            width: 800,
                                            height: 45,
                                            margin: NO_MARGIN,
                                            dataMax,
                                            labelHover: true
                                        }}
                                    />
                                </div>
                            ))}
                        </Grid>


                        <Grid xs={12} item className={'text-center'}
                              style={{margin: '1em', textDecoration: 'underline'}}
                              alignItems={'center'}>
                            <Typography variant="h6" className={'clickable'} onClick={() => setIsExtended(!isExtended)}>
                                {(ppvControllerStore.ppvPageController.selectionCharts && ppvControllerStore.ppvPageController.selectionCharts.data.length > 3) ? (isExtended ? 'Show less' : 'Show more') : null}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </div>


        <Grid container alignItems="center">
            <Grid item style={{flexBasis: '16em'}}>
                <ShadcnQuarterDatePicker
                    onUpdate={(values) => {
                        const startDate = values.range.from?.format(' YYYY-MM-DD') || '';
                        const endDate = values.range.to?.format(' YYYY-MM-DD') || '';
                        ppvControllerStore.ppvPageController.ppvPageFilterController.dateFilterChanged(startDate, endDate)
                    }}
                    dateFrom={ppvControllerStore.ppvPageController.ppvPageFilterController.startDate}
                    dateTo={ppvControllerStore.ppvPageController.ppvPageFilterController.startDate}
                />
            </Grid>
            <Grid item md={5} lg={3}>
                <SearchComponent
                    className="ppv-search-component"
                    searchFieldLabel="Search by description"
                    sm={ppvControllerStore.ppvPageController.ppvPageFilterController.ppvSearchManager}
                    componentId="ppv-search"
                    fullWidth
                />
            </Grid>
            <Grid item style={{marginLeft: 'auto'}}>
                <Button
                    endIcon={<FilterAlt/>}
                    variant='outlined'
                    onClick={() => {
                        ppvControllerStore.ppvPageController.ppvPageFilterController.setFilterDialogOpen(true)
                    }}>
                    Filter
                </Button>
            </Grid>
        </Grid>

        <section className="page-item ppv-table-section">
            <Grid container justifyContent="space-evenly">
                <Grid item xs={12} style={{minHeight: '10em'}}>
                    <PpvExpandableTable/>
                </Grid>
            </Grid>
        </section>
    </KoiFacade>
});
