/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {reaction} from "mobx";
import {SingleDatabagWrapped} from "./base/SingleDatabagWrapped";

/**
 * Wrapper for the mergeX product offering
 */
export const MergeXEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {
        rootAppStore,
        bagStore,
        bagLoadingStore,
        taxonomyManagerStore,
        supplierNormalizationStore,
        masterSupplierNormalizationStore,
        taxonomySuggestorStore,
        materializedApi,
        p,
        categorizationReviewStore
    } = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const isNoData = state.dataType === 'no_data';

    let revision = (match.params as any).revisionHackParam;
    console.warn('Categorization revision hack is being applied;', revision);
    if (revision === undefined) {
        materializedApi.revisionHack = undefined;
    } else {
        materializedApi.revisionHack = String(revision);
    }

    // TODO: See if this can be optimized, probably by the usage of react Context
    // Beware: This will cause the page to be rendered with the current state, this statement is called on every route change
    console.debug(`Setting app state to dataType=${state.dataType}`)
    rootAppStore.setApp(state)

    // The magic that loads the data
    useEffect(() => {
        const bagId = bagStore.bag?.id;
        taxonomySuggestorStore.setSelectedNodeLabel(undefined)
        taxonomySuggestorStore.setSelectedNodeValues(undefined)
        if (!p.p.hardcodeMasterBagId) throw new Error('No hardcoded hardcodeMasterBagId provided');
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId provided');
        if (p.p.hardcodeMasterBagId && bagId && bagId === p.p.hardcodeMasterBagId) supplierNormalizationStore.isMaster = true;
        masterSupplierNormalizationStore.init(p.p.hardcodedCombinedBagId) // Only used for the card titles

        // [CAT-1580] These auto-resets are causing unnecessary requests to the server
        // On page load they are always with databag=-1, which is not a valid bagId
        categorizationReviewStore.reviewPageController.reviewPageFilterController.supplierSearchManager.search.clearSearchBox();
        categorizationReviewStore.reviewPageController.reviewPageFilterController.partSearchManager.search.clearSearchBox();
        categorizationReviewStore.reviewPageController.loadData(true)

        // noinspection JSUnusedLocalSymbols
        const disposer = reaction(
            () => [bagStore.bagId, rootAppStore.app.dashboardEnvironment, bagStore.bag?.taxonomy_size] as const,
            ([bagId, dashboardEnvironment, taxonomySize]) => {
                /**
                 * @deprecated TODO: architecture for pre-loading of data is required, this is too hacky
                 * @see https://mobx.js.org/reactions.html#use-reactions-sparingly
                 */
                if (p.p.hackNSuppliersFromCommonSuppliers && bagId && taxonomySize)
                    supplierNormalizationStore.init(bagId, false)
            })

        if (!isNoData) {
            bagLoadingStore.initBag(location, history, match)

            // Overwrite the taxonomy for the categorization store
            // Each Taxonomy Editor uses loads in its own taxonomy
            taxonomyManagerStore.setDesiredTaxonomyId(p.p.masterTaxonomyId);

            // bagLoadingStore.loadBagWithoutLoadingStores(p.p.hardcodedSubsidiaryBagId).then(bag => {
            //     console.log(`Wrapper: Bag ${p.p.hardcodedBagId} loaded`);
            //     cmpStore.setCompareBag(bag);
            // });

            let taxonomySuggestorId: number | undefined = undefined;
            if (p.p.hardcodedTaxonomySuggestionId) {
                taxonomySuggestorId = Number(p.p.hardcodedTaxonomySuggestionId)
            }
            if ('taxonomySuggestorId' in match?.params) {
                taxonomySuggestorId = Number(match?.params['taxonomySuggestorId'])
            }
            if (taxonomySuggestorId) {
                taxonomySuggestorStore.getTaxonomySuggestion(taxonomySuggestorId)
                // The suggestion is still hardcoded, define the design to understand where and how it should be retrieved (soruce: CAT-873)
                taxonomySuggestorStore.getTaxonomySuggestion(Number(p.p.hardcodedTaxonomySuggestionId))
                // taxonomyHealthCheckStore.getTaxonomyHealthCheck(taxonomySuggestorId)
                // taxonomyHealthCheckStore.getTaxonomyHealthCheck(Number(p.p.hardcodedTaxonomySuggestionId))
            }
        }
        return () => disposer();
    }, [])

    // const desiredMasterBagStr = cmpStore.desiredMasterBagId ? `(${cmpStore.desiredMasterBagId}) ` : '';
    // const masterError = `Could not find Master data ${desiredMasterBagStr}on the server anymore`;

    // console.debug('Re-rendering MultiEnvWrapper', state.dashEnv)
    return <SingleDatabagWrapped state={state}>{children}</SingleDatabagWrapped>;
});
