import {
    ApprovalPart,
    ApprovalRequest,
    ApprovalStatusCount,
    ApprovalStatusEnum,
    ApprovalTypeEnum,
    CategorizationApprovalRequest,
    TaxonomyApprovalRequest
} from "../services/classes/AiClasses";
import {BagStore} from "./BagStore";
import {match as Match} from "react-router";
import {History} from "history";
import {makeAutoObservable, runInAction, toJS} from "mobx";
import {ApprovalRouteMatch, MithraHistoryState, routes} from "../routing/routes";
import {forkJoin, from, mergeMap, Observable, tap} from "rxjs";
import {AxiosResponse} from "axios";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {PageResponseManager} from "./managers/PageResponseManager";
import {MatPartReviewRow,} from "../services/classes/MaterializedClasses";
import {CategorizationApprovalDelegate} from "./approval/CategorizationApprovalDelegate";
import {AxoisRequestManager, GenericRequestManager} from "./managers/RequestManager";
import ProfileStore from "./ProfileStore";
import AuthStore from "./AuthStore";
import {PageResponse} from "../services/ApiTypes";
import {StringArrayFilter} from "../services/ApiHelpers";
import {environment} from "../env";

type AnyApprovalRequest = TaxonomyApprovalRequest | CategorizationApprovalRequest;

export type CategorizationApprovalPartPageManager = PageResponseManager<{ approvalId: number }, MatPartReviewRow>;

/**
 * Generic domain store for approvals
 */
export class ApprovalStore {
    public readonly categorizationApproval = new CategorizationApprovalDelegate(this, this.auth, this.bagStore, this.profile, this.api);
    readonly approvalLoader = new GenericRequestManager<unknown>();
    readonly statusCount = new AxoisRequestManager<void, ApprovalStatusCount[]>(
        () => from(this.api.approvalStatusCount()));
    readonly filteredList = new AxoisRequestManager<{ filters: string[][] }, PageResponse<ApprovalRequest>>(
        ({filters}) => from(this.api.approvalList(filters)));
    readonly approvalItem = new AxoisRequestManager<{ id: number }, ApprovalRequest>(
        ({id}) => from(this.api.approvalItem(id)));
    readonly categorizationPartList = new AxoisRequestManager<{
        id: number,
        filters: string[][]
    }, PageResponse<ApprovalPart>>(
        ({id, filters}) => from(this.api.approvalCategorizationPartList(id, filters)));
    readonly categorizationApply = new AxoisRequestManager<{ approvalId: number, status: ApprovalStatusEnum }, never>(
        ({approvalId, status}) => {
            // These two cleanups are precautions to ensure that if a request takes longer than
            //  expected, wrong results won't be displayed to user
            this.statusCount.cleanup();
            this.filteredList.cleanup();
            return from(this.api.applyCategorizationApproval(approvalId, status)).pipe(
                tap((response) => {
                    if (response.status === 200) {
                        this.statusCount.request();
                        this.filteredList.request({filters: this.listFilters.filters});
                    }
                }));
        });

    allRequestsLoading = false;
    allRequests?: ApprovalRequest[];
    approval?: AnyApprovalRequest;
    isAcceptApprovalDialogOpen = false;
    listFilters: StringArrayFilter = new StringArrayFilter(this.filteredList);
    categorizationPartListFilters: StringArrayFilter = new StringArrayFilter(this.categorizationPartList)

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private auth: AuthStore,
        private bagStore: BagStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this);
    }

    setListFilters(filters: string[][], reload = true) {
        this.listFilters.setFilters(filters, reload);
    }

    setCategorizationPartListFilters(filters: string[][]) {
        const approvalId = this.approvalItem.result?.id;
        if (approvalId === undefined) return;
        this.categorizationPartListFilters.setFilters(filters);
    }

    fetchAll() {
        this.allRequestsLoading = true;
        return this.api.listApprovalRequests().then(r => {
            runInAction(() => {
                this.allRequests = r.data;
                this.allRequestsLoading = false;
            });
            return r;
        })
    }

    get pendingRequests(): number | undefined {
        if (this.allRequests === undefined) return undefined
        return this.allRequests.filter(r => ApprovalStatusEnum.userCanUpdate(r.current_status.status)).length
    }

    // noinspection JSUnusedLocalSymbols
    onLoadApprovalPage(type: ApprovalTypeEnum, match: Match<ApprovalRouteMatch>, history: History<MithraHistoryState>) {
        // Attempt to interpret approval from URL
        if (match.params.approvalId === undefined) {
            console.warn('Cannot load approval URL mismatch')
            return;
        }
        const approvalId = Number(match.params.approvalId);
        if (!Number.isInteger(approvalId)) {
            console.warn('Cannot load approval URL mismatch', approvalId)
            return;
        }
        if (this.approval && this.approval.id !== approvalId) {
            this.setApproval(undefined)
        }

        this.loadApproval(approvalId, type);
    }

    loadApproval(approvalId: number, type: ApprovalTypeEnum) {
        switch (type) {
            case ApprovalTypeEnum.TAXONOMY:
                // TODO: Make to a TaxonomyApproval delegate
                this.approvalLoader.request(
                    from(this.api.getTaxonomyApprovalRequest(approvalId)).pipe(
                        tap(r => this.setApproval(r.data))
                    )
                )
                return;
            case ApprovalTypeEnum.CATEGORIZATION:
                this.approvalLoader.request(this.categorizationApproval.makeRetrieveApprovalRequest(approvalId))
                return;
        }
        throw new Error('Unknown approval type: ' + type);
    }

    setApproval(approval: AnyApprovalRequest | undefined) {
        this.approval = approval;
    }

    get taxonomyApproval(): TaxonomyApprovalRequest | undefined {
        if (this.approval && this.approval.request_type === ApprovalTypeEnum.TAXONOMY) {
            return this.approval
        }
        return undefined;
    }

    get categorizationApprovalRequest(): CategorizationApprovalRequest | undefined {
        if (this.approval && this.approval.request_type === ApprovalTypeEnum.CATEGORIZATION) {
            return this.approval
        }
        return undefined;
    }

    public static getBaseRoute(t: ApprovalTypeEnum): string {
        switch (t) {
            case ApprovalTypeEnum.TAXONOMY:
                return routes.approval_tax_detail
            case ApprovalTypeEnum.CATEGORIZATION:
                return routes.approval_cat_detail
        }
        throw new Error('Cannot create link for approval ' + t)
    }

    get request_type(): ApprovalTypeEnum | undefined {
        return this.approval?.request_type;
    }

    public static showRequestType(t: ApprovalTypeEnum): string {
        switch (t) {
            case ApprovalTypeEnum.TAXONOMY:
                return 'Taxonomy Improvement'
            case ApprovalTypeEnum.CATEGORIZATION:
                return 'Improve Categorization'
            default:
                console.error('Not implemented')
                return String(t)
        }
    }

    public static stripRequestTypeForSearch(search: string): string {
        if (search.toLowerCase() === 'taxonomy improvement') return 'taxonomy';
        if (search.toLowerCase() === 'improve categorization') return 'categorization';
        return search;
    }

    applyApproval(history: History<MithraHistoryState>) {
        // TODO-Architecture: apply correct delegation pattern
        if (!this.approval) return

        // Note: The approval request is done in the same queue as the loading of approvals
        const approvalId = this.approval.id;
        const requestType: ApprovalTypeEnum = this.approval.request_type;

        if (requestType === ApprovalTypeEnum.CATEGORIZATION) {
            this.categorizationApproval.applyApproval(history);
            return;
        }

        let oUpdate, oRetrieve: Observable<AxiosResponse<AnyApprovalRequest>>;
        switch (requestType) {
            case ApprovalTypeEnum.TAXONOMY:
                oUpdate = from(this.api.applyTaxonomyApproval(approvalId, ApprovalStatusEnum.APPROVED, this.approval.feedback_notes))
                oRetrieve = from(this.api.getTaxonomyApprovalRequest(approvalId))
                break;
            default:
                throw new Error();
        }

        const o = oUpdate.pipe(
            mergeMap(() => forkJoin([
                this.fetchAll(),
                oRetrieve.pipe(
                    tap(r => this.setApproval(r.data))
                ),
            ])),
            tap(([_, r]) => {
                this.isAcceptApprovalDialogOpen = false;
                if (!ApprovalStatusEnum.userCanUpdate(r.data.current_status.status)) {
                    // If we cannot change the approval anymore, go back to the overview page
                    history.push(routes.approval);
                }
            }),
        )
        this.approvalLoader.request(o)
    }

    clickCancelApprovalDialog() {
        if (!this.approvalLoader.busy) {
            this.isAcceptApprovalDialogOpen = false;
            this.approvalLoader.cleanup()
            if (this.approval) {
                this.api.storeApprovalNotes(this.approval.id, this.approval.feedback_notes).then()
            }
        }
    }

    clickAwayApprovalDialog() {
        this.clickCancelApprovalDialog()
    }

    openSendApprovalDialog() {
        this.isAcceptApprovalDialogOpen = true;
        switch (this.request_type) {
            case ApprovalTypeEnum.CATEGORIZATION:
                this.categorizationApproval.loadStats()
                break
        }
    }

    static getApprovalStatusName(status?: ApprovalStatusEnum): string {
        if (!status) return '';
        switch (status) {
            case ApprovalStatusEnum.PENDING:
                return 'Pending';
            case ApprovalStatusEnum.APPROVED:
                return 'Approved';
            case ApprovalStatusEnum.REJECTED:
                return 'Rejected';
            case ApprovalStatusEnum.BUSY:
                return 'Processing';
            case ApprovalStatusEnum.HALTED:
                if (environment.package === 'sales_demo') {
                    // btnIcon = <Check/>;
                    return 'Approved';
                }
                return 'Waiting for Mithra';
            case ApprovalStatusEnum.ERROR:
                return 'Error';
        }
    }

    getCountForStatus(status: ApprovalStatusEnum | 'all'): number | undefined {
        if (this.statusCount.busy || this.statusCount.result === undefined) return undefined;
        return this.statusCount.result?.find(i => {
            return i.status === status.toString().toLowerCase();
        })?.count ?? 0;
    }
}
