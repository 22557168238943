import {Divider, Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";
import React from "react";
import {useStores} from "../../../stores";
import {Analytics, Dashboard, Gavel, Info, Inventory, LocalShipping, RequestPage} from "@mui/icons-material";
import {ModalCardBlockType, ModalCardProps} from "../classes";

type Props = {
    isOpen: boolean
}

export const ModalContractDetails: React.FC<Props> = observer(({isOpen}) => {
    const {contractInsightsStore} = useStores();

    //
    // Since the implementation of Franke the demo does not work anymore for this view
    //

    const modalContractsDetailsData = contractInsightsStore.modalContractsDetailsData;
    const contractInfoCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Filename',
            bulletPoints: modalContractsDetailsData?.file_name ? [modalContractsDetailsData.file_name] : []
        },
        {
            blockTitle: 'Contract ID',
            bulletPoints: modalContractsDetailsData?.contract_id ? [modalContractsDetailsData.contract_id] : []
        },
        {
            blockTitle: 'Contract Title',
            bulletPoints: modalContractsDetailsData?.contract_title ? [modalContractsDetailsData.contract_title] : []
        },
        {
            blockTitle: 'Type',
            bulletPoints: modalContractsDetailsData?.contract_type ? [modalContractsDetailsData.contract_type] : []
        },
        {
            blockTitle: 'Parent Supplier',
            bulletPoints: modalContractsDetailsData?.parent_supplier ? [modalContractsDetailsData.parent_supplier] : []
        },
        {
            blockTitle: 'Supplier',
            bulletPoints: modalContractsDetailsData?.involved_supplier ? [modalContractsDetailsData.involved_supplier] : []
        },
        {
            blockTitle: 'Entity',
            bulletPoints: modalContractsDetailsData?.involved_company ? [modalContractsDetailsData.involved_company] : []
        },
        {
            blockTitle: 'Sister companies',
            bulletPoints: modalContractsDetailsData?.children_supplier ? [modalContractsDetailsData.children_supplier] : []
        },
        {
            blockTitle: 'Effective Date',
            bulletPoints: modalContractsDetailsData?.effective_date ? [modalContractsDetailsData.effective_date] : []
        },
        {
            blockTitle: 'Experiation Date',
            bulletPoints: modalContractsDetailsData?.expiration_date ? [modalContractsDetailsData.expiration_date] : []
        },
        {
            blockTitle: 'Scope',
            bulletPoints: modalContractsDetailsData?.scope ? [modalContractsDetailsData.scope] : []
        },
        {
            blockTitle: 'Evergreen Contract',
            bulletPoints: modalContractsDetailsData?.evergreen_contract ? [modalContractsDetailsData.evergreen_contract] : []
        },
    ];
    const contractFinancialTermsCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Pricing',
            bulletPoints: modalContractsDetailsData?.prices
                ? (
                    Array.isArray(modalContractsDetailsData.prices) ? modalContractsDetailsData.prices.map(p => JSON.stringify(p))
                        : typeof modalContractsDetailsData.prices === 'string' ? [modalContractsDetailsData.prices]
                            : [JSON.stringify(modalContractsDetailsData.prices)]
                ) : []
        },
        {
            blockTitle: 'Price adjustment',
            bulletPoints: modalContractsDetailsData?.price_adjustment ? [modalContractsDetailsData.price_adjustment] : []
        },
        {
            blockTitle: 'Volume Discount',
            bulletPoints: modalContractsDetailsData?.volume_discount ? [modalContractsDetailsData.volume_discount] : []
        },
        {
            blockTitle: 'Payment Terms',
            bulletPoints: modalContractsDetailsData?.payment_terms ? [modalContractsDetailsData.payment_terms] : []
        },
        {
            blockTitle: 'Invoice and Payment',
            bulletPoints: modalContractsDetailsData?.invoices_and_payment ? [modalContractsDetailsData.invoices_and_payment] : []
        },
    ];
    const contractProductDetailsCard: ModalCardBlockType[] = [
        // {
        //     blockTitle: 'Price Table',
        //     bulletPoints: modalContractsDetailsData?.price_table ? [modalContractsDetailsData.price_table] : [
        //         "Product thickness and width deviations allowed within\n" +
        //         "certain limits.\n" +
        //         "Over or under delivery acceptable within specified percentages based on net weight."]
        // },
        {
            blockTitle: 'Products / Services',
            bulletPoints: modalContractsDetailsData?.products ? [modalContractsDetailsData.products] : []
        },
        {
            blockTitle: 'Quantity',
            bulletPoints: modalContractsDetailsData?.quantity ? [modalContractsDetailsData.quantity] : []
        },
    ];
    const contractDeliveryNStockCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Delivery',
            bulletPoints: modalContractsDetailsData?.delivery ? [modalContractsDetailsData.delivery] : []
        },
        {
            blockTitle: 'Incoterms',
            bulletPoints: modalContractsDetailsData?.incoterms ? [modalContractsDetailsData.incoterms] : []
        },
        // {
        //     blockTitle: 'Quantity Thresholds',
        //     bulletPoints: modalContractsDetailsData?.quantity_thresholds ? ['BM Holding maintains a minimum stock level with maximum stock in the supply chain not exceeding 13 weeks.'] : []
        // }
    ];
    const contractTerminationNLegaCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Renewal Conditions',
            bulletPoints: modalContractsDetailsData?.renewal_condition ? [modalContractsDetailsData.renewal_condition] : []
        },
        {
            blockTitle: 'Termination',
            bulletPoints: modalContractsDetailsData?.termination ? [modalContractsDetailsData.termination] : []
        },
        {
            blockTitle: 'Liability & Legal',
            bulletPoints: modalContractsDetailsData?.liability ? [modalContractsDetailsData.liability] : []
        },
        {
            blockTitle: 'Disagreement Resolving',
            bulletPoints: modalContractsDetailsData?.disagreements_resolving ? [modalContractsDetailsData.disagreements_resolving] : []
        },
        {
            blockTitle: 'Governing Law',
            bulletPoints: modalContractsDetailsData?.governing_law ? [modalContractsDetailsData.governing_law] : []
        },
        {
            blockTitle: 'Confidentiality',
            bulletPoints: modalContractsDetailsData?.confidential ? [modalContractsDetailsData.confidential] : []
        },
    ];
    const contractPerformanceNComplianceCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Performance Obligations',
            bulletPoints: modalContractsDetailsData?.performance ? [modalContractsDetailsData.performance] : []
        },
    ];
    const contractGroupOfProductsCard: ModalCardBlockType[] = [
        // {
        //     blockTitle: 'Annual Cost & Volume',
        //     bulletPoints: modalContractsDetailsData?.annual_cost_volume ? [modalContractsDetailsData.annual_cost_volume] : ['Not specified']
        // },
    ];

    if (!modalContractsDetailsData) {
        return null;
    }


    return (
        <GenericModalVertical
            titleId={""}
            className="contract-details-modal"
            isOpen={isOpen}
            onSave={() => {
                contractInsightsStore.setDetailModalVisible(false)
            }}
            saveTitle={"Done"}
            onCancel={() => {
                contractInsightsStore.setDetailModalVisible(false)
            }}
            title={"Contract details"}
            maxWidth="md"
        >
            <Grid container>
                <Grid container item xs={10} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                    <ModalCard title="Contract Info" icon={<Info/>} blocks={contractInfoCard}/>
                    <ModalCard title="Contract Financial Terms" icon={<RequestPage/>}
                               blocks={contractFinancialTermsCard}/>
                    <ModalCard title="Contract Product Details" icon={<Inventory/>}
                               blocks={contractProductDetailsCard}/>
                    <ModalCard title="Contract Delivery & Stock" icon={<LocalShipping/>}
                               blocks={contractDeliveryNStockCard}/>
                    <ModalCard title="Contract Termination & Legal" icon={<Gavel/>}
                               blocks={contractTerminationNLegaCard}/>
                    <ModalCard title="Contract Performance & Compliance" icon={<Analytics/>}
                               blocks={contractPerformanceNComplianceCard}/>
                    <ModalCard title="Contract Group of Products" icon={<Dashboard/>}
                               blocks={contractGroupOfProductsCard}/>
                </Grid>
            </Grid>
        </GenericModalVertical>
    );
})

const ModalCard: React.FC<ModalCardProps> = ({title, icon, blocks}) => (
    <Grid container className={`modal-row`}
          style={{
              margin: '1em 0',
              padding: '1em',
              background: '#F6F6F6',
              borderRadius: '5px',
              border: '1px groove #D1D6DD'
          }}>
        <Grid item xs={1} className="row-icon">
            {icon}
        </Grid>
        <Grid item xs={11} className="row-title">
            <Typography variant="h5" component="h5">
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} className="row-content">
            {blocks.map(block => (
                block.bulletPoints.length > 0 && (
                    <>
                        <Grid item className="block" style={{marginTop: '1.5em', marginBottom: '1em'}}>
                            <Typography variant="h6" component="h6">
                                {block.blockTitle}
                            </Typography>
                            <ul>
                                {block.bulletPoints.map(bulletPoint => (
                                    <li>{bulletPoint}</li>
                                ))}
                            </ul>
                        </Grid>
                        <Divider/>
                    </>
                )
            ))}
        </Grid>
    </Grid>
);
