import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useSitStores} from "../../../stores";
import {useHistory} from "react-router";
import {
    Checkbox,
    FormControl,
    FormControlLabel, Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';

export const InitiativeForm: React.FC = observer(() => {
    const {ccStore, initiativeStore} = useSitStores();
    const history = useHistory();
    const match = useRouteMatch<{ initiativeId?: string }>();
    const initiativeId = 'initiativeId' in match?.params ? Number(match?.params['initiativeId']) : 0;

    useEffect(() => {
        if (initiativeStore.instance.id !== initiativeId)
            initiativeStore.getInstance(initiativeId);
    }, [initiativeStore, initiativeId])


    return <KoiFacade
        title={<Typography variant="h2" component="h1">
            Add New Initiative
        </Typography>}
        onBack={() => history.goBack()}
        backClass={""}
        className="initiative-page">
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' component='p'>General Information</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='title'
                            variant='outlined'
                            label='Title'
                            value={initiativeStore.instance.title}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            multiline minRows={3}
                            id='initiativedescriptions'
                            variant='outlined'
                            label='Description'
                            value={initiativeStore.instance.initiativedescriptions}/>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id="lever-group-label">Lever group</InputLabel>
                        <Select
                            labelId="lever-group-label"
                            id="lever-group"
                            value={initiativeStore.instance.lever?.group.id ?? ''}
                            label="Lever group"
                            onChange={() => {
                            }}
                        >
                            {ccStore.leverGroupRM.result?.map((lever_group) => {
                                return <MenuItem value={lever_group.id}>{lever_group.title}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id="lever-label">Lever</InputLabel>
                        <Select
                            labelId="lever-label"
                            id="lever"
                            value={initiativeStore.instance.lever?.id ?? ''}
                            label="Lever"
                            onChange={() => {
                            }}
                        >
                            {ccStore.leverRM.result?.map((lever) => {
                                return <MenuItem value={lever.id}>{lever.title}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={
                        <Checkbox value={initiativeStore.instance.confidential}/>
                    } label="Confidential"/>
                    <FormControlLabel control={
                        <Checkbox value={initiativeStore.instance.nda_required}/>
                    } label="Under NDA"/>
                    <FormControlLabel control={
                        <Checkbox value={initiativeStore.instance.sustainability}/>
                    } label="Sustainabiliy"/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' component='p'>Scope & Team</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='owner'
                            variant='outlined'
                            label='Initiative Owner'
                            value={initiativeStore.instance.owner}/>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            labelId="lever-label"
                            id="category"
                            value={initiativeStore.instance.financial.at(0)?.category.id ?? ''}
                            label="Category"
                            onChange={() => {
                            }}
                        >
                            {ccStore.categoryRM.result?.filter((category) =>
                                category.level === 1
                            )?.map((category) => {
                                return <MenuItem value={category.id}>{category.l1}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id="sub-category-label">Subcategory</InputLabel>
                        <Select
                            labelId="sub-category-label"
                            id="sub-category"
                            value={initiativeStore.instance.financial.at(0)?.category.id ?? ''}
                            label="Subcategory"
                            onChange={() => {
                            }}
                        >
                            {ccStore.categoryRM.result?.filter((category) =>
                                category.level === 2
                            )?.map((category) => {
                                return <MenuItem value={category.id}>{category.l2}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id="company-label">Company</InputLabel>
                        <Select
                            labelId="company-label"
                            id="company"
                            value={initiativeStore.instance.financial.at(0)?.company.id ?? ''}
                            label="Company"
                            onChange={() => {
                            }}
                        >
                            {ccStore.companyRM.result?.map((company) => {
                                return <MenuItem value={company.id}>{company.title}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' component='p'>Financial Information</Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth margin='dense'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="DOI5 Project Date"
                                value={dayjs(initiativeStore.instance.doi.filter((status) =>
                                    status.doi_stage === 5)?.at(0)?.due_date)}
                                views={['month', 'year']}/>
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='affected_months'
                            variant='outlined'
                            label='Affected period'
                            InputProps={{
                                endAdornment: <InputAdornment position="end">months</InputAdornment>
                            }}
                            value={initiativeStore.instance.financial.at(0)?.affected_months}/>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='fiscal_year'
                            variant='outlined'
                            label='Fiscal year'
                            value={initiativeStore.instance.financial.at(0)?.fiscal_year}/>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            labelId="currency-label"
                            id="currency"
                            value={initiativeStore.instance.financial.at(0)?.currency.id ?? ''}
                            label="Company"
                            onChange={() => {
                            }}
                        >
                            {ccStore.currencyRM.result?.map((currency) => {
                                return <MenuItem value={currency.id}>{currency.code}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='baseline_spend'
                            variant='outlined'
                            label='Baseline spend'
                            value={initiativeStore.instance.financial.at(0)?.baseline_spend}/>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='severance'
                            variant='outlined'
                            label='Severance'
                            value={initiativeStore.instance.financial.at(0)?.severance}/>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth margin='dense'>
                        <InputLabel id="saving-method-label">Cost saving method</InputLabel>
                        <Select
                            labelId="saving-method-label"
                            id="saving-method"
                            value={''}
                            label='Cost saving method'
                            onChange={() => {
                            }}
                        >
                            <MenuItem value={1}>Standard Saving Method</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='old-price'
                            variant='outlined'
                            label='Old price'
                            value={''}/>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='new-price'
                            variant='outlined'
                            label='New price'
                            value={''}/>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth margin='dense'>
                        <TextField
                            id='quantity-per-month'
                            variant='outlined'
                            label='Quantity per month'
                            value={''}/>
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    </KoiFacade>
});