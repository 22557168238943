import '../DataIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Alert, Button, CircularProgress, Grid} from "@mui/material";
import {DataIngestionStepper} from "../DataIngestionStepper";
import {ChevronRight} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../../routing/routes";
import {useStores} from "../../../stores";
import {DataUploadForm} from "./DataUploadForm";
import {GcpBucketUploadComponent} from "./gcp-bucket-upload/GcpBucketUploadComponent";
import {uploadController} from "./DataUploadController";
import {environment} from "../../../env";
import {DirectUploadComponent} from "./DirectUploadComponent";

type Props = {}
export const DataUploadPage: React.FC<Props> = observer(() => {
    const {dataIngestionStore, authStore, bagStore, p} = useStores();
    const history = useHistory();
    const isDemo = environment.isDemo;
    const useDirectUpload = p.p.useIngestionDirectUpload;

    const isLoading = dataIngestionStore.datasetUploadIsBusy;

    const dataFileId = dataIngestionStore.dataFile?.id;
    useEffect(() => {
        // If we have set the dataFileId and the status was updated to 'user-configuring', change the page
        if (dataFileId && dataIngestionStore.dataIngestionStatusEnum === 'user-configuring') {
            history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataFileId)}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIngestionStore.dataIngestionStatusEnum, dataFileId]);

    const readyForNext = useDirectUpload
        ? (dataIngestionStore.file && !dataIngestionStore.errorMsg)
        : (!dataIngestionStore.datasetIsUploadingFile && !dataIngestionStore.errorMsg && uploadController.isUploaded)

    const handleNextClick = () => {
        if (isDemo) {
            setTimeout(() => {
                const dataFileId = new Date().getDate();
                history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataFileId)}))
                // dataIngestionStore.navigateToPage('data_mapping')
            }, 2000);
            return;
        }
        if (useDirectUpload) {
            dataIngestionStore.uploadFileDirectly();
        } else {
            dataIngestionStore.uploadDroppedFile()
        }
    };

    return <div className="data-ingestion data-upload-page">
        <DataIngestionStepper defaultPage={"dataset_upload"}></DataIngestionStepper>

        <Grid container justifyContent="center" className="data-ingestion-page-content">
            <Grid item xs={12} sm={9} md={6} lg={5} xl={4}>
                {dataIngestionStore.errorMsg &&
                    <Alert severity="error" className="data-ingestion-root-error">{dataIngestionStore.errorMsg}</Alert>
                }

                {useDirectUpload
                    ? <DirectUploadComponent
                        onSelectFile={(file) => dataIngestionStore.setFileToUploadDirectly(file)}
                        file={dataIngestionStore.file}
                        isBusy={dataIngestionStore.datasetUploadIsBusy}
                    />
                    : <GcpBucketUploadComponent/>
                }

                <DataUploadForm/>

                <Grid container justifyContent="center" className="data-ingestion-page-action-button">
                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={!readyForNext}
                            endIcon={isLoading ? <CircularProgress size={20}/> : <ChevronRight/>}
                            onClick={() => handleNextClick()}>
                            Next
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    </div>
});
