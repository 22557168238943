import {styled} from '@mui/material/styles';
import React, {useEffect} from "react";
import {useStores} from "../../../stores";
import {Bag} from "../../../services/classes/Bag";
import {useHistory} from "react-router";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {observer} from "mobx-react-lite";
import {SelectDatasetMenu} from "../../_tiny/SelectDatasetMenu";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from '../../../routing/routes';

const PREFIX = 'SelectDataset';

const classes = {
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    formEditIcon: `${PREFIX}-formEditIcon`
};

const StyledFormControl = styled(FormControl)(({theme}) => ({
    [`&.${classes.formControl}`]: {
        margin: theme.spacing(1),
        minWidth: 300,
        minHeight: '4em',
    },

    [`& .${classes.selectEmpty}`]: {
        marginTop: theme.spacing(2),
    },

    [`& .${classes.formEditIcon}`]: {
        margin: theme.spacing(1),
    }
}));

const menuProps = {
    id: 'header-databag-menu',
};

type Props = {
    disabled?: boolean;
    showEmpty?: boolean;
}
export const SelectDataset: React.FC<Props> = observer(({disabled, showEmpty}) => {
    const {bagStore} = useStores();
    const bag = bagStore.bag as Bag;
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();

    const bags = bagStore.allBags;
    useEffect(() => {
        if(!bags) {
            // TODO: The select-dataset is reloaded when the page is changed, this can be optimized
            //  This component is unloaded when the router changes, pull the component higher up in the route chain
            bagStore.getAllBags()
        }
    }, [bagStore, bags])

    if (!bags)
        return <StyledFormControl className={classes.formControl}>
            <InputLabel id="selected-bag-loading-label">&nbsp;</InputLabel>
            <Select
                variant="standard"
                labelId="selected-bag-loading-label"
                id="selected-bag-loading"
                className="select-bag-field is-loading"
                value="Loading..."
                disabled>
                <MenuItem value="Loading..."/>
            </Select>
        </StyledFormControl>;

    if (!bag) return <></>;
    return <StyledFormControl className={classes.formControl} variant="standard">
        {!showEmpty && <SelectDatasetMenu
            variant="standard"
            labelId="selected-bag-label"
            id="selected-bag"
            value={bag.id || ''}
            onChange={event => {
                const bagId = event.target.value;
                bagStore.changeBag(bagId, history, routeMatch);
            }}
            // displayEmpty
            className={classes.selectEmpty + ' select-bag-field'}
            disabled={disabled}
            bags={bags}
            MenuProps={menuProps}
        />}
    </StyledFormControl>
})
