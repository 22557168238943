import {ApiService} from "./Http";
import {job_data_Response, JobPriceVarianceResp, ppv_all_groups_Response, ServerConfig} from "./ApiTypes";
import {AxiosResponse} from "axios";
import {Bag} from "./classes/Bag";

const USE_CACHE = false;

export default class MithraApi extends ApiService {
    private cache = new Map<string, any>();

    private cached: <T> (promise: Promise<T>, key: string) => Promise<T> = (promise, key) => {
        if (!USE_CACHE)
            return promise;
        if (this.cache.has(key)) {
            console.log('Cache hit', key);
            return Promise.resolve(this.cache.get(key));
        } else {
            console.log('Cache miss', key);
            return promise.then(resp => {
                console.log('Cache store', key);
                this.cache.set(key, resp);
                return resp;
            })
        }
    };

    // KPI endpoints

    getTotalPriceVarianceGroups(id: number): Promise<number> {
        return this.getPriceVarianceGroups(id)
            .then(resp => resp.data.total_opportunity);
    }

    getPriceVarianceGroups(id: number) {
        return this.cached(this.http.get<JobPriceVarianceResp>(`/bag/kpi_koi/${id}/supplier_pv/?top_groups=0`), `koi-ppv-${id}`);
    }

    getPriceVarianceGroupedGroups(id: number) {
        return this.http.get<ppv_all_groups_Response>(`/bag/kpi_koi/${id}/ppv_all_groups/`)
    }

    downloadPPV(id: number) {
        // Tip check: https://gist.github.com/jbutko/d7b992086634a94e84b6a3e526336da3
        return this.http.get(`/bag/kpi_koi/${id}/data/purchase_price_variance_sheet/`, {responseType: 'blob'})
    }

    getPPVDataAll(id: number): Promise<AxiosResponse<any[]>> {
        return this.http.get(`/bag/kpi_koi/${id}/data/purchase_price_variance_data/?no_page`);
    }

    getPartData(id: number, supplier_name: string): Promise<AxiosResponse<job_data_Response[]>> {
        return this.http.get<job_data_Response[]>(`/bag/${id}/data/?supplier=${encodeURIComponent(supplier_name)}`)
    }

    getServerConfig() {
        return this.http.get<ServerConfig>('/config/');
    }

    getDataBagList() {
        return this.http.get<Bag[]>('/bag/');
    }

    downloadCategorizationResultExcel(bagId: number) {
        return this.http.get(`/ai/bag/${bagId}/v2_data/download_excel/`, {responseType: 'blob'})
    }
}
