import React, {useEffect} from "react";
import './ManageDataPage.scss';
import {Bag} from "../../services/classes/Bag";
import {useStores} from "../../stores";
import {Button, Grid, Typography} from "@mui/material";
import {DataSetSelectEditComponent} from "./DataSetSelectEditComponent";
import {generatePath, useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from '../../routing/routes';
import {generateHomePath} from "../../routing/routing.utils";
import {observer} from "mobx-react-lite";

export const ManageDataPage: React.FC = observer(() => {
    const {p, rootAppStore, bagStore, authStore} = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    useEffect(() => {
        bagStore.getAllBags();
    }, [bagStore])
    const bags = bagStore.allBags;

    const showUploadPage = false; // authStore.canUpload
    const canEditName = authStore.canUpload

    const handleStart = () => {
        if (selectedBag && selectedBag.id !== -1) {
            bagStore.setBag(selectedBag);
            history.push(generateHomePath(routeMatch, {id: String(selectedBag.id)}, rootAppStore.app));
        }
    }

    const [selectedBag, setSelectedBag] = React.useState<Bag | undefined>(bagStore.bag);
    const [busy, setBusy] = React.useState(false);

    useEffect(() => {
        if (bags && bags.length > 0 && !selectedBag) {
            setSelectedBag(bags.at(-1))
        }
    }, [bags, selectedBag])

    return <section>
        <Grid container className="manage-data-page" justifyContent="center">
            <Grid item xs={3}/>
            <Grid item>
                <Typography variant="h4" component="h2" className="title">
                    Select data
                </Typography>
                <DataSetSelectEditComponent
                    enableNew={showUploadPage}
                    enableEditName={canEditName}
                    bags={bags}
                    selectedBagId={selectedBag?.id}
                    disabled={busy}
                    onSelectBag={b => setSelectedBag(b)}
                    onPatchBag={b => {
                        // Note: b is a new object
                        if (!bags || busy) return;
                        const newBags = [...bags];
                        const patchI = bags.findIndex(b => b.id === selectedBag?.id);
                        if (patchI === -1) return;

                        newBags[patchI] = b;
                        setBusy(true);
                        bagStore.setAllBags(newBags)
                        bagStore.patch(b)
                            .then(r => setSelectedBag(r.data))
                            .finally(() => setBusy(false))
                    }}
                    onSaveNewBag={(name) => {
                        if (!bags || busy) return;
                        const currentNewBagI = bags?.findIndex(b => b.id === -1);
                        if (currentNewBagI !== -1) {
                            const currentNewBag = bags[currentNewBagI];
                            // Patch bag
                            const newBags = [...bags];
                            newBags[currentNewBagI] = {...currentNewBag, name};
                            bagStore.setAllBags(newBags)
                            setSelectedBag(currentNewBag);
                        } else {
                            const newBag = bagStore.construct(name);
                            const newBags = [...bags, newBag];
                            bagStore.setAllBags(newBags)
                            setSelectedBag(newBag);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={3}/>
            <Grid item xs={6} className="container-files">
                <section className="buttons">
                    <Button variant="contained" color="primary" className="bigger-button"
                            id="start-app-button"
                            onClick={handleStart} disabled={busy}>
                        Start
                    </Button>
                </section>

                {(p.p.demoFakeDataIngestion || p.p.enableDataIngestion) &&
                    <section className="buttons">
                        <Button variant="contained" color="primary" className="bigger-button"
                                id="start-ingestion-app-button"
                                onClick={() => {
                                    if (p.p.demoFakeDataIngestion) {
                                        history.push(generatePath(routes.data_upload));
                                    } else if (p.p.enableDataIngestion) {
                                        history.push(generatePath(routes.data_ingestion));
                                    }
                                }}>
                            data ingestion
                        </Button>
                    </section>
                }

                {p.p.enableTaxonomyIngestion &&
                    <section className="buttons">
                        <Button variant="contained" color="primary" className="bigger-button"
                                id="start-taxonomy-ingestion-app-button"
                                onClick={() => {
                                    history.push(generatePath(routes.taxonomy_ingestion));
                                }}>
                            taxonomy ingestion
                        </Button>
                    </section>
                }

            </Grid>
        </Grid>
    </section>
});
