import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {
    Button,
    Grid,
    Tab,
    Tabs,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    FormControl,
    InputAdornment,
    InputLabel, Select, MenuItem,
} from "@mui/material";
import {useSitStores} from "../../../stores";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {INITIATIVE_COLUMNS} from "./columns";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {generatePath, useHistory} from "react-router";
import {sitRoutes} from "../../routing/sitRouting";
import {Search} from "@mui/icons-material";
import {Plus} from "lucide-react";


export const InitiativeTable: React.FC = observer(() => {
    const {initiativeStore,} = useSitStores();
    const history = useHistory();
    const [value, setValue] = useState(0);

    return <Grid container>
        <Grid item xs={12}>
            <Tabs value={value} onChange={() => {
            }}>
                <Tab label="INITIATIVES" value='0'/>
                <Tab label="FINANCIAL PERFORMANCE" value='1'/>
                <Tab label="SAVING TRACKER" value='2'/>
            </Tabs>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={2} columns={24}>
                <Grid item xs={4}>
                    <FormControl>
                        <TextField
                            id='title'
                            variant='standard'
                            label='Search for initiatives'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
                            }}
                            value={''}/>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="show-label">Show</InputLabel>
                        <Select
                            labelId="show-label"
                            id="show"
                            value={'1'}
                            label='Show'
                            onChange={() => {
                            }}
                        >
                            <MenuItem value={1}>My initiatives</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category"
                            value={'1'}
                            label='Show'
                            onChange={() => {
                            }}
                        >
                            <MenuItem value={1}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="company-label">Company</InputLabel>
                        <Select
                            labelId="company-label"
                            id="company"
                            value={'1'}
                            label='Show'
                            onChange={() => {
                            }}
                        >
                            <MenuItem value={1}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="doi-stage-label">DOI Stage</InputLabel>
                        <Select
                            labelId="doi-stage-label"
                            id="doi-stage"
                            value={'1'}
                            label='DOI Stage'
                            onChange={() => {
                            }}
                        >
                            <MenuItem value={1}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl>
                        <TextField
                            id='more'
                            disabled
                            variant='standard'
                            label='More'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><Plus/></InputAdornment>
                            }}
                            value={''}/>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl margin='dense'>
                        <Button variant='contained' onClick={() => {
                            initiativeStore.getInstance(0);
                            history.push(generatePath(sitRoutes.sit_initiative, {initiativeId: 0}))
                        }}>Add new initiative
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <TableContainer className={'mithra-table x-design target-table' +
                (initiativeStore.initiativeListRM.busy ? ' loading' : '')}>
                <Table>
                    <MithraTableHeadColumns columns={INITIATIVE_COLUMNS} enableLoading
                                            isLoading={initiativeStore.initiativeListRM.busy}/>
                    {initiativeStore.initiativeListRM.result &&
                        <TableBody>
                            {initiativeStore.initiativeListRM.result?.results.map((row) => {
                                return (
                                    <TableRow onClick={() => {
                                        initiativeStore.getInstance(0);
                                        history.push(generatePath(sitRoutes.sit_initiative, {initiativeId: row.id}))
                                    }}>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[0]}>{row.title}</MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[1]}>{row.owner}</MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[2]}>{
                                            row.financial.length > 0 &&
                                            row.financial[0].category.l1 + ', ' + row.financial[0].category.l2 + ', ' + row.financial[0].category.l3
                                        }
                                        </MithraTableCell>
                                        <MithraTableCell
                                            c={INITIATIVE_COLUMNS[3]}>{row.lever?.group.title}</MithraTableCell>
                                        <MithraTableCell c={INITIATIVE_COLUMNS[4]}>{
                                            row.doi.slice().sort((a, b) =>
                                                (new Date(a.due_date).getTime()) - (new Date(b.due_date).getTime())).at(0)?.doi_stage
                                        }
                                        </MithraTableCell>
                                        <MithraTableCell
                                            c={INITIATIVE_COLUMNS[4]}>{row.financial[0].saving_amount}</MithraTableCell>
                                        <MithraTableCell
                                            c={INITIATIVE_COLUMNS[5]}>{row.financial[0].avoidance_amount}</MithraTableCell>
                                        <MithraTableCell
                                            c={INITIATIVE_COLUMNS[6]}>{row.created}</MithraTableCell>
                                        <MithraTableCell
                                            c={INITIATIVE_COLUMNS[7]}>{row.responsible}</MithraTableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={INITIATIVE_COLUMNS.length}
                                count={initiativeStore.initiativeListRM.result?.count ?? 0}
                                rowsPerPage={parseInt(initiativeStore.initiativeListFilter.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                                page={parseInt(initiativeStore.initiativeListFilter.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                                onPageChange={(event, page: number) => {
                                    initiativeStore.initiativeListFilter.setFilters([['page', (page + 1).toString()]]);
                                }}
                                onRowsPerPageChange={(event) => {
                                    initiativeStore.initiativeListFilter.setFilters([['page_size', (event.target.value).toString()]], false);
                                    initiativeStore.initiativeListFilter.setFilters([['page', '1']]);
                                }}
                                ActionsComponent={MithraTablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
});