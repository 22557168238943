import {makeAutoObservable} from "mobx";

class SitHomeController {
    counter = 0

    constructor() {
        makeAutoObservable(this);
    }

    setCounter(value: number) {
        this.counter = value;
    }
}

export const sitHomeController = new SitHomeController();
