import ProfileStore from "../../../stores/ProfileStore";
import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export function getPpvSubTableColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-p_name', txt: p.partDataName, align: 'center'},
        {cls: 'col-p_description', txt: 'Description'},
        {cls: 'col-n_parts', txt: 'Transaction count', align: 'center'},
        {cls: 'col-s_name', txt: p.supplierDataName, align: 'center'},
        {cls: 'col-s_country', txt: 'Supplier location', align: 'center'},
        {cls: 'col-cc_description', txt: 'Site', align: 'center'},
        {cls: 'col-p_quantity', txt: 'Quantity', align: 'center'},
        {cls: 'col-p_spend', txt: 'Amount', align: 'center'},
        {cls: 'col-combined_unit_cost', txt: 'Avg. unit cost', align: 'center'},
        {cls: 'col-ppv_delta', txt: 'Variance cost', align: 'center'},
        {cls: 'col-ppv_delta_perc', txt: 'PPV delta %', align: 'center'},
        {cls: 'col-ppv_opportunity', txt: 'PPV opportunity', align: 'center'},
        p.p.isPpvReadOnly ? undefined : {cls: 'col-button', txt: '', align: 'center'},
    ];
    return arr.filter(c => c) as ColSpec[];
}

export function getPpvTableColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-dropdown', txt: '', align: 'center'},
        {cls: 'col-group_key', txt: p.groupKeyDataName},
        {cls: 'col-variance_types', txt: 'Variance Types', align: 'center'},
        {cls: 'col-total_variance_cost', txt: 'Total PPV opportunity', align: 'center'},
        {cls: 'col-notes', txt: 'Notes', width: '300px'},
        !p.p.isPpvReadOnly ? {cls: 'col-button', txt: '', width: '150px'} : undefined,
    ];

    return arr.filter(c => c) as ColSpec[];
}
