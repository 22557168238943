import React from "react";
import {Button, CircularProgress, Grid} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from '../../routing/routes';
import {generateHomePath} from "../../routing/routing.utils";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {CategorizationStatisticsDashboard} from "./components/statistics/CategorizationStatisticsDashboard";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {useHistory} from "react-router";

type Props = {}
export const CategorizationStatisticsPage: React.FC<Props> = observer(() => {
    const {rootAppStore, bagStore, categorizationReviewStore} = useStores();
    const statisticsDashboardController = categorizationReviewStore.statisticsDashboardController;
    const history = useHistory();

    const bagId = bagStore.bagId;
    const routeMatch = useRouteMatch<JobRouteMatch>();

    return (
        <KoiFacade
            title=''
            back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
            onBack={() => {
                history.goBack()
            }}
            next={{
                type: 'component',
                node: <>
                    <Button
                        color="primary"
                        variant="outlined"
                        className="btn-with-circular-progress"
                        disabled={statisticsDashboardController.requestIsBusy}
                        startIcon={statisticsDashboardController.requestIsBusy ? <CircularProgress/> : <Refresh/>}
                        onClick={() => statisticsDashboardController.updateCategorizationVersionedStatistics(categorizationReviewStore.bagId)}>
                        Update statistics
                    </Button>
                </>
            }}
            className="categorization-statistics-page"
        >
            <Grid container justifyContent="center" className='page-content-grid'>
                <Grid item className="categorization-v2-page-content">
                    <CategorizationStatisticsDashboard/>
                </Grid>
            </Grid>
        </KoiFacade>)
});
