/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";
export const C: AppConfiguration = {
    package: 'cleanup',
    dashboardVariant: 'simple',
    menu: 'single',
    profile: {
        p_name_col_name: 'Part Name',
        p_description_col_name: 'Part Description',

        s_col_name: 'Vendor',
        bu_col_name: 'Company Code',

        p_context_1_col_name: 'Context 1',
        p_context_2_col_name: 'Context 2',
        p_context_3_col_name: 'Context 3',
        p_context_pref: [1, 2],

        p_ddate_pref: [1],
        p_ddate_1_col_name: 'Date field 1',
        p_ddate_1_col_type: 'date',

        defaultPartContextColNames: [
            {colName: 'Date field 1', colType: 'date', dataField: 'p_ddate_1', contextI: '1', colClassName: 'col-p_ddate_1'},
        ],

        enableDataIngestion: true,

        catFilterSearchPC23: true,
        searchConfiguration: 'default_p_desc',
        categorizationShowScore: true,
        s_context_1_col_name: 'Supplier context 1',

        showUncategorizedSubBars: true,

        locale: 'en-en',
        currency: 'EUR',

        useAiTaxonomyHealthCheck: true,

        enableTaxonomyIngestion: true,
    },
}
