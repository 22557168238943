import {AxoisRequestManager} from "../../stores/managers/RequestManager";
import {from} from "rxjs";
import SitApi from "../SitApit";
import {makeAutoObservable} from "mobx";
import {Category, Company, Currency, Lever, LeverGroup} from "../ApiTypes";

export class CompanyConfigurationStore {
    readonly leverGroupRM = new AxoisRequestManager<void, LeverGroup[]>(
        () => from(this.api.listLeverGroups()));
    readonly leverRM = new AxoisRequestManager<void, Lever[]>(
        () => from(this.api.listLevers()));
    readonly categoryRM = new AxoisRequestManager<void, Category[]>(
        () => from(this.api.listCategories()));
    readonly companyRM = new AxoisRequestManager<void, Company[]>(
        () => from(this.api.listCompanies()));
    readonly currencyRM = new AxoisRequestManager<void, Currency[]>(
        () => from(this.api.listCurrencies()));

    constructor(private api: SitApi) {
        makeAutoObservable(this, {});
        if(this.leverGroupRM.result === undefined)
            this.leverGroupRM.request();
        if(this.leverRM.result === undefined)
            this.leverRM.request();
        if(this.categoryRM.result === undefined)
            this.categoryRM.request();
        if(this.companyRM.result === undefined)
            this.companyRM.request();
        if(this.currencyRM.result === undefined)
            this.currencyRM.request();
    }
}