import {Alert} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../../stores";
import moment from "moment";

type Props = {}
export const TaxonomySelectionAlert: React.FC<Props> = observer(() => {
    const {categorizationReviewStore, taxonomyManagerStore} = useStores();
    const job = categorizationReviewStore.statusDelegate.status?.categorization_job.latest;
    const lastRunDate: string = job?.created ? moment(job.created).format('L') : '';

    // For backwards compatibility the taxonomy is taken from the taxonomyManagerStore if latest status is not available
    const taxonomy = job?.taxonomy || taxonomyManagerStore.taxonomy;

    const taxonomyName: string = taxonomy?.name || '';
    const severity = taxonomyName ? 'info' : 'warning';
    const style = taxonomyName ? {background: '#0262F21A', marginBottom: '1em'} : {marginBottom: '1em'};
    return <Alert severity={severity} style={style}>
        {
            taxonomyName
                ? `You are reviewing the categorization based on taxonomy ${taxonomyName}${lastRunDate ? ` from “${lastRunDate}”` : ''}`
                : `You are reviewing the categorization based on an unknown taxonomy`
        }
    </Alert>
});
