import {GROUP_READ_ONLY, GROUP_STAFF, GROUP_SUPER, GROUP_USER} from "../services/ApiTypes";
import {makeAutoObservable} from "mobx";
import {AuthenticationManager} from "./managers/AuthManager";
import AuthApi from "../services/AuthApi";
import ProfileStore from "./ProfileStore";
import {LogoutOptions} from "@auth0/auth0-react";
import {stores} from "./index";
import {User} from "../services/classes/User";

/**
 * Authentication can be improved
 * - Add refresh tokens
 * - Add support for automatic login
 * - Test by external party
 */
export default class AuthStore {
    authentication = new AuthenticationManager(this.api);

    constructor(
        private api: AuthApi,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this);
    }

    get user() {
        return this.authentication.authenticatedUser
    }

    get userId() {
        return this.user?.id;
    }

    getUserId(): number {
        const userId = this.userId;
        if (typeof userId !== 'number') {
            throw new Error('Could not find user_id');
        }
        return userId;
    }

    get displayUser(): string {
        if (!this.user) {
            return ''
        }

        const d = this.user;
        if (d?.first_name) {
            const lastName = this.profileStore.filterLastName(d.last_name || '');
            return lastName ? `${d.first_name} ${lastName}` : d.first_name;
        }
        return this.user.username || '' // The username is the fall-back
    }

    get email(): string {
        return this.user?.email || ''
    }

    get isMithraStaff(): boolean {
        if (this.user) {
            // noinspection SuspiciousTypeOfGuard
            if (typeof this.user.is_staff === 'boolean') {
                return this.user.is_staff;
            }
        }
        return false
    }

    userHasGroup(group: string): boolean {
        return this.user?.groups?.includes(group) || false;
    }

    get viewOnly() {
        if (!this.user || this.user.groups === undefined || this.user.groups === null) {
            // When no user is set, or no groups are known, we assume the user is read-only
            return true;
        }
        return this.userHasGroup(GROUP_READ_ONLY)
    }

    get canUpload() {
        // Upload is allowed for staff users at all times
        return this.isMithraStaff;
    }

    get isCustomerSuper(): boolean {
        return this.userHasGroup(GROUP_SUPER);
    }

    get isMithraStaffGroup(): boolean {
        return this.userHasGroup(GROUP_STAFF);
    }

    get customerRole(): string {
        if (!this.user) {
            return 'unknown user'
        }
        if (this.isMithraStaffGroup) {
            return 'staff';
        }
        if (this.viewOnly) {
            return 'view only';
        }
        if (this.isCustomerSuper) {
            return 'super user';
        }
        if (this.userHasGroup(GROUP_USER)) {
            return 'user';
        }
        return String(this.user.groups)
    }

    public logout() {
        this.authentication.logout();
    }

    public auth0Logout(auth0LogoutHandle: (options: LogoutOptions | undefined) => void) {
        this.authentication.auth0Logout(auth0LogoutHandle);
    }

    public isLoggedIn(): boolean {
        return Boolean(this.user);
    }

    devopsSetGroups(groups: string[]) {
        const userId = this.userId;
        const http = stores.materializedApi.http;
        if (!userId) return;
        return http.post<User>(`/users/${userId}/devops_set_groups/`, {groups})
            .then(r => this.authentication.onChangeUserData(r.data))
    }
}
