import {SitHomePage} from "../pages/home/SitHomePage";
import React from "react";
import {AuthRoute, RouteSpec} from "../../routing/AuthRoute";
import {SitRouteWrapper} from "./SitRouteWrapper";
import {routeKey} from "../../routing/routing.utils";
import {TargetTable} from "../pages/target/TargetTable";
import {InitiativeTable} from "../pages/initiative/InitiativeTable";
import {InitiativeForm} from "../pages/initiative/InitiativeForm";

const R = AuthRoute;
const k = routeKey;
const sit: RouteSpec = SitRouteWrapper;

/**
 * All the URL names
 */
const r = {
    sit_home: '/savings-tracker',
    sit_targets: '/savings-tracker/targets',
    sit_initiative: '/savings-tracker/initiatives/:initiativeId',
    sit_initiatives: '/savings-tracker/initiatives',
}

/**
 * The pages that link to the URLS
 */
export const sitRouting: JSX.Element[] = [
    <R key={k()} w={sit} path={r.sit_targets} component={TargetTable}/>,
    <R key={k()} w={sit} path={r.sit_initiative} component={InitiativeForm}/>,
    <R key={k()} w={sit} path={r.sit_initiatives} component={InitiativeTable}/>,
    <R key={k()} w={sit} path={r.sit_home} component={SitHomePage}/>,
];

export const sitRoutes = r;
