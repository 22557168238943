import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export const INITIATIVE_COLUMNS: Array<ColSpec> = [
    {cls: '', txt: 'Title'},
    {cls: '', txt: 'Owner'},
    {cls: '', txt: 'Category'},
    {cls: '', txt: 'Lever group'},
    {cls: '', txt: 'DOI Stage'},
    {cls: '', txt: 'Cost Savings'},
    {cls: '', txt: 'Cost Avoidance'},
    {cls: '', txt: 'Last Modified'},
    {cls: '', txt: 'Modified By'},
]
