import ListItem from "@mui/material/ListItem";
import React from "react";
import {List, ListItemIcon, ListItemText, Paper} from "@mui/material";
import {useStores} from "../../../../stores";
import {CATEGORIZATION_STATUS_LIST} from "../../../../services/classes/JobClasses";
import {ArrowRight} from "@mui/icons-material";

export const DevopsCategorizationStateDiagram: React.FC = () => {
    const {categorizationReviewStore} = useStores();
    const statusDelegate = categorizationReviewStore.statusDelegate;
    const job = statusDelegate.status?.categorization_job.latest;

    return <Paper className="devops-categorization-state-diagram">
        <List>
            {CATEGORIZATION_STATUS_LIST.map((status, index) => {
                    const active = job?.current_status.status === status;
                    return <ListItem key={status}>
                        {active && <ListItemIcon><ArrowRight/></ListItemIcon>}
                        <ListItemText inset={!active} primary={status}/>
                    </ListItem>;
                }
            )}
        </List>
    </Paper>;
}
