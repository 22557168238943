export interface Country {
    id: number;
    name: string;
    code_a3: string;
}

export interface Currency {
    id: number;
    name: string;
    code: string;
}

export interface ConversionRate {
    id: number;
    source: Currency;
    target: Currency;
    created: Date;
}

export interface Cluster {
    id: number;
    title: string;
}

export interface Company {
    id: number;
    title: string;
    code: string;
    fiscal_year_start_month: number;
    cluster: Cluster;
    currency: Currency;
    country: Country;
}

export interface LeverGroup {
    id: number;
    title: string;
}

export interface Lever {
    id: number;
    title: string;
    description: string;
    group: LeverGroup;
}

export interface Category {
    id: number;
    level: number;
    l1: string;
    l2: string;
    l3: string;
    l4: string;
}

export interface KPIType {
    id: number;
    title: string;
    description: string;
}

export interface Target {
    id: number;
    title: string;
    target_year: number;
    fiscal_year: number;
    baseline_spend: number;
    saving_amount: number;
    avoidance_amount: number;
    fiscal_year_saving_amount: number;
    fiscal_year_avoidance_amount: number;
    start_date: Date;
    lever: Lever;
    owner: string;
    currency: Currency;
    approver: string;
    companies: Company[];
    categories: Category[];
}

export interface InitiativeFinancial {
    id: number;
    active: true,
    affected_months: number;
    baseline_spend: number;
    investment: number;
    severance: number;
    fiscal_year: number;
    saving_amount: number;
    avoidance_amount: number;
    fiscal_year_saving_amount: number;
    fiscal_year_avoidance_amount: number;
    carry_over_saving_amount: number;
    carry_over_avoidance_amount: number;
    initiative: number;
    category: Category;
    company: Company;
    currency: Currency;
    saving_method: 1,
    parent: null
}

export interface InitiativeStatus {
    id: number;
    initiative: number;
    doi_stage: number;
    due_date: Date;
    created: Date;
}

export interface InitiativeSuccessStep {
    id: number;
    initiative: number;
    achieved: boolean;
    created: Date;
    updated: Date;
}

export interface InitiativeKPI {
    id: number;
    initiative: number;
    type: KPIType
    parent: number;
    active: boolean;
    value: number;
    created: Date;
}

export class Initiative {
    id: number = 0;
    title: string = '';
    locked: boolean = false;
    confidential: boolean = false;
    nda_required: boolean = false;
    sustainability: boolean = false;
    start_date: Date = new Date();
    created: Date = new Date();
    updated: Date = new Date();
    owner: string = '';
    responsible: string = '';
    lever?: Lever;
    financial: InitiativeFinancial[] = [];
    doi: InitiativeStatus[] = [];
    success_step: InitiativeSuccessStep[] = [];
    kpi: InitiativeKPI[] = [];
    team: string[] = [];
    skus: string[] = [];
    initiativedescriptions: string = '';
}

// Cost Saving methods
export interface CSMStandard {
    old_price: number;
    new_price: number;
    quantity_per_month: number;
}

export interface CSMManufacturing {
    old_process_time: number;
    new_process_time: number;
    labor_rate: number;
    quantity_per_year: number;
}

export interface CSMOutsourcing {
    new_landed_price: number;
    marginal_costs: number;
    quantity_per_year: number;
}

export interface CSMDemandControl {
    spend_previous_period: number;
    spend_current_period: number;
}

export interface CSMCapex {
    first_offer_received: number;
    signed_offer: number;
}
