import './TaxonomyIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Button, CircularProgress, Grid} from "@mui/material";
import {TaxonomyIngestionStepper} from "./TaxonomyIngestionStepper";
import {generatePath, useHistory} from "react-router";
import {environment} from "../../env";
import {DirectUploadComponent} from "../data-management/upload/DirectUploadComponent";
import {TaxonomyUploadForm} from "./components/TaxonomyUploadForm";
import {ChevronRight} from "@mui/icons-material";
import {routes} from "../../routing/routes";

type Props = {
    viewer?: boolean
}
export const TaxonomyUploadPage: React.FC<Props> = observer(() => {
    const {taxonomyIngestionStore, p} = useStores();
    const history = useHistory();
    const isDemo = environment.isDemo;

    useEffect(() => {
        taxonomyIngestionStore.navigateToPage('taxonomy_upload')
    }, [taxonomyIngestionStore])

    const isLoading = taxonomyIngestionStore.taxonomyUploadIsLoading;
    const readyForNext = taxonomyIngestionStore.fileToUpload !== undefined && !isLoading;

    const handleNextClick = () => {
        if (isDemo) {
            setTimeout(() => {
                const dataFileId = new Date().getDate();
                taxonomyIngestionStore.setTaxonomyUploadIsLoading(false);
                taxonomyIngestionStore.setTaxonomyFileId(dataFileId)
                taxonomyIngestionStore.setErrorMsg('')
                // taxonomyIngestionStore.navigateToPage('taxonomy_mapping')
                history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataFileId)}))
            }, 2000);
            return;
        }
        taxonomyIngestionStore.uploadFileDirectly()
            .then(() => history.push(generatePath(routes.taxonomy_upload_mapping, {dataFileId: String(taxonomyIngestionStore.taxonomyFileId)})));
    };

    return <div className="taxonomy-ingestion data-upload-page">
        <TaxonomyIngestionStepper defaultPage={"taxonomy_upload"}></TaxonomyIngestionStepper>

        <Grid container justifyContent="center" className="taxonomy-ingestion-page-content">
            <Grid item xs={12} sm={9} md={6} lg={5} xl={4}>
                {taxonomyIngestionStore.errorMsg &&
                    <Alert severity="error">{taxonomyIngestionStore.errorMsg}</Alert>
                }

                <DirectUploadComponent
                    onSelectFile={(file) => taxonomyIngestionStore.setFileToUpload(file)}
                    file={taxonomyIngestionStore.fileToUpload}
                    isBusy={taxonomyIngestionStore.uploadIsBusy}
                />

                <TaxonomyUploadForm/>

                <Grid container justifyContent="center" className="action-button-container">
                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={!readyForNext}
                            endIcon={isLoading ? <CircularProgress size={20}/> : <ChevronRight/>}
                            onClick={() => handleNextClick()}>
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
});
