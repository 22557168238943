import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {CategorizationReviewStore} from "./CategorizationReviewStore";
import AuthStore from "../AuthStore";
import {makeAutoObservable, reaction} from "mobx";
import {AxoisRequestManager} from "../managers/RequestManager";
import {from, tap} from "rxjs";
import {CategorizationStatus} from "../../services/classes/CategorizationClasses";
import {getStatusIsFinal} from "../../services/classes/JobClasses";

/**
 * This class is responsible for the communication with the backend regarding the status of the categorization
 */
export class CategorizationStatusDelegate {
    timer?: NodeJS.Timeout;

    /**
     * Request for checking of the review screen is available
     * Note: This endpoint should be accessible by non-super users as well
     */
    readonly statusRequestManager = new AxoisRequestManager<{ databagId: number }, CategorizationStatus>(
        ({databagId}) => from(this.api.getCategorizationStatus(databagId)).pipe(tap(resp => {
            const job = resp.data.categorization_job.latest;
            if (job) {
                if (!getStatusIsFinal(job.current_status.status)) {
                    this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('ai-busy')
                }
            }
        }))
    );

    constructor(
        private api: MithraMaterializedApi,
        private categorizationReviewStore: CategorizationReviewStore,
        private auth: AuthStore,
    ) {
        makeAutoObservable(this)
        reaction(() => this.categorizationReviewStore.bagId, () => {
            this.stopCheckingStatus()
            this.checkStatus();
        })
    }

    get status(): CategorizationStatus | undefined {
        return this.statusRequestManager.result;
    }

    get isCategorizationApprovalBusy(): boolean {
        if (!this.status) return false;
        return this.status.approval.n_busy_requests > 0;
    }

    get categorizationJobId() {
        return this.status?.categorization_job.latest?.id;
    }

    get categorizationJobStatus() {
        return this.status?.categorization_job.latest?.current_status.status
    }

    get latestRevisionId(): number | null {
        return this.status?.categorization_job.latest?.current_revision_id || null;
    }

    get latestRevisionIsRejected(): boolean {
        return Boolean(this.status?.categorization_job.latest?.current_revision_is_rejected);
    }

    get latestRevisionIsNotRejected(): boolean {
        return this.status?.categorization_job.latest?.current_revision_is_rejected === false;
    }

    startCheckingStatus() {
        clearInterval(this.timer);
        this.timer = setInterval(() => this.checkStatus(), 1000 * 30);
        this.checkStatus();
    }

    stopCheckingStatus() {
        clearInterval(this.timer);
    }

    checkStatus() {
        if (this.categorizationReviewStore.bagId > 0) {
            this.statusRequestManager.request({databagId: this.categorizationReviewStore.bagId})
        } else {
            this.statusRequestManager.cleanup()
        }
    }
}