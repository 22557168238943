import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, Dialog, Grid, Tab, Tabs} from "@mui/material";
import {ChevronLeft, CloudDone, RedoRounded} from "@mui/icons-material";
import {generatePath, useHistory, useRouteMatch} from "react-router";
import {JobRouteMatch, routes} from "../../routing/routes";
import {ModalDeleteCategory} from "./modals/ModalDeleteCategory";
import {ModalMergeCategory} from "./modals/ModalMergeCategory";
import {ModalRenameCategory} from "./modals/ModalRenameCategory";
import {ModalModifyCategory} from "./modals/ModalModifyCategory";
import {ModalMoveCategory} from "./modals/ModalMoveCategory";
import {TaxonomyHealthCheckTable} from "./TaxonomyHealthCheckTable";
import {generateGotoTaxonomyBuilderPath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {environment} from "../../env";

export const TaxonomyHealthCheckPage: React.FC = observer(() => {
    const {bagStore, taxonomyManagerStore, taxonomyHealthCheckStore, p} = useStores();
    const bagId = bagStore.bagId;
    const history = useHistory();
    const match = useRouteMatch<JobRouteMatch>();

    const taxonomyId = taxonomyManagerStore.taxonomy?.id
    useEffect(() => {
        if (!taxonomyId) {
            console.warn("No taxonomy id found, is that okay???")
            // taxonomyHealthCheckStore.resetHealthCheck()
            return
        }
        console.log("taxonomyIdf", taxonomyId)

        taxonomyHealthCheckStore.getTaxonomyHealthCheck(taxonomyId).then()
    }, [taxonomyHealthCheckStore, taxonomyId]);

    // Define state variables to track the number of data in each tab
    const [labelFormatsCount, setLabelFormatsCount] = useState(0);
    const [prefixOverlapsCount, setPrefixOverlapsCount] = useState(0);
    const [categoryOverlapsCount, setCategoryOverlapsCount] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        taxonomyHealthCheckStore.taxonomyTableController.setFilter(newValue);
    };

    function gotoTaxonomyBuilder() {
        if (environment.package === 'cleanup') {
            if ('taxonomyId' in match?.params) {
                history.push(generatePath(routes.taxonomy_builder,
                    {id: bagId, taxonomyId: Number(match.params['taxonomyId'])}
                ))
            } else {
                history.push(generatePath(routes.taxonomy_builder, {id: bagId,}))
            }
        } else {
            history.push(generatePath(routes.merge_x_taxonomy_builder, {
                id: bagId,
            }))
        }
    }

    return <KoiFacade
        title="Taxonomy Health"
        subTitle={taxonomyManagerStore.taxonomy?.name}
        onBack={() => history.push(generateGotoTaxonomyBuilderPath(bagId))}
        className="taxonomy-health-check-page taxonomy-editor-page"
    >
        <>
            <div className='taxonomy-health-checker-page'>
                <Dialog
                    open={
                        taxonomyHealthCheckStore.isDeleteCategoryModalOpen
                        || taxonomyHealthCheckStore.isMoveCategoryModalOpen
                        || taxonomyHealthCheckStore.isMergeCategoryModalOpen
                        || taxonomyHealthCheckStore.isRenameCategoryModalOpen
                        || taxonomyHealthCheckStore.isModifyCategoryModalOpen
                    }
                    onClose={() => {
                    }}
                    aria-labelledby={"taxonomy-editor-modal-title"}
                    maxWidth="lg"
                >
                    {taxonomyHealthCheckStore.isDeleteCategoryModalOpen && <ModalDeleteCategory/>}
                    {taxonomyHealthCheckStore.isMoveCategoryModalOpen && <ModalMoveCategory/>}
                    {taxonomyHealthCheckStore.isMergeCategoryModalOpen && <ModalMergeCategory/>}
                    {taxonomyHealthCheckStore.isRenameCategoryModalOpen && <ModalRenameCategory/>}
                    {taxonomyHealthCheckStore.isModifyCategoryModalOpen && <ModalModifyCategory/>}
                </Dialog>

                {!environment.isDemo && <div className='taxonomy-health-checker-toolbar'>
                    <div>
                        <Button
                            className="health-btns"
                            color="primary"
                            variant="outlined"
                            startIcon={<ChevronLeft/>}
                            onClick={() => gotoTaxonomyBuilder()}>
                            Back
                        </Button>
                    </div>

                    <div>
                        <Button
                            className="health-btns"
                            color="secondary"
                            variant="outlined"
                            startIcon={<CloudDone/>}
                            disabled={taxonomyHealthCheckStore.disableApply}
                            onClick={() => {
                                taxonomyHealthCheckStore.setDisableApply(true)
                                taxonomyHealthCheckStore.setApplyButtonText("Apply is in progress...")
                                const taxonomyHealthCheckId = taxonomyHealthCheckStore.taxonomyHealthCheckApiResponse?.id
                                if (!taxonomyHealthCheckId) {
                                    return;
                                }
                                taxonomyHealthCheckStore.applyTaxonomyHealthCheck(taxonomyHealthCheckId).then(() => {
                                    taxonomyHealthCheckStore.setDisableApply(false)
                                    taxonomyHealthCheckStore.setApplyButtonText("Apply")
                                    taxonomyManagerStore.overwriteTaxonomyHealthCheckResult(false)
                                    if (taxonomyId) {
                                        taxonomyManagerStore._requestTaxonomy(taxonomyId)
                                    }
                                    gotoTaxonomyBuilder()
                                }).catch(() => {
                                    taxonomyHealthCheckStore.setDisableApply(false)
                                    taxonomyHealthCheckStore.setApplyButtonText("Apply")
                                })
                            }}>
                            {taxonomyHealthCheckStore.applyButtonText}
                        </Button>

                        <Button
                            className="rerun-button health-btns"
                            color="secondary"
                            variant="outlined"
                            startIcon={<RedoRounded/>}
                            disabled={true}
                            onClick={() => {
                                console.log("rerun the pipeline!")
                                let taxonomyId = bagStore.bag?.taxonomy
                                if (match.params['taxonomyId']) {
                                    taxonomyId = match.params['taxonomyId'];
                                }
                                if (p.p.hardcodedTaxonomySuggestionId) {
                                    taxonomyId = p.p.hardcodedTaxonomySuggestionId;
                                }
                                if (taxonomyId) {
                                    taxonomyHealthCheckStore.getTaxonomyHealthCheck(taxonomyId)
                                }
                                console.log("taxonomyId", taxonomyId)
                                if (taxonomyId) {
                                    taxonomyHealthCheckStore.createNewTaxonomyHealthCheck(taxonomyId).then(() => {
                                        // We have successfully created a new taxonomy health check
                                        taxonomyManagerStore.overwriteTaxonomyHealthCheckResult(true)
                                        if (taxonomyId != null) {
                                            taxonomyManagerStore._requestTaxonomy(taxonomyId)
                                        }
                                        if (taxonomyId != null) {
                                            taxonomyHealthCheckStore.getTaxonomyHealthCheck(taxonomyId)
                                        }
                                    })
                                }
                            }}>
                            Rerun Health Check Pipeline
                        </Button>
                    </div>
                </div>}

                <div className='taxonomy-health-checker-filters'>
                    <Tabs value={taxonomyHealthCheckStore.taxonomyTableController.filter} onChange={handleChange}
                          aria-label="Filter tabs">
                        <Tab label={`All`} value={0}/>
                        <Tab
                            label={`LABEL FORMATS (${taxonomyHealthCheckStore.suggestionKpis.numberOfLabelFormatting})`}
                            value={1}/>
                        <Tab
                            label={`PREFIX OVERLAPS (${taxonomyHealthCheckStore.suggestionKpis.numberOfPrefixOverlaps})`}
                            value={2}/>
                        <Tab
                            label={`CATEGORY OVERLAPS (${taxonomyHealthCheckStore.suggestionKpis.numberOfCategoryOverlap})`}
                            value={3}/>
                        {/* <Tab label={`TO RENAME (${taxonomyHealthCheckStore.suggestionKpis.numberOfRenames})`} value={4} /> */}
                    </Tabs>
                </div>

                <Grid container className='taxonomy-health-checker-main-container'>
                    <Grid item xs={12}>
                        <section className='taxonomy-container'>
                            {taxonomyHealthCheckStore.taxonomyTableController.data && (
                                // Display content based on the selected tab
                                // For example, you can conditionally render different tables based on the tabValue
                                <div>
                                    <TaxonomyHealthCheckTable columns={taxonomyHealthCheckStore.HEALTH_CHECK_COLUMNS}/>
                                </div>
                            )}
                        </section>
                    </Grid>
                </Grid>
                {!taxonomyHealthCheckStore.taxonomyTableController.data && (
                    <div className="loading-mid-page-container">
                        <Grid item className="loading-mid-page">
                            <LoadingSpinnerPie/>
                        </Grid>
                    </div>
                )}
            </div>
        </>
    </KoiFacade>
});

