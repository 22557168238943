import "./ContractInsightsPage.scss";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../../routing/routes";
import {generateHomePath} from "../../../routing/routing.utils";
import React, {useEffect} from "react";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {ContractInsightsComponent} from "../components/ContractInsightsComponent";
import {ContractUploadComponent} from "../components/ContractUploadComponent";
import {ModalContractDetails} from "../modals/ModalContractDetails";
import {ModalContractEdit} from "../modals/ModalContractEdit";
import {environment} from "../../../env";

type Props = {}
export const ContractInsightsPage: React.FC<Props> = observer(() => {
    const {rootAppStore, contractInsightsStore, p} = useStores();

    const routeMatch = useRouteMatch<JobRouteMatch>();

    const isDemo = environment.isDemo;

    useEffect(() => {
        if(!isDemo && p.p.useBeDemoContractData) {
            contractInsightsStore.contractsDemoDataManager.request()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <KoiFacade
        title="Contract Insights"
        intro={<></>}
        back={generateHomePath(routeMatch, routeMatch.params, rootAppStore.app)}
        className="contract-insights-page">

        <ModalContractDetails isOpen={contractInsightsStore.isModalContractsDetailsOpen}/>
        <ModalContractEdit isOpen={contractInsightsStore.isModalEditContractsOpen}/>

        {!isDemo && contractInsightsStore.contractsDemoDataManager.busy
            ? <></> // Loading screen missing
            : undefined
        }
        {isDemo &&
            !(contractInsightsStore.contracts && contractInsightsStore.contracts?.length > 0) &&
            <ContractUploadComponent/>
        }

        {(contractInsightsStore.contracts && contractInsightsStore.contracts?.length > 0) &&
            <ContractInsightsComponent/>
        }
    </KoiFacade>
});
