import './AiBusyComponent.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Button, Grid, Typography} from "@mui/material";
import {AdminPanelSettings, ChevronLeft} from "@mui/icons-material";
import {getStatusIsFinal} from "../../../../services/classes/JobClasses";
import {
    DevopsCategorizationStateDiagram
} from "../devops-categorization-state-diagram/DevopsCategorizationStateDiagram";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../../../routing/routes";
import {useHistory} from "react-router";
import {generateHomePath} from "../../../../routing/routing.utils";
import {RocketImage} from "../../../../components/icons/images/RocketImage";

export const AiBusyComponent: React.FC = observer(() => {
    const {rootAppStore, bagStore, categorizationReviewStore, authStore} = useStores();
    const bagId = bagStore.bagId;

    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();

    useEffect(() => {
        categorizationReviewStore.statusDelegate.startCheckingStatus();
    }, [categorizationReviewStore]);
    const status = categorizationReviewStore.statusDelegate.status;
    const job = status?.categorization_job.latest;
    const statusIsFinal = job && getStatusIsFinal(job.current_status.status);
    useEffect(() => {
        if (statusIsFinal) {
            categorizationReviewStore.statusDelegate.stopCheckingStatus();
        }
    }, [categorizationReviewStore, statusIsFinal]);

    return <div className="ai-busy-component">
        <Grid container alignItems="center" direction="column">
            <Grid item component="section">
                <Typography variant="h4" className="text-center header">
                    Categorizing
                </Typography>
                <Typography variant="body2" className="text-center">
                    Your AI model is running, please come back later &hellip;
                </Typography>
            </Grid>
            <Grid item component="section">
                {authStore.isMithraStaffGroup
                    ? <>
                        <a href={job?.gcp_vertex_link ? job.gcp_vertex_link : undefined} target="_blank" rel="noreferrer">
                            <Button variant="outlined" startIcon={<AdminPanelSettings/>}>
                                Open Vertex AI
                            </Button>
                        </a><br/>
                        <a href={job?.gcp_cloudrun_link ? job.gcp_cloudrun_link : undefined} target="_blank" rel="noreferrer">
                            <Button variant="outlined" startIcon={<AdminPanelSettings/>}>
                                Open Run Jobs
                            </Button>
                        </a><br/>

                        <Button variant="outlined" startIcon={<AdminPanelSettings/>} onClick={() => {
                            categorizationReviewStore.statusDelegate.stopCheckingStatus();
                            categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('review');
                        }}>
                            Go to review page
                        </Button><br/>

                        <DevopsCategorizationStateDiagram/>
                    </>
                    : <RocketImage/>
                }
            </Grid>
            <Grid item className="action-btn-container" component="section">
                <Button
                    id="continue-categorization-btn"
                    color="primary"
                    variant="outlined"
                    startIcon={<ChevronLeft/>}
                    onClick={() => history.push(generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app))}
                >
                    Back
                </Button>
            </Grid>
        </Grid>
    </div>
});
