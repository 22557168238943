import {EChartContext, EChartContextType} from "./EChartProvider";
import {CallbackDataParams, EChartsType} from "echarts/types/dist/shared";
import React, {useContext, useEffect, useState} from "react";

type Props<D extends CallbackDataParams = any> = {
    /**
     * @see https://echarts.apache.org/handbook/en/concepts/chart-size
     */
    initChart: (echart: EChartContextType, root: HTMLElement, data: any[]) => EChartsType
    /**
     * @see https://echarts.apache.org/handbook/en/how-to/data/dynamic-data
     */
    updateChart?: (chart: EChartsType, data: any[]) => void
    /**
     * Data updates are triggered either via mobx or react
     */
    data: any[]
    /**
     * @see https://echarts.apache.org/handbook/en/concepts/event
     */
    onClick?: (params: D) => void

    /**
     * Enable hot-reloading of the chart on each update by setting `alwaysRerender={() => {}}`
     * Useful only for debugging purposes, the updateChart will not work when this is set
     */
    alwaysRerender?: () => void
}

/**
 * Wrapper class for simple Echarts
 *
 * Contains boiler template code to simplify echart integration
 *
 */
export const MithraEchart: React.FC<Props> = ({initChart, updateChart, data, onClick, alwaysRerender}) => {
    // console.log('MithraEchart: tick')

    // Get the echart context, after initialization of the EChartProvider
    const echart = useContext(EChartContext);

    // Track the root element that is mounted by React
    const ref = React.useRef<HTMLDivElement>(null);

    // Track the echart instance that is controlling the graph
    const [chart, setChart] = useState<EChartsType | null>(null);

    if (chart && onClick) {
        // On each render of react the click event listener is re-created
        // We have to ensure the hooks are updated here as well
        chart.off('click')
        chart.on('click', onClick)
    }

    useEffect(() => {
        if (!alwaysRerender) return;

        console.debug('MithraEchart: alwaysRerender')
        if (chart) {
            chart.off('click')
            chart.dispose()
            setChart(null)
        }
    }, [alwaysRerender]) // Trigger every time the function is updated

    useEffect(() => {
        if (!ref.current || chart) return;

        // When the root element is mounted we initialize the echart instance (once)
        // console.log('MyEchartBarChart: initialize')
        const newChart = initChart(echart, ref.current, data);
        setChart(newChart);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current, chart]);

    useEffect(() => {
        if (!data || !chart) return;

        // Every time the data is updated, we trigger a update of the chart
        // console.log('MyEchartBarChart: update')
        if(updateChart) updateChart(chart, data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        // no-op
        return () => {
            // When the component in unmounted we dispose the echart instance as well
            // console.log('MyEchartBarChart: on unmount')
            if (chart) {
                chart.off('click')
                chart.dispose()
                setChart(null)
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <div ref={ref}/>
}
