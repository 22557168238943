const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
export type CurrencyAbbreviation = { symbol: string; value: number };
const ABBREVIATIONS: CurrencyAbbreviation[] = [
    {value: 1, symbol: ""},
    {value: 1e3, symbol: "k"},
    {value: 1e6, symbol: "M"},
    {value: 1e9, symbol: "B"},
];
type Formatted = [number, string];
export const MILLION_ABBREVIATION = ABBREVIATIONS[2]
export const THOUSAND_ABBREVIATION = ABBREVIATIONS[1]
export const NO_ABBREVIATION = ABBREVIATIONS[0];

export function findAbbreviation(num: number): CurrencyAbbreviation {
    const match = ABBREVIATIONS.slice().reverse().find(item => num >= item.value)
    if (!match) {
        // Could be negative
        return ABBREVIATIONS[0];
    }
    return match;
}

export function findAbbreviationOfGroup(numbers: number[]): CurrencyAbbreviation {
    // Find the biggest abbreviation that matches any of the numbers
    if (numbers.length === 0) throw Error()
    const biggest = numbers.sort((a, b) => b - a)[0]
    return findAbbreviation(biggest)
}

function nFormatter(num: number, abbreviation: CurrencyAbbreviation, digits: number): Formatted {
    const value = abbreviation ? num / abbreviation.value : num;

    let valueRightDigits: number
    if (value === 0) {
        valueRightDigits = digits
    } else if (value >= 1) {
        const valueLeftDigits = Math.ceil(Math.log10(value))
        valueRightDigits = digits - valueLeftDigits
    } else {
        const valueLeftDigits = Math.ceil(Math.log10(value))
        valueRightDigits = digits - valueLeftDigits - 1
    }
    const valueRounding = Math.pow(10, valueRightDigits);
    const valueStripped = Math.round(value * valueRounding) / valueRounding;
    const postFix = abbreviation ? abbreviation.symbol : '';
    return [valueStripped, postFix];
}

export const testNFormatter = nFormatter;

export function toCurrency(
    currencySymbol: string,
    v: number,
    nDigits = 3,
    abbreviation?: CurrencyAbbreviation,
    hideAlmostZero?: boolean,
): string {
    const neg = v < 0;
    const absV = Math.abs(v);
    if (!abbreviation) abbreviation = findAbbreviation(absV)
    let [valueStripped, postFix] = nFormatter(absV, abbreviation, nDigits);
    let preFix = '';
    if (hideAlmostZero) {
        // console.log('valueStripped=', valueStripped, nDigits)
        const minimalValue = Math.pow(10, -nDigits + 1);
        if (valueStripped < minimalValue && valueStripped > 0) {
            valueStripped = 0
            preFix = '~'
        }
    }
    if (currencySymbol !== '') {
        currencySymbol += ' '
    }
    return currencySymbol
        + (neg && preFix ? `${preFix} ` : preFix)
        + (neg ? '-' : '')
        + valueStripped.toFixed(nDigits).replace(rx, "$1")
        + postFix;
}

export function toCurrencyWithP(
    v: number,
    currencyFormat: Intl.NumberFormat,
    currencySymbol: string,
    vP: number,
    nDigits = 3,
) {
    const [str,] = _toCurrencyWithP(v, currencyFormat, currencySymbol, vP, nDigits);
    return str;
}

export function toNumberWithP(v: number, vP: number, nDigits = 3) {
    const neg = v < 0;
    const abbreviation = findAbbreviation(v)
    const [valueStripped, postFix] = nFormatter(v, abbreviation, nDigits);
    let str = (neg ? '-' : '')
        + valueStripped.toFixed(nDigits).replace(rx, "$1")
        + postFix;
    if (vP !== undefined) {
        str += ` (${Math.round(vP * 100)}%)`;
    }
    return str;
}

export function _toCurrencyWithP(
    v: number,
    currencyFormat: Intl.NumberFormat,
    currencySymbol: string,
    vP: number | undefined,
    nDigits = 3,
    abbreviation?: CurrencyAbbreviation,
    hideAlmostZero?: boolean,
) {
    let str;
    const vanillaStr = currencyFormat.format(v);
    if (nDigits === undefined) {
        str = vanillaStr;
    } else {
        str = toCurrency(currencySymbol, v, nDigits, abbreviation, hideAlmostZero);
    }
    if (vP !== undefined) {
        str += ` (${Math.round(vP * 100)}%)`;
    }
    return [str, vanillaStr];
}
