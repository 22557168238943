import React, {ChangeEvent} from 'react';
import Button from '@mui/material/Button';
import {UploadFile} from "@mui/icons-material";
import {Grid, Typography} from "@mui/material";
import {UPLOAD_MIME_TYPES} from "./DataUploadController";

type Props = {
    onSelectFile: (file: File) => void
    file?: File
    isBusy: boolean
}
export const DirectUploadComponent: React.FC<Props> = ({onSelectFile, file, isBusy}) => {
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        onSelectFile(file)
    };

    return <Grid container alignItems="center" className="direct-upload-component">
        <Grid item>
            <Button
                disabled={isBusy}
                startIcon={<UploadFile/>}
                variant="outlined"
                component="label"
                className="upload-file-button"
            >
                Upload
                <input
                    hidden
                    accept={UPLOAD_MIME_TYPES}
                    type="file"
                    onChange={handleFileChange}
                />
            </Button>
        </Grid>
        <Grid item style={{flex: 1, marginLeft: '1em'}}>
            <Typography variant="caption">
                {file
                    ? <>Filename: <span className="filename">{file.name}</span></>
                    : 'Select a file to upload'
                }
            </Typography>
        </Grid>
    </Grid>
}
