import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import './BubbleMap.scss';
import {AdvancedGeoData} from "../../../services/ApiTypes";
import {countryToPoint} from "./countries";
import objectHash from "object-hash";
import {observer} from "mobx-react-lite";

export const BubbleMap: React.FC<{
    data: AdvancedGeoData[],
    selectedCountries?: string[],
    countryFilterChanged?: (country?: string[]) => void,
}> = observer(({data, selectedCountries, countryFilterChanged}) => {
    const width = 730;
    const height = 435;

    const svgRef = useRef<SVGSVGElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const currency = d3.formatLocale({
        "decimal": ".",
        "thousands": ",",
        "grouping": [3],
        "currency": ["€", ""],
    })
    const format = currency.format("$,d");

    useEffect(() => {
        if (!svgRef.current) {
            return;
        }
        const datahash = objectHash(data);
        if (svgRef.current.dataset['datahash'] === datahash && svgRef.current.dataset['selected'] === selectedCountries?.toString()) {
            // Don't re-render if the input data is already rendered
            return;
        }
        svgRef.current.dataset['datahash'] = datahash;
        svgRef.current.dataset['selected'] = selectedCountries?.toString();

        const svg = d3.select(svgRef.current);
        const tooltip = d3.select(tooltipRef.current);
        svg.html("");
        svg.attr('width', width).attr('height', height);

        const unselect = () => countryFilterChanged?.(undefined);

        if (svg.select(".background").empty()) {
            // Draw background
            svg.append("rect")
                .attr("class", "background")
                .attr("width", "100%")
                .attr("height", "100%")
                .attr("fill", "grey")
                .attr("opacity", 0.3)
                .on("click", unselect);
        }

        const projection = d3
            .geoMercator()
            .center([20, 20])
            .scale(100)
            .translate([width / 2, height / 2]);

        d3.json("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson")
            .then((dataGeo) => ready(dataGeo));

        function ready(dataGeo) {
            const pathBuilder = (feature) => d3.geoPath(projection)(feature);

            if (svg.select(".map").empty()) {
                // Draw the Map
                svg
                    .append("g")
                    .attr("class", "map")
                    .selectAll()
                    .data(dataGeo.features)
                    .enter()
                    .append('path')
                    .attr("d", (feature) => pathBuilder(feature))
                    .attr("fill", "white")
                    .attr("opacity", 0.3);
            }

            // Find min and max
            const extentResult: [number, number] | [undefined, undefined] = d3.extent(data, (d) => +d.spend);
            const extremes: [number, number] = extentResult.some((x) => x === undefined)
                ? [0, 0] : extentResult as [number, number];

            // Create scale
            const radiusScale = d3.scaleSqrt().domain(extremes).range([3, 30]);
            const color = d3.scaleLinear<string>().domain(extremes).range(["blue", "red"]);

            tooltip
                .style("opacity", 0);

            function mouseover(this, event, d: AdvancedGeoData) {
                tooltip.style("opacity", 1);
                d3.select(this).attr('fill-opacity', 0.8);
            }

            function mousemove(this, event, d: AdvancedGeoData) {
                tooltip.text(d.country + ": " + format(d.spend))
                    .style("left", event.pageX + "px")
                    .style("top", (event.pageY + 20) + "px");
            }

            function mouseleave(this, event, d: AdvancedGeoData) {
                tooltip.style("opacity", 0);
                d3.select(this).attr('fill-opacity', selectedCountries?.includes(d.country) ? 1 : 0.2);
            }

            function click(this, event, d: AdvancedGeoData) {
                if (!selectedCountries) {
                    selectedCountries = [d.country];
                } else if (!selectedCountries.includes(d.country)) {
                    selectedCountries.push(d.country);
                } else {
                    selectedCountries = selectedCountries.filter(country => country !== d.country);
                }

                countryFilterChanged?.(selectedCountries);
            }

            svg.select(".bubbles").remove();

            // Draw bubbles
            svg
                .append("g")
                .attr("class", "bubbles")
                .selectAll("circle")
                .data(data)
                .enter()
                .append("circle")
                .attr("cx", (d) => {
                    const coordinates: [number, number] | null = projection(countryToPoint(d.country))
                    return coordinates ? coordinates[0] : 0;
                })
                .attr("cy", (d) => {
                    const coordinates: [number, number] | null = projection(countryToPoint(d.country))
                    return coordinates ? coordinates[1] : 0;
                })
                .attr("r", d => radiusScale(d.spend))
                .attr("fill", d => color(d.spend))
                .attr("fill-opacity", d => selectedCountries?.includes(d.country) ? 1 : 0.2)
                .attr("style", "cursor: pointer;")
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
                .on("click", click);
        }
    }, [selectedCountries, countryFilterChanged, data, format]);

    return <div className="bubble-map-container">
        <svg
            className="bubble-map"
            ref={svgRef}
            viewBox={`0 0 ${width} ${height}`}/>
        <div className='tooltip' ref={tooltipRef}/>
    </div>;
});
