// Taken from https://www.npmjs.com/package/echarts-for-react

import React, {useState} from 'react';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';

// @formatter:off
import {BarChart, /**
     * ScatterChart,
     * RadarChart,
     * MapChart,
     * TreeChart,
     * TreemapChart,
     * GraphChart,
     * GaugeChart,
     * FunnelChart,
     * ParallelChart,
     * SankeyChart,
     * BoxplotChart,
     * CandlestickChart,
     * EffectScatterChart,
     * HeatmapChart,
     * PictorialBarChart,
     * ThemeRiverChart,
     * SunburstChart,
     * CustomChart,
     */
    LineChart, LinesChart, PieChart, } from 'echarts/charts';
import {DatasetComponent, /**
     * GridSimpleComponent,
     * PolarComponent,
     * RadarComponent,
     * GeoComponent,
     * SingleAxisComponent,
     * ParallelComponent,
     * CalendarComponent,
     * GraphicComponent,
     * ToolboxComponent,
     * AxisPointerComponent,
     * BrushComponent,
     * TimelineComponent,
     * MarkPointComponent,
     * MarkLineComponent,
     * MarkAreaComponent,
     * LegendScrollComponent,
     * LegendPlainComponent,
     * DataZoomComponent,
     * DataZoomInsideComponent,
     * DataZoomSliderComponent,
     * VisualMapComponent,
     * VisualMapContinuousComponent,
     * VisualMapPiecewiseComponent,
     * AriaComponent,
     * TransformComponent,
     */
    GridComponent, LegendComponent, TitleComponent, TooltipComponent, } from 'echarts/components';
import {/**
     * CanvasRenderer,
     */
    SVGRenderer, } from 'echarts/renderers';
import EchartStyle from "./EchartStyle";
// @formatter:on

export type EChartContextType = typeof echarts;

/**
 * This context provides an initialized echarts instance
 */
export const EChartContext = React.createContext<EChartContextType>(null as any);

export const EChartProvider: React.FC = ({children}) => {
    const [initializedEcharts] = useState<EChartContextType>(() => {
        // console.log('EChartProvider: initializing echarts')

        // Register the required components
        echarts.use([
            LineChart,
            BarChart,
            PieChart,
            LinesChart,

            GridComponent,
            TooltipComponent,
            TitleComponent,
            LegendComponent,
            DatasetComponent,

            SVGRenderer,
        ]);
        echarts.registerTheme('mithra', EchartStyle)
        return echarts;
    })
    return <EChartContext.Provider value={initializedEcharts}>{children}</EChartContext.Provider>;
}
