import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {
    Button,
    Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TableCell, TablePagination,
    TableRow
} from "@mui/material";
import {useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import moment from "moment";
import {AccountCircleOutlined, Refresh, CalendarTodayOutlined, DatasetOutlined} from "@mui/icons-material";
import {MithraTable, MithraTableHeader} from "../../../components/data-table/MithraTable";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {PartCategorizationComponent} from "../../../components/categorization/PartCategorizationComponent";
import {
    fromFieldsCategoriesOrUncat,
} from "../../../services/ApiHelpers";
import {ApprovalStatusEnum} from "../../../services/classes/AiClasses";
import {generatePath, useHistory} from "react-router";

const HEADERS: MithraTableHeader[] = [
    {headerText: 'Supplier'},
    {headerText: 'Name'},
    {headerText: 'Amount'},
    {headerText: 'Description'},
    {headerText: 'Previous category', className: 'width-20'},
    {headerText: 'Requested category', className: 'width-20'},
]

export const ApprovalCategorizationPage: React.FC = observer(() => {
    const {approvalStore, bagStore} = useStores();
    const history = useHistory();
    const routeParams = useParams();
    const taxonomySize = bagStore.taxonomy_size;
    const [dialogFor, setDialogFor] = React.useState('');

    useEffect(() => {
        approvalStore.approvalItem.cleanup();
        approvalStore.categorizationPartList.cleanup();
        approvalStore.approvalItem.request({id: routeParams['approvalId']});
        approvalStore.categorizationPartList.request({
            id: routeParams['approvalId'],
            filters: approvalStore.categorizationPartListFilters.filters
        });
    }, [routeParams, approvalStore.approvalItem, approvalStore.categorizationPartList, approvalStore.categorizationPartListFilters])

    return <KoiFacade
            title="Request details"
            back={routes.approval}
            backClass={""}
            className="approval-page categorization-approval-page"
    >
        <Grid container className="approval-info-header">
            <Grid item xs={2}>
                <AccountCircleOutlined className="approval-avatar"/>
            </Grid>
            <Grid item xs={4} container direction="column">
                <Grid item>
                    Requester
                    <h3>{(approvalStore.approvalItem.result?.created_by.first_name ?? '') +
                            ' ' + (approvalStore.approvalItem.result?.created_by.last_name ?? '')}</h3>
                </Grid>
                <Grid item>
                    Notes
                    <div className="approval-notes">{approvalStore.approvalItem.result?.notes}</div>
                </Grid>
            </Grid>
            <Grid item xs={3} container direction="column">
                <Grid item className="approval-info-type-date">
                    <DatasetOutlined/>
                    <div>
                        Dataset
                        <h4>
                            {approvalStore.approvalItem.result?.baseline_bag?.name}
                        </h4>
                    </div>
                </Grid>
                <Grid item className="approval-info-type-date">
                    <Refresh/>
                    <div>
                        Type of change
                        <h4>
                            {approvalStore.approvalItem.result && ApprovalStore.showRequestType(approvalStore.approvalItem.result.request_type)}
                        </h4>
                    </div>
                </Grid>
                <Grid item className="approval-info-type-date">
                    <CalendarTodayOutlined/>
                    <div>
                        Date & Time
                        <h4>{approvalStore.approvalItem.result && moment(approvalStore.approvalItem.result.created_timestamp).format('YYYY-MM-DD hh:mm A')}</h4>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={3} container direction="column" alignItems="center">
                <Grid item>
                    <div>Status</div>
                    <Chip size="medium"
                          label={approvalStore.approvalItem.result && ApprovalStore.getApprovalStatusName(approvalStore.approvalItem.result.current_status.status)}
                          className={'approval-accept-reject-btn ' + (approvalStore.approvalItem.result && approvalStore.approvalItem.result?.current_status.status_name.toLowerCase())}/>
                </Grid>
                <Grid item>
                    {approvalStore.approvalItem.result?.current_status.status === ApprovalStatusEnum.PENDING &&
                            <>
                                <Button className="approval-accept-reject-btn accept"
                                        onClick={() => setDialogFor('Accept')}>
                                    Accept all
                                </Button>
                                <Button className="approval-accept-reject-btn reject"
                                        onClick={() => setDialogFor('Reject')}>
                                    Reject all
                                </Button>
                            </>}
                </Grid>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <h3>Change list</h3>
                <MithraTable
                        headers={HEADERS} loading={approvalStore.categorizationPartList.busy} className="mithra-table"
                        children={approvalStore.categorizationPartList.result?.results.map((p, i) => {
                            let prev_cat_ls = {p_l1: ''};
                            p.prev_cats?.forEach((item, index) => prev_cat_ls['p_l' + (index+1).toString()] = item);
                            return <TableRow key={i}>
                                <TableCell>{p.part__supplier__s_name}</TableCell>
                                <TableCell>{p.part__p_name}</TableCell>
                                <TableCell>
                                    <CurrencyComponent v={p.part__p_spend} nDigits={3}/>
                                </TableCell>
                                <TableCell>{p.part__p_description}</TableCell>
                                <TableCell>
                                    <PartCategorizationComponent
                                            cats={fromFieldsCategoriesOrUncat(prev_cat_ls, 'p', taxonomySize)}/>
                                </TableCell>
                                <TableCell>
                                    <PartCategorizationComponent
                                            cats={fromFieldsCategoriesOrUncat(p, 'category_', taxonomySize)}/>
                                </TableCell>
                            </TableRow>
                        })}
                        footer={
                            <TableRow>
                                <TablePagination
                                        count={approvalStore.categorizationPartList.result?.count ?? 0}
                                        rowsPerPage={parseInt(approvalStore.categorizationPartListFilters.filters.find(i => i[0] === 'page_size')?.at(1) ?? '10')}
                                        page={parseInt(approvalStore.categorizationPartListFilters.filters.find(i => i[0] === 'page')?.at(1) ?? '1') - 1}
                                        onPageChange={(event, page: number) => {
                                            approvalStore.setCategorizationPartListFilters([['page', (page + 1).toString()]]);
                                        }}
                                        onRowsPerPageChange={(event) => {
                                            approvalStore.setCategorizationPartListFilters([['page_size', (event.target.value).toString()]]);
                                            approvalStore.setCategorizationPartListFilters([['page', '1']]);
                                        }}
                                />
                            </TableRow>}/>
            </Grid>
        </Grid>
        {/* Dialog */}
        <Dialog open={dialogFor !== ''} aria-labelledby="dialog-title" aria-describedby="dialog-description"
                onClose={() => {
                    setDialogFor('');
                }}>
            <DialogTitle id="dialog-title">
                {dialogFor + ' request'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    Are you sure about {dialogFor === 'Accept' ? 'accepting' : 'rejecting'} change from&nbsp;
                    {(approvalStore.approvalItem.result?.created_by.first_name ?? '') +
                            ' ' + (approvalStore.approvalItem.result?.created_by.last_name ?? '')}
                    {dialogFor === 'Accept' &&
                            <>
                                <br/>
                                It could take several minutes to few hours to completely process a request.
                            </>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setDialogFor('');
                }}>cancel</Button>
                <Button className={dialogFor === 'Accept' ? 'bg-primary' : 'bg-danger'}
                        disabled={approvalStore.categorizationApply.busy}
                        onClick={() => {
                            approvalStore.categorizationApply.request({
                                approvalId: routeParams['approvalId'],
                                status: dialogFor === 'Accept' ? ApprovalStatusEnum.APPROVED : ApprovalStatusEnum.REJECTED
                            });
                            history.push(generatePath(routes.approval));
                        }}>
                    {dialogFor + ' request'}
                </Button>
            </DialogActions>
        </Dialog>
    </KoiFacade>
});
