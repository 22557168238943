import {environment} from "../../env";
import {Analytics, getAnalytics, logEvent, setUserId} from "firebase/analytics";
import {setUserProperties} from "@firebase/analytics";

/**
 * Steps for GA:
 * - Turn on Enhanced measurement
 * - Turn on Data Collection consents
 * - Define Custom Dimensions of Users
 * - Define Custom Dimensions of Events
 * - Disable email redact setting
 * - Add Customer Audience: is_mithra_user = "true", max duration
 */

/**
 * To be added as custom dimensions on Users in GA
 */
type UserProperties = {
    mithra_id: string
    email_domain: string
    is_mithra_user: 'true' | 'false'
    is_dennis_user: 'true' | 'false'
}

/**
 * To be added as custom dimensions in GA
 */
type GaEventName =
    'app_init'
    | 'filter'
    | 'opportunity'
    | 'export'
    | 'search'

export function analyticsLoginEvent(event: UserProperties) {
    const eventName = '_login'
    console.log(`Analytics ${eventName}${environment.isGA ? ' (GA)' : ''}`, event);
    if (environment.isGA) {
        const analytics = getAnalytics();
        const userId = event.mithra_id;
        setUserId(analytics, userId);
        setUserProperties(analytics, event);
    }
}

export function analyticsLogoutEvent() {
    const eventName = '_logout'
    console.log(`Analytics ${eventName}${environment.isGA ? ' (GA)' : ''}`);
    if (environment.isGA) {
        const analytics = getAnalytics();
        setUserId(analytics, null);
        setUserProperties(analytics, {});
    }
}

/**
 * Every time the user interacts with an opportunity
 * This is an important KPI to how valuable our solution is at the customer side
 */
export function analyticsOpportunityEvent(event: {
    type: 'ppv'
    key: string
    status: string
    expected_savings: number
    user: string
}) {
    _myLogEvent('opportunity', event);
}

/**
 * Every time the user downloads an export
 */
export function analyticsExportEvent(event: {
    export_type: 'taxonomy' | 'categorization'
    object_key: string
    user: string
}) {
    _myLogEvent('export', event);
}

/**
 * Every time the user interacts with a search bar
 * This helps us determine what the customer journey is and where they are looking for details
 */
export function analyticsSearchEvent(event: {
    search_term: string
    trigger: string
    search_type: string
}) {
    _myLogEvent('search', event);
}

/**
 * Every time the user interacts with a filter in one of the analysis views
 * This helps us determine what the customer journey is and where they are zooming in on details
 * As well it helps us track engagement with the page
 */
export function analyticsFilterEvent(event: {
    // TODO
}) {
    _myLogEvent('filter', event);
}

export function analyticsInitEvent(analytics: Analytics, event: {
    version: string
    customer: string
    hostname: string
}) {
    _myLogEvent( 'app_init', event);
}

function _myLogEvent(eventName: GaEventName, event: any, analytics?: Analytics) {
    console.log(`Analytics ${eventName}${environment.isGA ? ' (GA)' : ''}`, event);
    if (environment.isGA) {
        // Make sure we do not call getAnalytics() without initializing the app
        logEvent(analytics ?? getAnalytics(), eventName as any, event);
    }
}
