import React, {useEffect} from "react";
import {RouteWrapperProps} from "../../jobs/wrapper/RouteWrapperProps";
import {observer} from "mobx-react-lite";
import {HeaderWrapper} from "../../jobs/wrapper/HeaderWrapper";
import {MainComponent} from "../../components/main/MainComponent";

export const SitRouteWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    // const match = useRouteMatch();
    // const history = useHistory();

    useEffect(() => {
        // Some loading that has to be performed for the whole app
    }, [])

    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        {children}
    </MainComponent>
});
