import React from "react";
import './SitHomePage.scss';
import {Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import Button from "@mui/material/Button";
import {sitHomeController} from "./SitHomeController";

export const SitHomePage: React.FC = observer(() => {
    return <Grid container className="sit-home-page" justifyContent="center" alignItems="center">
        <Grid item>
            <Typography variant="h3" component="h1" className="message">
                Welcome SIT!<br/>
                {'' + sitHomeController.counter}
            </Typography>

            <Button onClick={() => sitHomeController.setCounter(sitHomeController.counter + 1)}>
                add 1
            </Button>
        </Grid>
    </Grid>
});
