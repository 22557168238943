import {makeAutoObservable} from "mobx";
import {CategorizationReviewStore} from "./CategorizationReviewStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {AxoisRequestManager} from "../managers/RequestManager";
import {from, tap} from "rxjs";

/**
 * This class is responsible for the communication with the backend regarding the AI jobs
 */
export class AiCategorizationJobDelegate {

    createJobRequestManager = new AxoisRequestManager<number, { id: number }>(
        databagId => from(this.matApi.createAiJob({
            src_data_bag: databagId,
            requested_task: 'categorize_all',
        })).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('ai-busy');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    createDummyRequestManager = new AxoisRequestManager<number, { id: number }>(
        databagId => from(this.matApi.createAiJob({
            src_data_bag: databagId,
            requested_task: 'categorize_dummy',
        })).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('ai-busy');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsCleanupRequestManager = new AxoisRequestManager<number, void>(
        databagId => from(this.matApi.http.post<void>(`/bag/${databagId}/devops_cleanup_categorization_results/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )

    /**
     * Future work: Maybe it makes sens to add the revision that we want to go live with.
     * Now we always go live with the latest revision only.
     */
    devopsGoLiveRequestManager = new AxoisRequestManager<number, void>(
        jobId => from(this.matApi.http.post<void>(`/ai_io/${jobId}/accept_review/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsRejectRevisionManager = new AxoisRequestManager<number, void>(
        jobId => from(this.matApi.http.post<void>(`/ai_io/${jobId}/reject_review/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsDestroyJobManager = new AxoisRequestManager<number, void>(
        jobId => from(this.matApi.http.post<void>(`/ai_io/${jobId}/clear_review/`)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsApplyCutoffManager = new AxoisRequestManager<[number, { cutoff_value: number, revision: number }], void>(
        ([jobId, data]) => from(this.matApi.http.patch<void>(`/ai_io/${jobId}/apply_cutoff/`, data)).pipe(
            tap(() => {
                this.categorizationReviewStore.reviewPageController.reviewPageStateController.setPage('welcome');
                this.closeRunAiModal();
                this.categorizationReviewStore.statusDelegate.startCheckingStatus();
            })
        ),
    )
    devopsSimulateCutoffManager = new AxoisRequestManager<{ cutoff_value: number, revision: number }, {
        suppliers: number,
        spend: number,
        parts: number
    }>(
        ({
             cutoff_value,
             revision
         }) => from(this.matApi.http.get(`/m_review/stats/live/${revision}/devops_simulate_cutoff/?cutoff_value=${cutoff_value}`)),
    )

    isRunAiModalOpen = false

    constructor(
        private matApi: MithraMaterializedApi,
        private categorizationReviewStore: CategorizationReviewStore,
    ) {
        makeAutoObservable(this)
    }

    openRunAiModal() {
        this.isRunAiModalOpen = true
    }

    closeRunAiModal() {
        this.isRunAiModalOpen = false
    }
}