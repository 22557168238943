import {makeAutoObservable} from "mobx";
import MithraDataIngestionApi, {canUploadFile} from "../services/MithraDataIngestionApi";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import ProfileStore from "./ProfileStore";
import {
    ClientArea,
    ClientColumn,
    MappingTableItemType,
    MithraArea,
    MithraColumn
} from "../pages/data-management/data-mapping/MappingTableTypes";
import {
    DataFrameSerializer,
    DataIngestionKpiSerializer,
    DataMappingResultTableSerializer,
    DataMappingSerializer,
    MithraColumnSerializer
} from '../services/ApiTypes';
import {v4 as uuid} from "uuid";
import {History} from "history";
import {match as Match} from "react-router";
import {DataFileRouteMatch, routes} from "../routing/routes";
import AuthStore from "./AuthStore";
import {
    DataIngestionFileStatus,
    DataIngestionFileStatusEnum,
    IngestionFile
} from "../services/classes/IngestionClasses";
import {AxoisRequestManager} from "./managers/RequestManager";
import {from} from "rxjs";
import {environment} from "../env";
import {uploadController} from "../pages/data-management/upload/DataUploadController";


type Page = 'dataset_upload'
    | 'data_mapping'
    | 'data_check'
    | 'data_finish'

type ResultTableRows = {
    bu__id: string | number
    bu__name: string
    s__id: string | number
    s__name: string
    s__city: string
    p__id: string | number
    p__name: string
    p__spend: string | number
    p__description: string
    p__context_1: string
    p__purchase_date: string
    p__quantity: string | number
    p__in_l1: string | number
    p__in_l2: string | number
    p__in_l3: string | number
}


export default class DataIngestionStore {
    readonly datasetListManager = new AxoisRequestManager<void, IngestionFile[]>(
        () => from(this.ingestionApi.getDatasetList())
    )

    /**
     * For Direct uploading
     */
    file: File | undefined

    /**
     * See this.datasetUploadIsBusy
     */
    datasetIsUploadingFile = false

    dataFile: IngestionFile | undefined
    dataFileNotFound: boolean | undefined
    datasetName: string = ''
    startingRow: number = 2
    headersRow: number | undefined = 1
    mergeTarget: number | undefined = undefined
    existingDatasets: string[] | undefined = undefined

    dataMapping: DataMappingSerializer[] | undefined
    dataMappingResultKpi: DataIngestionKpiSerializer | undefined
    dataMappingResultResponse: DataMappingResultTableSerializer | undefined

    mithraColumns: MithraColumnSerializer[] | undefined
    clientColumns: DataFrameSerializer | undefined
    allowedOperations
    clientAreaState: ClientArea = []
    mithraAreaState: MithraArea = []
    step = 0;
    page: Page | undefined = undefined;

    /**
     * Error message to be shown on top of the page
     */
    errorMsg = ''; // spgetify

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        public ingestionApi: MithraDataIngestionApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    async deleteIngestionFile(file: IngestionFile) {
        await this.ingestionApi.deleteIngestionFile(file.id);
        await new Promise<void>(resolve => this.datasetListManager.request().add(() => resolve()));
    }

    get datasetList() {
        return this.datasetListManager.result;
    }

    async uploadDroppedFile() {
        const gcpFile = uploadController.uploadedFile;
        if (!gcpFile) {
            console.warn('No file to upload')
            return;
        }

        this.datasetIsUploadingFile = true;
        try {
            const data = await this.ingestionApi.uploadDroppedDatasetFile(gcpFile, this.datasetName, this.startingRow, this.headersRow, this.mergeTarget)
            this.setErrorMsg('')
            this.setDataFile(data)

            try {
                // Do the first status check synchronously
                await this.doStatusCheck()
                this.startStatusCheck();
            } catch (error) {
                console.error(error);
            }
        } catch (error) {
            console.error(error);
            this.setErrorMsg(`Error uploading file (${String(error)})`)
        } finally {
            this.setDatasetUploadLoading(false);
        }
    }

    async uploadFileDirectly() {
        const file = this.file;
        if (!file) {
            console.warn('No file to upload')
            return
        }

        this.datasetIsUploadingFile = true;
        try {
            const data = await this.ingestionApi.uploadDatasetFileDirectly(file, this.datasetName, this.startingRow, this.headersRow, this.mergeTarget)
            this.setErrorMsg('')
            this.setDataFile(data)

            try {
                // Do the first status check synchronously
                await this.doStatusCheck()
                this.startStatusCheck();
            } catch (error) {
                console.error(error);
            }
        } catch (error) {
            console.error(error);
            this.setErrorMsg(`Error uploading file (${String(error)})`)
        } finally {
            this.setDatasetUploadLoading(false);
        }
    }

    /**
     * Start the data merge, and update the status.
     */
    startDataMerge() {
        if (!this.dataFile?.id) return Promise.reject('Data file id is not set')
        return this.ingestionApi.startDatasetMerge(this.dataFile.id)
            .then(() => this.doStatusCheck())
            .then(() => this.startStatusCheck())
    }

    /**
     * @private
     */
    statusCheckInterval?: NodeJS.Timeout;
    dataIngestionStatus?: DataIngestionFileStatus;

    stopStatusCheck(): void {
        if (this.statusCheckInterval) {
            clearInterval(this.statusCheckInterval);
            this.statusCheckInterval = undefined;
        }
    }

    startStatusCheck(): void {
        this.stopStatusCheck();
        this.statusCheckInterval = setInterval(() => this.doStatusCheck(), 5000);
    }

    doStatusCheck() {
        const dataFileId = this.dataFile?.id;
        if (!dataFileId) {
            console.warn('Cannot do status check, data file id is not set');
            this.dataIngestionStatus = undefined;
            this.stopStatusCheck();
            return;
        }
        return this.ingestionApi.getDataMergeStatus(dataFileId).then(resp => this.onDataIngestionStatusUpdate(resp.data));
    }

    get dataIngestionStatusEnum(): DataIngestionFileStatusEnum | undefined {
        return this.dataIngestionStatus?.status;
    }

    onDataIngestionStatusUpdate(status: DataIngestionFileStatus) {
        this.dataIngestionStatus = status;
        switch (status.status) {
            case 'user-configuring':
                // Navigation happens in DataUploadPage (TODO: de-spaghettify)
                this.stopStatusCheck()
                break;
            case 'ingest-completed':
                this.navigateToPage('data_finish')
                this.stopStatusCheck()
                break;
        }
    }

    get newDatabagId() {
        const status = this.dataIngestionStatus;
        if (status && status.status === 'ingest-completed') {
            return status.target_databag_id;
        }
        return undefined;
    }

    setInitialStateMithraArea(mithraColumns: MithraColumnSerializer[], dataMapping: DataMappingSerializer[]) {
        const listOfMithraColumns = mithraColumns
        const mithraAreaToSet: MithraArea = []
        let itemArray: MappingTableItemType[] = []

        for (const mithraColumn of listOfMithraColumns) {
            for (const mithraItem of dataMapping) {
                if (mithraColumn.key === mithraItem.mithra_column_key) {
                    console.log('mithraItem');
                    console.log(mithraItem);
                    const tempItem: MappingTableItemType = {
                        id: uuid(),
                        name: mithraItem.client_column_name,
                        example: this.clientColumns?.data[0][mithraItem.client_column_index],
                        index: mithraItem.client_column_index,
                        parentList: mithraItem.client_column_name,
                        type: mithraItem.client_column_type,
                        ai_result: mithraItem.ai_result,
                        user_result: mithraItem.user_result,
                        // column_letter: mithraItem.column_letter,
                        column_letter: this.clientColumns?.columns_letters[mithraItem.client_column_index] || '',
                    }
                    itemArray.push(tempItem)
                }
            }

            const tempColumn: MithraColumn = {
                id: uuid(),
                items: itemArray,
                name: mithraColumn.name,
                type: mithraColumn.type,
                key: mithraColumn.key,
                description: mithraColumn.description,
                example: mithraColumn.example,
                disabled: false,
                is_required: mithraColumn.is_required,
                was_mapped: mithraColumn.was_mapped,

                data: mithraColumn,
            }
            itemArray = []
            mithraAreaToSet.push(tempColumn)
        }

        this.mithraColumns = mithraColumns
        this.mithraAreaState = mithraAreaToSet
    }

    setInitialStateClientArea(clientColumns: DataFrameSerializer) {
        const copyClientColumns = clientColumns
        const clientAreaToSet: ClientArea = []

        for (let i = 0; i < copyClientColumns.columns.length; i++) {
            const temp: ClientColumn = {
                id: uuid(),
                name: copyClientColumns.columns[i],
                example: copyClientColumns.data[0][i],
                index: copyClientColumns.column_indexes[i],
                type: copyClientColumns.types[i],
                column_letter: copyClientColumns.columns_letters[i],
            }
            clientAreaToSet.push(temp)
        }
        this.clientColumns = clientColumns
        this.clientAreaState = clientAreaToSet
    }

    /**
     * Delete item from list, this function is passed to the child component via context
     * @param parentMithraColumn id of the parent list
     * @param itemId of the item to delete inside the parent list
     * @returns void
     */
    deleteItemFromList = (parentMithraColumn: string, itemId: string) => {
        const newMithraArea = [...this.mithraAreaState];
        const mithraColumn = this.mithraAreaState.findIndex((e) => e.id === parentMithraColumn);
        const itemToDeleteIndex = this.mithraAreaState[mithraColumn].items.findIndex((e) => e.id === itemId);
        newMithraArea[mithraColumn].items.splice(itemToDeleteIndex, 1);
        this.mithraAreaState = newMithraArea;
    }

    async postDataMappingsList() {
        if (environment.isDemo) {
            await Promise.all([
                this.ingestionApi.getDatasetMappingResult(-1).then(d => this.setDataMappingResultResponse(d)),
                this.ingestionApi.getDatasetKpiList(-1).then(d => this.setDataMappingResultKpi(d))
            ]);
            return;
        }

        const dataFileId = this.dataFile?.id;
        if (!dataFileId) {
            throw new Error('Data file id is not set');
        }

        const mapping: DataMappingSerializer[] = []
        for (const mithraColumn of this.mithraAreaState) {
            for (const item of mithraColumn.items) {
                const m: DataMappingSerializer = {
                    data_file: dataFileId,
                    client_column_index: item.index,
                    client_column_name: item.name,
                    client_column_type: item.type,
                    mithra_column_key: mithraColumn.key,
                    user_result: item.user_result,
                    ai_result: item.ai_result,
                }
                mapping.push(m)
            }
        }

        /*
        * 1) post mappings
        * 2) apply mappings
        * 3) get mapping result table
        * 4) get mapping result KPIs
        * */

        await this.ingestionApi.postDatasetMappingList(mapping);
        await this.ingestionApi.applyDatasetMapping(dataFileId);
        await Promise.all([
            this.ingestionApi.getDatasetMappingResult(dataFileId).then(d => this.setDataMappingResultResponse(d)),
            this.ingestionApi.getDatasetKpiList(dataFileId).then(d => this.setDataMappingResultKpi(d))
        ]);
    }

    setDataMappingResultKpi(d: DataIngestionKpiSerializer) {
        this.dataMappingResultKpi = d;
    }

    setDataMappingResultResponse(d: DataMappingResultTableSerializer) {
        this.dataMappingResultResponse = d

    }

    resetIngestionStore() {
        this.datasetListManager.cleanup()
        this.datasetName = ''
        this.dataFile = undefined
        this.dataMapping = []
        this.allowedOperations = []
        this.clientAreaState = []
        this.mithraAreaState = []
        this.step = 0;
        this.page = 'dataset_upload';
        this.errorMsg = '';
    }


    navigateToPage(page: Page) {
        switch (page) {
            case "dataset_upload":
                this.step = 0;
                break;
            case "data_mapping":
                this.step = 1;
                break;
            case "data_check":
                this.step = 2;
                break;
            case "data_finish":
                this.step = 3;
                break;
        }
        this.page = page;
    }

    get all_required_columns_mapped(): boolean {
        for (const mithraColumn of this.mithraAreaState) {
            if (mithraColumn.items.length === 0 && mithraColumn.is_required) {
                return false
            }
        }
        return true;
    }

    get dataMappingResultTable(): ResultTableRows[] | undefined {
        const result = this.dataMappingResultResponse;
        if (!result) return undefined;
        const columnMap: Record<string, keyof ResultTableRows> = {
            bu__id: 'bu__id',
            bu__name: 'bu__name',
            s__id: 's__id',
            s__name: 's__name',
            s__city: 's__city',
            p__id: 'p__id',
            p__name: 'p__name',
            p__spend: 'p__spend',
            p__description: 'p__description',
            p__context_1: 'p__context_1',
            p__purchase_date: 'p__purchase_date',
            p__quantity: 'p__quantity',
            p__in_l1: 'p__in_l1',
            p__in_l2: 'p__in_l2',
            p__in_l3: 'p__in_l3',
        };
        return result.data.map((row) =>
            row.reduce((obj, value, index) => {
                const columnName = result.columns[index];
                const key = columnMap[columnName];
                obj[key] = value;
                return obj;
            }, {} as ResultTableRows)
        );
    }


    initDataFile(
        history: History<unknown>,
        match: Match<DataFileRouteMatch>
    ) {
        const dataFileId = Number(match.params.dataFileId);
        if (isNaN(dataFileId)) {
            this.setDataFileNotFound()
            return
        }

        Promise.all([
            this.ingestionApi.getDatasetMappingList(dataFileId),
            this.ingestionApi.getAllClientDatasetColumns(dataFileId),
            this.ingestionApi.getAllMithraDatasetColumns(),
            this.ingestionApi.getAllowedOperations(),
            this.ingestionApi.getDataset(dataFileId),
        ])
            .then(([dataMapping, clientColumns, mithraColumns, allowedOperations, dataFile]) => {
                this.setErrorMsg('')
                this.setDataFileAction(dataFileId, dataMapping, clientColumns, mithraColumns, allowedOperations, dataFile)
            })
            .catch(err => {
                const unAuth = this.authStore.authentication.catchApiError(err);
                if (err) {
                    this.setErrorMsg('Error loading file')
                    this.setDataFileNotFound()
                    history.push(routes.data_upload);
                    return
                } else if (unAuth) {
                    this.setDataFileNotFound()
                    history.push(routes.login);
                    return
                }
            })
    }

    setDataFileAction(
        dataFileId: number,
        dataMapping: DataMappingSerializer[],
        clientColumns: DataFrameSerializer,
        mithraColumns: MithraColumnSerializer[],
        allowedOperations: any,
        dataFile: IngestionFile,
    ) {
        //The order of the following two lines is important because the mithraAreaState depends on the clientAreaState
        this.setInitialStateClientArea(clientColumns)
        this.setInitialStateMithraArea(mithraColumns, dataMapping)
        this.allowedOperations = allowedOperations
        this.dataFile = dataFile
        this.dataFileNotFound = false;
    }

    setFileToUploadDirectly(file: File | undefined) {
        this.file = file
        if (!file) return;

        const err = canUploadFile(file.name, file.size);
        if (err) {
            this.errorMsg = err;
        } else {
            this.errorMsg = '';
            if (!this.datasetName || this.datasetName.trim() === '') {
                this.setDatasetName(file.name);
            }
        }
    }

    setDataFileNotFound() {
        this.dataFileNotFound = true;
        this.dataFile = undefined;
    }

    setErrorMsg(error: string) {
        this.errorMsg = error;
    }

    setDataFile(dataFile: IngestionFile) {
        this.dataFile = dataFile;
    }

    setDatasetName(datasetName: string) {
        this.datasetName = datasetName;
    }

    get datasetUploadIsBusy() {
        return this.datasetIsUploadingFile
            || Boolean(this.dataFile?.id !== undefined && this.dataIngestionStatusEnum !== 'user-configuring')
    }

    setDatasetUploadLoading(isLoading: boolean) {
        this.datasetIsUploadingFile = isLoading;
    }

    setStartingRow(startingRow: number) {
        this.startingRow = startingRow;
    }

    setHeadersRow(headersRow: number) {
        this.headersRow = headersRow
    }

    setMergeTarget(target: number) {
        this.mergeTarget = target;
    }

    get showMithraAreaState(): MithraArea {
        let r = this.mithraAreaState;
        if (!this.authStore.isMithraStaff) {
            r = r.filter(s => !s.data?.is_staff_only);
        }
        return r;
    }
}