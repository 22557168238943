import {ApprovalRequest} from "./AiClasses";

type sTimestamp = string;

export namespace m_taxonomy {
    export type Data = {
        category_id?: string,
        p__id__count: number,
        p__spend__sum: number,
        s__id__nunique: number,
        description: string,
    }

    /**
     * Response of the API
     */
    export type Tree<D = Data> = {
        id: number
        label: string
        values: D
        /**
         * Denotes the original IDs of the source category of the parts.
         */
        sources: number[]
        children: Tree<D>[]
    }

    export type SimpleSerializer = {
        id: number
        name: string
        size: number
        current_operation_number: number
        next_node_id: number
        current_date: string
        is_submitted_for_approval: boolean
    }
    export type UpdateSerializer = {
        name: string
    }
    export type FullSerializer = {
        id: number
        name: string
        size: number
        current_operation_number: number
        next_node_id: number
        current_date: string
        is_submitted_for_approval: string
        result_of_approval: null | ApprovalRequest
        tree: Tree
        has_taxonomy_health_check: boolean
    }
    export type CreateTaxonomyOperationSerializer = {
        operation_number: number
        next_node_id: number
        operation_name: _Operation
        state: Tree
    }
    export type SimpleTaxonomyOperationSerializer = {
        operation_number: number
        next_node_id: number
        operation_name: _Operation
        date: string
    }
    export type GotoHistorySerializer = {
        goto_history_number: number
    }

    export enum Operation {
        Add,
        Move,
        Update,
        Delete,
        Merge,
        Suggestion,
        HealthCheck,
    }

    type _Operation = number
    export type TaxonomyHistory = SimpleTaxonomyOperationSerializer

    export type ChangeLogFields = 'label' | 'description';
    export type NodeResult = {
        node_id: number
        labels: string[]
        data: {
            values: Data,
        }
    }
    export type MergeNodeResult = NodeResult & {
        sources: NodeResult[]
    }
    export type MoveNodeResult = NodeResult & {
        old_labels: string[]
    }
    export type EditNodeResult = NodeResult & {
        changes: { field: ChangeLogFields, old_value: any, new_value: any }
    }
    export type ChangeLogResult = {
        created: NodeResult[]
        merged: MergeNodeResult[]
        moved: MoveNodeResult[]
        edited: EditNodeResult[]
        deleted: NodeResult[]
    }

    export type MaterializedTaxonomyData = {
        id: number // materialized_category_id
        lifecycle_cat_id: number
        customer_cat_id: string
    }

    export type MaterializedTaxonomyTree = {
        label: string
        children: MaterializedTaxonomyTree[]
        values: MaterializedTaxonomyData
    }

    export type MaterializedTaxonomy = {
        id: number
        src_taxonomy: number
        name: string
        created: sTimestamp
        n_leafs: number
        n_nodes: number
        tree_state: MaterializedTaxonomyTree
    }

    export type CreateMaterializedCategoryMap = {
        src_lifecycle_cat_id: number,
        src_mat_taxonomy: number,
        dst_lifecycle_cat_id: number,
        dst_mat_taxonomy: number,
    }

    export type CreateMaterializedCategoryMap2 = {
        src_mat_category: number,
        dst_mat_category: number,
    }

    export type MaterializedCategoryMap = {
        id: number // not used
        ai_suggestion: boolean
        user_suggestion: boolean
        user: number | null
        created: sTimestamp
        modified: sTimestamp
        src_mat_category: number,
        source_category_lifecycle_id: number,
        src_mat_taxonomy: number,
        source_l1: string,
        source_l2: string,
        source_l3: string,
        source_l4: string,
        source_l5: string,
        source_l6: string,
        source_l7: string,
        source_l8: string,
        dst_mat_category: number,
        destination_category_lifecycle_id: number,
        dst_mat_taxonomy: number,
        destination_l1: string,
        destination_l2: string,
        destination_l3: string,
        destination_l4: string,
        destination_l5: string,
        destination_l6: string,
        destination_l7: string,
        destination_l8: string
    }

    export type SetDatabagForTaxonomy = {
        taxonomy: number
    }

    export function sortKey(m: MaterializedCategoryMap): string {
        return m.source_l1 + '|' +
            m.source_l2 + '|' +
            m.source_l6 + '|' +
            m.source_l3 + '|' +
            m.source_l4 + '|' +
            m.source_l5 + '|' +
            m.source_l7 + '|' +
            m.source_l8 + '|' +
            m.ai_suggestion + '|' +
            m.destination_l1 + '|' +
            m.destination_l2 + '|' +
            m.destination_l6 + '|' +
            m.destination_l3 + '|' +
            m.destination_l4 + '|' +
            m.destination_l5 + '|' +
            m.destination_l7 + '|' +
            m.destination_l8;
    }
}
