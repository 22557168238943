import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";

type Props = {}
export const DataUploadForm: React.FC<Props> = observer(() => {
    const {dataIngestionStore, authStore, bagStore} = useStores();
    useEffect(() => {
        bagStore.getAllBags()
    }, [bagStore])
    const bags = bagStore.allBags;

    const [mergeWithExisting, setMergeWithExisting] = useState(false);

    const handleHeadersRowChange = (event) => {
        const value = event.target.value;
        const headerRow = Number(value);

        if (!isNaN(headerRow) && headerRow > 0) {
            dataIngestionStore.setHeadersRow(headerRow);

            if (headerRow >= dataIngestionStore.startingRow) {
                dataIngestionStore.setStartingRow(headerRow + 1);
            }
        } else {
            dataIngestionStore.setHeadersRow(1);
        }
        dataIngestionStore.setErrorMsg('');
    };
    const handleStartingRowChange = (event) => {
        const value = event.target.value;
        const numericValue = Number(value);

        if (!isNaN(numericValue) && numericValue > 0) {
            dataIngestionStore.setStartingRow(numericValue);

            if (dataIngestionStore.headersRow && dataIngestionStore.headersRow >= numericValue) {
                dataIngestionStore.setErrorMsg('Headers row must be lower than the starting row.');
            } else {
                dataIngestionStore.setErrorMsg('');
            }
        } else {
            dataIngestionStore.setStartingRow(1);
            dataIngestionStore.setErrorMsg('');
        }
    };
    return <Grid container justifyContent="center" className="data-upload-form">
        <Grid xs={12} item>
            <TextField fullWidth id="outlined-basic" label="Dataset name"
                       variant="outlined" value={dataIngestionStore.datasetName}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                           dataIngestionStore.setDatasetName(event.target.value);
                       }}
            />
        </Grid>
        <Grid xs={12} item style={{marginTop: '1em'}}>
            <TextField
                fullWidth
                id="outlined-headers-row"
                label="Header row"
                variant="outlined"
                type="number"
                value={dataIngestionStore.headersRow ? dataIngestionStore.headersRow.toString() : ''}
                onChange={handleHeadersRowChange}
                defaultValue={2}
                InputProps={{inputProps: {min: 1}}}
                helperText="The row number where the headers of the table are located."
            />
        </Grid>
        <Grid xs={12} item style={{marginTop: '1em'}}>
            <TextField
                fullWidth
                id="outlined-starting-row"
                label="Data row"
                variant="outlined"
                type="number"
                value={dataIngestionStore.startingRow.toString()}
                onChange={handleStartingRowChange}
                defaultValue={2}
                InputProps={{inputProps: {min: 1}}}
                helperText="The row number where the first line of data is located."
            />
        </Grid>
        <Grid xs={12} item style={{marginTop: '1em'}}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={mergeWithExisting}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setMergeWithExisting(event.target.checked);
                        }}
                        name="mergeWithExisting"
                        color="primary"
                    />
                }
                label="Merge with an existing dataset"
            />
        </Grid>
        {mergeWithExisting && (
            <Grid xs={12} item style={{marginTop: '1em'}}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-existing-dataset-label">Select Existing Dataset</InputLabel>
                    <Select
                        labelId="select-existing-dataset-label"
                        id="select-existing-dataset"
                        value={dataIngestionStore.mergeTarget}
                        onChange={(event) => {
                            dataIngestionStore.setMergeTarget(event.target.value as number);
                        }}
                        label="Select Existing Dataset"
                    >
                        {bags && bags.map((bag) => (
                            <MenuItem key={bag.id} value={bag.id}>
                                {authStore.isMithraStaff ? '[' + bag.id + '] ' + bag.name : bag.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        )}
    </Grid>
});