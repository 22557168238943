import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export const TARGET_COLUMNS: Array<ColSpec> = [
    {cls: '', txt: 'Title'},
    {cls: '', txt: 'Target year'},
    {cls: '', txt: 'Fiscal year'},
    {cls: '', txt: 'Baseline spend'},
    {cls: '', txt: 'Saving amount'},
    {cls: '', txt: 'Avoidance amount'},
]
